<div class="benefits-card" *ngIf="cardDetail">
  <p class="title">{{ cardDetail.mainTitle }}</p>

  <div *ngFor="let item of cardDetail.items" class="mt-4">
    <div class="d-flex flex-row benefits-item align-items-center">
      <img [attr.src]="item.subtitleImageSrc" width="38px" />
      <h6 class="ml-4">{{ item.subtitle }}</h6>
    </div>
    <p class="mt-2 benefits-description">
      {{ item.description }}
    </p>
    <div class="d-flex flex-row align-items-center">
      <a class="learn-more-link" *ngIf="item.actionLinkSrc" [href]="item.actionLinkSrc">{{ item.actionLinkTitle }}</a>
      <a class="learn-more-link" *ngIf="item.actionLinkSignUp" (click)="onSignUp()">{{ item.actionLinkTitle }}</a>
      <nb-icon *ngIf="item.actionLinkSrc || item.actionLinkSignUp" [icon]="item.actionLinkIcon || 'arrow-forward'" class="forward-icon"></nb-icon>
    </div>
  </div>
</div>