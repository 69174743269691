import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { openAuctions, auction, auctions, auctionLot, auctionsBySeller, bidderReport } from '../../graphql/queries';
import { submitBid,
         submitMaxBid,
         createAuction,
         updateAuction,
         deleteAuction,
         addAuctionBusiness,
         deleteAuctionBusiness,
         manageFile,
         deleteAuctionAndAssets,
         cloneAuctionAndAssets,
         cloneAuctionLotAndAssets,
         updateAuctionLot,
         createAuctionLot,
         deleteAuctionLot,
         updateAuctionReportLineItem,
         updateAuctionReport,
         sendInvoice,
         sendInvoices
       } from '../../graphql/mutations';

import { onAuctionChanged  } from '../../graphql/subscriptions';
import { auctionCustom,
         openAuctionsCustom,
         auctionLotCustom,
         auctionLotBidsCustom,
         updateAuctionLotPriceParmsCustom,
         auctionBiddersCustom,
         auctionLotsBidPriceCustom,
         auctionLotsBidTimeCustom,
         auctionLotVersionCustom,
         auctionLotCountsCustom,
         auctionReportCustom,
         allAuctionsCustom,
         meCustom,
         meAuctionAsSeller,
         meAuctionAsSellerLotBidPrices,
         meAuctionAsSellerLotBidTimes,
         meAuctionAsSellerAllLotBids } from '../../graphql/custom';

import { OpenAuctionsQuery,
         AuctionQuery,
         SubmitBidMutation,
         BidResultStatus,
         BidResultCode,
         AuctionLocationType,
         AuctionLotQuery,
         OpenAuctionsCustomQuery,
         AuctionLotCustomQuery,
         DeleteAuctionMutation,
         UpdateAuctionLotMutation,
         CreateAuctionMutation,
         ClosingExtensionStyle,
         AuctionType,
         AuctionStatus,
         AuctionLotCategory,
         SexedCategory,
         EPDCategory,
         PhoneNumberDetailInput,
         UpdateAuctionMutation,
         AddAuctionBusinessMutation,
         DeleteAuctionBusinessMutation,
         ManageFileMutation,
         AuctionsBySellerQuery,
         DeleteAuctionAndAssetsMutation,
         CloneAuctionAndAssetsMutation,
         CloneAuctionLotAndAssetsMutation,
         BidType,
         AuctionLotBidsCustomQuery,
         CreateAuctionLotMutation,
         DeleteAuctionLotMutation,
         UpdateAuctionLotPriceParmsCustomMutation,
         AuctionBiddersCustomQuery,
         AuctionLotsBidPriceCustomQuery,
         AuctionLotsBidTimeCustomQuery,
         OnAuctionChangedSubscriptionVariables,
         AuctionLotVersionCustomQuery,
         AuctionLotCountsCustomQuery,
         InterestType,
         ExportableType,
         PaymentStatus,
         SaleStatus,
         AllAuctionsCustomQuery,
         AuctionReportCustomQueryVariables,
         AuctionReportSortField,
         SortDirection,
         AuctionReportCustomQuery,
         UpdateAuctionReportMutationVariables,
         UpdateAuctionReportMutation,
         UpdateAuctionReportLineItemMutationVariables,
         UpdateAuctionReportLineItemMutation,
         SendInvoicesMutationVariables,
         SendInvoicesMutation,
         SendInvoiceMutationVariables,
         SendInvoiceMutation,
         MeCustomQuery,
         MeAuctionAsSellerQuery,
         MeAuctionAsSellerBidPricesQuery,
         MeAuctionAsSellerLotBidTimesQuery,
         MeAuctionAsSellerAllLotBidsQuery,
         AuctionCustomQuery,
         BidderReportQuery,
         SubmitMaxBidMutationVariables,
         SubmitMaxBidMutation
        } from '../API.service';

import { environment } from '../../environments/environment'
import { PortalUser } from './portaluser.model';
import { Seller } from './seller.model';
import { AuctionTimeStatistics } from '../services/auction-time-statistics';
export interface LineageHierarchy {
  sire:string;
  sireRegNum:string;
  sireUrl:string;
  dam:string;
  damRegNum:string;
  damUrl:string;
  sireParents:LineageHierarchy;
  damParents:LineageHierarchy;
}

export interface LineageHierarchyDetail {
  name:string;
  regNum:string;
  url:string;
  sire:boolean;
  parentAvail:boolean;
}

export interface EPDMeasures {
  category:EPDCategory;
  measure:string;
  value:string;
  accuracy:string;
  progeny:string;
  percentage:string;
  order:string;
}

export interface CustomLotField {
  customLabel:string;
  customValue:string;
}

export interface LotImage {
  storageName: string;
  fileName: string;
  caption: string;
  cover: boolean;
}

export interface AuctionResultLineItem {
  auctionId: string;
  auctionName: string;
  auctionStartDate: string;
  lotId: string;
  lotNum: string;
  title: string;
  finalBid: number;
  quantity: number;
  interest: InterestType;
  finalPrice: number;
  saleStatus: SaleStatus;
  invoiceId: string;
  invoice: Invoice;
  businessAccountId: string;
  winningBidderFirstName: string;
  winningBidderLastName: string;
  winningBidderAddress: Array<string>;
  winningBidderEmail: string;
  paymentStatus: PaymentStatus;
  sellerName: string;
  sellerPhoneNumber: string;
  sellerEmail: string;
  additionalLineItems: Array<AdjustmentLineItem>;
}

export interface AdjustmentLineItem {
  amount: number;
  note: string;
}

export interface Invoice {
  auctionId: string;
  auctionName: string;
  auctionStartDate: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceSentDate: string;
  paymentDueDate: string;
  bannerImageKey: string;
  buyerName: string;
  buyerAddress: Array< string | null >;
  buyerPhoneNumber: string;
  buyerEmail: string;
  saleAuctionName: string;
  saleAuctionClosingDate: string;
  sellerName: string;
  sellerPhoneNumber: string;
  sellerEmail: string;
  sellerWebsite: string;
  sellerAddress: Array< string | null >;
  sellerContactName: string;
  sellerCity: string;
  sellerState: string;
  sellerZip: string;
  sellerNotes: string;
  totalAmountPaid: number;
  totalAmountDue: number;
  datePaid: string;
  paid: boolean;
  paymentMethod: string;
  paymentStatus: string;
  paymentInvoiceId: string;
  paymentCustomerId: string;
  paymentInvoicePdf: string;
  paymentUrl: string;
}



@Injectable()
export class AuctionModelService {

  openAuctionsList:AuctionModel[] = [];
  auctionsBySellerList:AuctionModel[] = [];
  activeAuction:AuctionModel;
  activeLot:LotModel;
  subscriptions: any[];
  // lotSummaryList:LotModel[] = [];
  activeAuctionReport: AuctionReportModel;


  constructor() {
    this.subscriptions = [];
  }

  async loadOpenAuctions(forcereload=false,sortDirection="ASC") {
    if (this.openAuctionsList.length == 0 || forcereload == true) {
      let result: AuctionModel[] = [];
      let queryVars = {};
      queryVars['limit'] = 50;
      queryVars['sortDirection'] = sortDirection;
      let continueLoop = true;

      while (continueLoop) {
        try{
          const response = (await API.graphql(graphqlOperation(openAuctionsCustom,queryVars))) as {
            data: OpenAuctionsCustomQuery;
          };

          for(let item of response.data.openAuctions.items) {
            let auction = new AuctionModel();
            auction.load(item);
            result.push(auction);
          }
          continueLoop = response.data.openAuctions.nextToken != undefined;
          queryVars['nextToken'] = response.data.openAuctions.nextToken;
        }
        catch(err){
          console.log('error loading open auctions list',err);
          throw(err)
        }
      }
      this.openAuctionsList = result;
    }
    return this.openAuctionsList;
  }


  async loadSellerOpenAuctions(forcereload=false,sortDirection="ASC") {
    let returnValue = "";
    if(this.openAuctionsList.length == 0 || forcereload == true) {
      this.openAuctionsList = [];
      let queryVars = {};
      queryVars['limit'] = 50;
      queryVars['sortDirection'] = sortDirection;
      let continueLoop = true;

      while(continueLoop){
        try{
          const response = (await API.graphql(graphqlOperation(meCustom,queryVars))) as {
            data: MeCustomQuery;
          };
          const auctions = response.data.me.auctionsAsSeller;
          for(let item of (auctions.items || [])){
            let auction = new AuctionModel();
            auction.load(item);
            this.openAuctionsList.push(auction);
          }
          continueLoop = auctions.nextToken != undefined;
          queryVars['nextToken'] = auctions.nextToken;
        }
        catch(err){
          console.log('error loading open auctions list',err);
          throw(err)
        }
      }
    }
    return this.openAuctionsList;
  }

  async loadAuctionsBySeller(sellerId:string,forcereload=false,sortDirection="ASC") {
    let returnValue = "";
    if(this.auctionsBySellerList.length == 0 || forcereload == true) {
      this.auctionsBySellerList = [];
      let queryVars = {};
      queryVars['sellerId'] = sellerId;
      queryVars['limit'] = 20;
      queryVars['sortDirection'] = sortDirection;
      let continueLoop = true;

      while(continueLoop){
        try{
          const response = (await API.graphql(graphqlOperation(auctionsBySeller,queryVars))) as {
            data: AuctionsBySellerQuery;
          };
          // console.log('response',response);
          for(let item of response.data.auctionsBySeller.items){
            let auction = new AuctionModel();
            auction.load(item);
            this.auctionsBySellerList.push(auction);
          }
          continueLoop = response.data.auctionsBySeller.nextToken != undefined;
          queryVars['nextToken'] = response.data.auctionsBySeller.nextToken;
        }
        catch(err){
          console.log('error loading auctions by seller',err);
          throw(err)
        }
      }
    }
    return this.auctionsBySellerList;
  }

  async loadAuctionsLotsByRecentBidActivity(auctionId:string,limit:number=5,sortDirection="DESC") {
    // this.lotSummaryList.splice(0,this.lotSummaryList.length);
    let returnValue = new Array();
    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    queryVars['limit'] = limit;
    queryVars['sortDirection'] = sortDirection;

    try{
      const response = (await API.graphql(graphqlOperation(meAuctionAsSellerLotBidTimes,queryVars))) as {
        data: MeAuctionAsSellerLotBidTimesQuery;
      };
      // console.log('meAuctionAsSellerLotBidTimes',response);
      for(let item of response.data.me.auctionAsSellerLotBidTimes.items){
        let lot = new LotModel();
        lot.load(item);
        returnValue.push(lot);
      }
    }
    catch(err){
      console.log('error loading lots by recent bid activity',err);
      throw(err)
    }
    return returnValue;
  }

  async loadAuctionsLotsByRecentBidPrice(auctionId:string,limit:number=5,sortDirection="ASC") {
    // this.lotSummaryList.splice(0,this.lotSummaryList.length);
    let returnValue = new Array();

    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    queryVars['limit'] = 5;
    queryVars['sortDirection'] = sortDirection;

    try{
      const response = (await API.graphql(graphqlOperation(meAuctionAsSellerLotBidPrices,queryVars))) as {
        data: MeAuctionAsSellerBidPricesQuery;
      };
      // console.log('auctionLotsBidPriceCustom',response);
      for(let item of response.data.me.auctionAsSellerLotBidPrices.items){
        let lot = new LotModel();
        lot.load(item);
        returnValue.push(lot);
      }
    }
    catch(err){
      console.log('error loading lots by recent bid price',err);
      throw(err)
    }
    return returnValue;
  }

  async getAuctionAsSellerAllLotBids(auctionId:string){
    let returnValue = new Array();
    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    try{
      const response = (await API.graphql(graphqlOperation(meAuctionAsSellerAllLotBids,queryVars))) as {
        data: MeAuctionAsSellerAllLotBidsQuery;
      };
      // console.log('get auction as seller all lot bids',response.data);
      for(let item of response.data.me.auctionAsSellerWithLots.lots.items){
        let lot = new LotModel();
        lot.load(item);
        returnValue.push(lot);
      }
    }
    catch(err){
      console.log('error loading auction',err);
      throw(err)
    }
    returnValue = returnValue.sort(AuctionModel.compareLotOrder);

    return returnValue;
  }

  async loadAuction(auctionId: string) {
    let queryVars = {};
    queryVars['auctionId'] = auctionId;

    try {
      const response = (await API.graphql(graphqlOperation(auctionCustom, queryVars))) as {
        data: AuctionCustomQuery;
      };
      if (response && response.data && response.data.auction) {
        this.activeAuction = new AuctionModel();
        this.activeAuction.load(response.data.auction);

        let nextToken = response.data.auction.lots.nextToken;
        while (nextToken) {
          const lotQueryVars = { auctionId: auctionId, nextToken: nextToken }
          const response = (await API.graphql(graphqlOperation(auctionCustom, lotQueryVars))) as {
            data: AuctionCustomQuery;
          };
          if (response?.data?.auction?.lots?.items) {
            this.activeAuction.addLots(response.data.auction.lots.items);
            nextToken = response.data.auction.lots.nextToken;
          } else {
            nextToken = null;
          }
        }
      }
    }
    catch(err){
      console.log('error loading auction',err);
      throw(err)
    }
    return this.activeAuction;
  }

  async loadLot(auctionId:string, lotId:string) {
    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    queryVars['lotId'] = lotId;

    try{
      const response = (await API.graphql(graphqlOperation(auctionLotCustom,queryVars))) as {
        data: AuctionLotCustomQuery;
      };
      // console.log('AuctionLotCustomQuery',response.data);
      this.activeLot = new LotModel();
      this.activeLot.load(response.data.auctionLot);
    }
    catch(err){
      console.log('error retrieving lot',err);
      throw(err)
    }
    return this.activeLot;
  }

  async subscribeToBiddingAuctionUpdates(auctionId: string, localThis, onUpdate: (bidData,localThis) => void, onError?: (message: string) => void) {
    try {
      const variables: OnAuctionChangedSubscriptionVariables = { auctionId: auctionId }
      const operation = await API.graphql(graphqlOperation(onAuctionChanged, variables))
      // @ts-ignore https://github.com/aws-amplify/amplify-js/issues/5741
      const subscription = operation.subscribe({
        next: (bidData) => {
          onUpdate(bidData.value.data.onAuctionChanged,localThis);
        },
        error: (error: any) => {
          if (onError) onError(error)
          console.error(error)
        },
      })
      let key = Date.now();
      let subscriptionParms = {
        "key":key,
        "subscription":subscription
      }
      this.subscriptions.push(subscriptionParms)
      return subscriptionParms
    }
    catch (e) {
      throw(e)
    }
  }

  async subscribeToAuctionUpdates(auctionId: string, localThis, onUpdate: (bidData,localThis) => void, onError?: (message: string) => void, retryInterval?: number) {
    try {
      if (!retryInterval) retryInterval = 50;

      setTimeout(async () => {
        const variables: OnAuctionChangedSubscriptionVariables = { auctionId: auctionId }
        const operation = await API.graphql(graphqlOperation(onAuctionChanged, variables))
        // @ts-ignore https://github.com/aws-amplify/amplify-js/issues/5741
        const subscription = operation.subscribe({
          next: (bidData) => {
            onUpdate(bidData.value.data.onAuctionChanged,localThis);
          },
          error: (error: any) => {
            console.error("Error in subscription... retrying.", error);
            return this.subscribeToAuctionUpdates(auctionId, localThis, onUpdate, onError, retryInterval * 2);
          },
        })
        let key = Date.now();
        let subscriptionParms = {
          "key": key,
          "subscription": subscription
        }
        this.subscriptions.push(subscriptionParms)
        return subscriptionParms
      }, retryInterval);
    }
    catch (e) {
      throw(e)
    }
  }
​
  unsubscribeToAuctionUpdates() {
    try {
      this.subscriptions.forEach((el) => {
        el.subscription.unsubscribe()
      })
      this.subscriptions = []
    }
    catch (e) {
      console.error(e)
    }
  }

  unsubscribeToSingleBidUpdate(key) {
    let index = this.subscriptions.findIndex((el)=>{return el.key==key});
    if(index >= 0){
      try {
        this.subscriptions[index]['subscription'].unsubscribe();
        this.subscriptions.splice(index,1);
      }
      catch (e) {
        console.error(e)
      }

    }
  }

  initNewLot(auctionId:string){
    let returnValue = new LotModel();
    returnValue.auctionId = auctionId;
    returnValue.biddingDisabled = false;
    returnValue.title = "";
    returnValue.footnote = [];
    returnValue.footnote.push("");
    if(this.activeAuction.lots == undefined || this.activeAuction.lots.length==0){
      returnValue.lotOrder = 1;
    }
    else {
      let maxLotOrder = this.activeAuction.lots.slice(-1)[0].lotOrder;
      returnValue.lotOrder = maxLotOrder + 1;
    }
    returnValue.lotNum = "" + returnValue.lotOrder;
    returnValue.startBid = this.activeAuction.startBid;
    returnValue.floorPrice = this.activeAuction.floorPrice;
    returnValue.bidIncrement = this.activeAuction.bidIncrement;
    returnValue.photos = [];
    returnValue.videos = [];
    returnValue.interest = InterestType.WHOLE;
    returnValue.lineageHierarchy= returnValue.getInitializedLineage();
    returnValue.epdMeasures = returnValue.getInitializedEPDs();
    return returnValue;
  }

  public async loadAuctionReport(
    auctionId: string,
    sortField: string,
    sortDirection: string,
    lotLimit: number,
    lastItemId: string
  ) {
    let model = new AuctionReportModel();
    const auctionReport = await model.get(
      auctionId,
      sortField,
      sortDirection,
      lotLimit,
      lastItemId,
    );
    this.activeAuctionReport = auctionReport;

    return auctionReport;
  }


}

export class AuctionModel {

  static AUCTION_TYPES = [
    {"key":"HORSE_STYLE","value":"Horse Style"}
    // {"key":"LIVE_CLOSEOUT","value":"Live Closeout"},
    // {"key":"STAGGERED_CLOSE","value":"Staggered Close"},
    // {"key":"MARKETPLACE","value":"Marketplace"}
  ];

  static EXTENSION_PERIOD_INTERVALS = [
    {"key":1,"value":"1 min"},
    {"key":2,"value":"2 min"},
    {"key":3,"value":"3 min"},
    {"key":4,"value":"4 min"},
    {"key":5,"value":"5 min"},
    {"key":10,"value":"10 min"},
    {"key":15,"value":"15 min"},
    {"key":20,"value":"20 min"},
    {"key":30,"value":"30 min"}
  ];

  auctionId:string;
  title:string;
  startTime:string;
  endTime:string;
  locationType:string;
  locationCity:string;
  locationState:string;
  description:string;
  businessAccountId:string;
  type:string;
  closingExtensionEnabled: boolean;
  closingExtensionStyle: string;
  closingExtensionInterval: number;
  closingExtensionTriggered: boolean;
  status:AuctionStatus;
  salesInfoBannerKeys:string[];
  salesInfoNotes:string;
  sellerLocationTitle:string;
  sellerContactName:string;
  sellerAddress: string[];
  sellerCity:string;
  sellerState:string;
  sellerZip:string;
  sellerEmail:string;
  sellerPhoneNumbers:PhoneNumberDetailInput[];
  saleOverview: string[];
  salesRepName:string;
  salesRepContact:string;
  salesRepEmail:string;
  welcomeMessage:string;
  bidIncrement:number;
  startBid:number;
  floorPrice:number;
  catalogKey:string;
  introVideoUrl:string;
  paymentOptions:string[];
  shippingInstructions:string;
  receivingInstructions:string;
  liveBidLocation:string;
  bannerImageKey:string;
  hasCustomInvoiceDetails:boolean;
  hasStandardTermsAndConditions:boolean;
  hasCustomTermsAndConditions:boolean;
  customTermsAndConditions:string;
  hasStandardDeliveryAndPaymentTerms:boolean;
  hasCustomDeliveryAndPaymentTerms:boolean;
  customDeliveryAndPaymentTerms:string;
  timezone:string;
  useSellerContactInfo:boolean;
  version:number;

  lots:LotModel[] = [];
  businessName:string;

  primaryBusiness:Seller;
  businesses:Seller[] = [];

  businessAccountIds:string[] = [];
  businessModifiedAccountIds:string[] = [];

  salesInfoBannerUrls:[] = [];
  catalogKeyUrl;
  bannerImageKeyUrl;
  bidders:BidderModel[];

  config = environment;

  timeStatistics: AuctionTimeStatistics;

  constructor() { }

  load(item:{}){
    this.auctionId = item['auctionId'] != undefined ? item['auctionId'] : "";
    this.title = item['title'] != undefined ? item['title'] : undefined;
    this.startTime = item['startTime'] != undefined ? item['startTime'] : undefined;
    this.endTime = item['endTime'] != undefined ? item['endTime'] : undefined;
    this.locationType = item['locationType'] != undefined ? item['locationType'] : undefined;
    this.locationCity = item['locationCity'] != undefined ? item['locationCity'] : undefined;
    this.locationState = item['locationState'] != undefined ? item['locationState'] : undefined;
    this.description = item['description'] != undefined ? item['description'] : undefined;
    this.businessAccountId = item['businessAccountId'] != undefined ? item['businessAccountId'] : undefined;
    this.type = item['type'] != undefined ? item['type'] : undefined;
    this.closingExtensionEnabled = item['closingExtensionEnabled'] != undefined ? item['closingExtensionEnabled'] : false;
    this.closingExtensionStyle = item['closingExtensionStyle'] != undefined ? item['closingExtensionStyle'] : undefined;
    this.closingExtensionInterval = item['closingExtensionInterval'] != undefined ? item['closingExtensionInterval'] : undefined;
    this.closingExtensionTriggered = item['closingExtensionTriggered'] != undefined ? item['closingExtensionTriggered'] : undefined;
    this.status = item['status'] != undefined ? item['status'] : undefined;
    this.salesInfoBannerKeys = item['salesInfoBannerKeys'] != undefined ? item['salesInfoBannerKeys'] : [];
    this.salesInfoNotes = item['salesInfoNotes'] != undefined ? item['salesInfoNotes'] : undefined;
    this.sellerLocationTitle = item['sellerLocationTitle'] != undefined ? item['sellerLocationTitle'] : undefined;
    this.sellerContactName = item['sellerContactName'] != undefined ? item['sellerContactName'] : undefined;
    this.sellerAddress = item['sellerAddress'] != undefined ? item['sellerAddress'] : [];
    this.sellerCity = item['sellerCity'] != undefined ? item['sellerCity'] : undefined;
    this.sellerState = item['sellerState'] != undefined ? item['sellerState'] : undefined;
    this.sellerZip = item['sellerZip'] != undefined ? item['sellerZip'] : undefined;
    this.sellerEmail = item['sellerEmail'] != undefined ? item['sellerEmail'] : undefined;
    this.sellerPhoneNumbers = item['sellerPhoneNumbers'] != undefined ? item['sellerPhoneNumbers'] : [];
    this.saleOverview = item['saleOverview'] != undefined ? item['saleOverview'] : [];
    this.salesRepContact = item['salesRepContact'] != undefined ? item['salesRepContact'] : undefined;
    this.salesRepName = item['salesRepName'] != undefined ? item['salesRepName'] : undefined;
    this.salesRepEmail = item['salesRepEmail'] != undefined ? item['salesRepEmail'] : undefined;
    this.welcomeMessage = item['welcomeMessage'] != undefined ? item['welcomeMessage'] : undefined;
    this.bidIncrement = item['bidIncrement'] != undefined ? item['bidIncrement'] : undefined;
    this.startBid = item['startBid'] != undefined ? item['startBid'] : undefined;
    this.floorPrice = item['floorPrice'] != undefined ? item['floorPrice'] : undefined;
    this.catalogKey = item['catalogKey'] != undefined ? item['catalogKey'] : undefined;
    this.introVideoUrl = item['introVideoUrl'] != undefined ? item['introVideoUrl'] : undefined;
    this.paymentOptions = item['paymentOptions'] != undefined ? item['paymentOptions'] : undefined;
    this.shippingInstructions = item['shippingInstructions'] != undefined ? item['shippingInstructions'] : undefined;
    this.receivingInstructions = item['receivingInstructions'] != undefined ? item['receivingInstructions'] : undefined;
    this.liveBidLocation = item['liveBidLocation'] != undefined ? item['liveBidLocation'] : undefined;
    this.bannerImageKey = item['bannerImageKey'] != undefined ? item['bannerImageKey'] : undefined;
    this.hasCustomInvoiceDetails = item['hasCustomInvoiceDetails'] != undefined ? item['hasCustomInvoiceDetails'] : false;
    this.hasStandardTermsAndConditions = item['hasStandardTermsAndConditions'] != undefined ? item['hasStandardTermsAndConditions'] : true;
    this.hasCustomTermsAndConditions = item['hasCustomTermsAndConditions'] != undefined ? item['hasCustomTermsAndConditions'] : true;
    this.customTermsAndConditions = item['customTermsAndConditions'] != undefined ? item['customTermsAndConditions'] : true;
    this.hasStandardDeliveryAndPaymentTerms = item['hasStandardDeliveryAndPaymentTerms'] != undefined ? item['hasStandardDeliveryAndPaymentTerms'] : true;
    this.hasCustomDeliveryAndPaymentTerms = item['hasCustomDeliveryAndPaymentTerms'] != undefined ? item['hasCustomDeliveryAndPaymentTerms'] : true;
    this.customDeliveryAndPaymentTerms = item['customDeliveryAndPaymentTerms'] != undefined ? item['customDeliveryAndPaymentTerms'] : true;
    this.timezone = item['timezone'] != undefined ? item['timezone'] : undefined;
    this.useSellerContactInfo = item['useSellerContactInfo'] != undefined ? item['useSellerContactInfo'] : false;
    this.version = item['version'] != undefined ? item['version'] : undefined;

    var loadedLots = item['lots'] != undefined ? item['lots']['items'] : [];
    loadedLots.forEach(loadedLot => {
      const lotIndex = this.lots.findIndex(x => x.auctionId === loadedLot.auctionId && x.lotId === loadedLot.lotId);
      if (lotIndex !== -1) {
        this.lots[lotIndex].load(loadedLot);
      } else {
        let lot = new LotModel().load(loadedLot);
        this.lots.push(lot);
      }
    })
    this.lots = this.lots.sort(AuctionModel.compareLotOrder);


    if(item['primaryBusiness'] != undefined){
      this.primaryBusiness = new Seller();
      this.primaryBusiness.loadResults(item['primaryBusiness']);
      this.businessName = this.primaryBusiness.businessName != undefined ? this.primaryBusiness.businessName : "";
    }
    else {
      this.businessName = "";
    }

    if(item['businesses'] != undefined){
      this.businesses = [];
      this.businessAccountIds = [];
      this.businessModifiedAccountIds = [];
      for(let value of item['businesses']){
        let business = new Seller();
        business.loadResults(value)
        this.businesses.push(business);
        this.businessAccountIds.push(value.accountId);
        this.businessModifiedAccountIds.push(value.accountId);
      }
    }

    if (this.startTime && this.endTime) {
      this.timeStatistics = new AuctionTimeStatistics(this);
    }

    return this;
  }

  public addLots(lotList) {
    if(lotList != undefined){
      for(let [index,item] of lotList.entries()){
        let lot = new LotModel().load(item);
        this.lots.push(lot);
      }
      this.lots = this.lots.sort(AuctionModel.compareLotOrder);
    }
  }

  async loadBidders(){
    let queryVars = {};
    queryVars['auctionId'] = this.auctionId;
    try{
      const response = (await API.graphql(graphqlOperation(auctionBiddersCustom,queryVars))) as {
        data: AuctionBiddersCustomQuery;
      };
      // console.log('get auction bidders',response.data);
      let item = response.data.auction;

      this.bidders = [];
      if(item['bidders'] != undefined && item['bidders']['items'] != undefined && item['bidders']['items'].length > 0){
        for(let bidder of item['bidders']['items']){
          let bidderModel = new BidderModel();
          bidderModel.load(bidder);
          this.bidders.push(bidderModel);
        }
      }

    }
    catch(err){
      console.log('error loading auction bidders',err);
      throw(err)
    }
    return this;
  }

  static compareLotOrder( a:LotModel, b:LotModel ) {
    let valueA:number = a.lotOrder;
    let valueB:number = b.lotOrder;
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  }

  async get(auctionId:string){
    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    try{
      const response = (await API.graphql(graphqlOperation(auctionCustom,queryVars))) as {
        data: AuctionCustomQuery;
      };
      this.load(response.data.auction);

      let nextToken = response.data.auction.lots.nextToken;
      while (nextToken) {
        const lotQueryVars = { auctionId: auctionId, nextToken: nextToken }
        const response = (await API.graphql(graphqlOperation(auctionCustom, lotQueryVars))) as {
          data: AuctionCustomQuery;
        };
        if (response?.data?.auction?.lots) {
          this.addLots(response.data.auction.lots);
          nextToken = response.data.auction.lots.nextToken;
        } else {
          nextToken = null;
        }
      }
    }
    catch(err){
      console.log('error loading auction',err);
      throw(err)
    }
    return this;
  }

  async getAuctionAsSeller(auctionId:string){
    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    try{
      const response = (await API.graphql(graphqlOperation(meAuctionAsSeller,queryVars))) as {
        data: MeAuctionAsSellerQuery;
      };
      // console.log('get auction as seller',response.data);
      this.load(response.data.me.auctionAsSellerWithLots);
    }
    catch(err){
      console.log('error loading auction as seller',err);
      throw(err)
    }
    return this;
  }

  async updateStatus(status:AuctionStatus){
    if(this.auctionId == undefined || this.auctionId.length == 0){
      throw("Auction not yet saved.");
    }
    try{
      let updateVars = {};
      updateVars['status'] = status;
      const response = (await API.graphql(graphqlOperation(updateAuction,{auctionId:this.auctionId,expectedVersion:this.version,input:updateVars}))) as {
        data: UpdateAuctionMutation;
      };

      if(response.data == undefined || response.data.updateAuction == undefined){
        throw new Error('Error updating auction status. Contact your administrator.');
      }
      // console.log('update auction',response);
      this.load(response.data.updateAuction);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating auction',err);
      throw(errorMsg)
    }
  }

  async updateClosingExtensionInterval(){
    if(this.auctionId == undefined || this.auctionId.length == 0){
      throw("Auction not yet saved.");
    }
    try{
      let updateVars = {};
      updateVars['closingExtensionInterval'] = this.closingExtensionInterval;
      const response = (await API.graphql(graphqlOperation(updateAuction,{auctionId:this.auctionId,expectedVersion:this.version,input:updateVars}))) as {
        data: UpdateAuctionMutation;
      };

      if(response.data == undefined || response.data.updateAuction == undefined){
        throw new Error('Error updating auction extension interval. Contact your administrator.');
      }
      return await this.get(this.auctionId);
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating auction',err);
      throw(errorMsg)
    }
  }

  async update(){
    if(this.auctionId == undefined || this.auctionId.length == 0){
      return this.create();
    }
    try{
      // console.log(this.getUpdateVars());
      let updateVars = this.getUpdateVars();
      const response = (await API.graphql(graphqlOperation(updateAuction,{auctionId:this.auctionId,expectedVersion:this.version,input:this.getUpdateVars()}))) as {
        data: UpdateAuctionMutation;
      };

      if(response.data == undefined || response.data.updateAuction == undefined){
        throw new Error('Error updating auction. Contact your administrator.');
      }
      await this.saveBusinesses();
      return await this.get(this.auctionId);
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating auction',err);
      throw(errorMsg)
    }
  }

  async create(){
    try{
      const response = (await API.graphql(graphqlOperation(createAuction,{input:this.getUpdateVars()}))) as {
        data: CreateAuctionMutation;
      };

      if(response.data == undefined || response.data.createAuction == undefined){
        throw new Error('Error creating auction. Contact your administrator.');
      }
      // console.log('create auction',response);
      this.auctionId = response.data.createAuction['auctionId'];
      await this.saveBusinesses();
      await this.get(this.auctionId);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating auction',err);
      throw(errorMsg)
    }
  }

  async delete(){
    try{
      await this.deleteBusinesses();
      const response = (await API.graphql(graphqlOperation(deleteAuction,{auctionId:this.auctionId}))) as {
        data: DeleteAuctionMutation;
      };

      if(response.data == undefined || response.data.deleteAuction == undefined){
        throw new Error('Error deleting auction. Contact your administrator.');
      }
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting auction',err);
      throw(errorMsg)
    }
  }

  async saveBusinesses(){

    /* Add businesses that do not exist*/
    for(let item of this.businessModifiedAccountIds){
      if(!this.businessAccountIds.includes(item)){
        await this.addBusiness(item);
      }
    }

    /* Delete businesses */
    for(let item of this.businessAccountIds){
      if(!this.businessModifiedAccountIds.includes(item)){
        await this.deleteBusiness(item);
      }
    }
  }

  async deleteBusinesses(){
    for(let item of this.businessAccountIds){
        await this.deleteBusiness(item);
    }
  }

  async addBusiness(businessAccountId:string){
    try{
      const response = (await API.graphql(graphqlOperation(addAuctionBusiness,{auctionId:this.auctionId,businessAccountId:businessAccountId}))) as {
        data: AddAuctionBusinessMutation;
      };

      if(response.data == undefined || response.data.addAuctionBusiness == undefined){
        throw new Error('Error adding auction business. Contact your administrator.');
      }
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error adding auction business',err);
      throw(errorMsg)
    }
  }

  async deleteBusiness(businessAccountId:string){
    try{
      const response = (await API.graphql(graphqlOperation(deleteAuctionBusiness,{auctionId:this.auctionId, businessAccountId:businessAccountId}))) as {
        data: DeleteAuctionBusinessMutation;
      };

      if(response.data == undefined || response.data.deleteAuctionBusiness == undefined){
        throw new Error('Error deleting auction business. Contact your administrator.');
      }
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting auction business',err);
      throw(errorMsg)
    }
  }

  async deleteAuctionAndAssets(){
    try{
      const response = (await API.graphql(graphqlOperation(deleteAuctionAndAssets,{auctionId:this.auctionId}))) as {
        data: DeleteAuctionAndAssetsMutation;
      };

      if(response.data == undefined || response.data.deleteAuctionAndAssets == undefined){
        throw new Error('Error deleting auction auction and assets. Contact your administrator.');
      }
      // console.log('deleteAuctionAndAssets',response);
      let deletedAuctionId = response.data.deleteAuctionAndAssets['auctionId'];
      return deletedAuctionId;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting auction and assets',err);
      throw(errorMsg)
    }
  }

  async cloneAuctionAndAssets(auctionId:string){
    try{
      const response = (await API.graphql(graphqlOperation(cloneAuctionAndAssets,{auctionId:auctionId}))) as {
        data: CloneAuctionAndAssetsMutation;
      };

      if(response.data == undefined || response.data.cloneAuctionAndAssets == undefined){
        throw new Error('Error cloning auction auction and assets. Contact your administrator.');
      }
      // console.log('cloneAuctionAndAssets',response);
      let clonedAuctionId = response.data.cloneAuctionAndAssets['auctionId'];
      return clonedAuctionId;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error cloning auction and assets',err);
      throw(errorMsg)
    }
  }

  getUpdateVars(){
    var returnValue = {};
    this.title != undefined ? returnValue['title'] = this.title : undefined;
    this.startTime != undefined ? returnValue['startTime'] = this.startTime : undefined;
    this.endTime != undefined ? returnValue['endTime'] = this.endTime : undefined;
    this.locationType != undefined ? returnValue['locationType'] = this.locationType : undefined;
    this.locationCity != undefined ? returnValue['locationCity'] = this.locationCity : undefined;
    this.locationState != undefined ? returnValue['locationState'] = this.locationState : undefined;
    this.description != undefined ? returnValue['description'] = this.description : undefined;
    this.businessAccountId != undefined ? returnValue['businessAccountId'] = this.businessAccountId : undefined;
    this.type != undefined ? returnValue['type'] = this.type : undefined;
    this.closingExtensionEnabled != undefined ? returnValue['closingExtensionEnabled'] = this.closingExtensionEnabled : undefined;
    this.closingExtensionStyle != undefined ? returnValue['closingExtensionStyle'] = this.closingExtensionStyle : undefined;
    this.closingExtensionInterval != undefined ? returnValue['closingExtensionInterval'] = this.closingExtensionInterval : undefined;
    this.status != undefined ? returnValue['status'] = this.status : undefined;
    this.salesInfoBannerKeys != undefined ? returnValue['salesInfoBannerKeys'] = this.salesInfoBannerKeys : undefined;
    this.salesInfoNotes != undefined ? returnValue['salesInfoNotes'] = this.salesInfoNotes : undefined;
    this.sellerLocationTitle != undefined ? returnValue['sellerLocationTitle'] = this.sellerLocationTitle : undefined;
    this.sellerContactName != undefined ? returnValue['sellerContactName'] = this.sellerContactName : undefined;
    this.sellerAddress != undefined ? returnValue['sellerAddress'] = this.sellerAddress : undefined;
    this.sellerCity != undefined ? returnValue['sellerCity'] = this.sellerCity : undefined;
    this.sellerState != undefined ? returnValue['sellerState'] = this.sellerState : undefined;
    this.sellerZip != undefined ? returnValue['sellerZip'] = this.sellerZip : undefined;
    this.sellerEmail != undefined ? returnValue['sellerEmail'] = this.sellerEmail : undefined;
    this.sellerPhoneNumbers != undefined ? returnValue['sellerPhoneNumbers'] = this.sellerPhoneNumbers : undefined;
    this.saleOverview != undefined ? returnValue['saleOverview'] = this.saleOverview : undefined;
    this.salesRepName != undefined ? returnValue['salesRepName'] = this.salesRepName : undefined;
    this.salesRepContact != undefined ? returnValue['salesRepContact'] = this.salesRepContact : undefined;
    this.salesRepEmail != undefined ? returnValue['salesRepEmail'] = this.salesRepEmail : undefined;
    this.welcomeMessage != undefined ? returnValue['welcomeMessage'] = this.welcomeMessage : undefined;
    this.bidIncrement != undefined ? returnValue['bidIncrement'] = this.bidIncrement : undefined;
    this.startBid != undefined ? returnValue['startBid'] = this.startBid : undefined;
    this.floorPrice != undefined ? returnValue['floorPrice'] = this.floorPrice : undefined;
    this.catalogKey != undefined ? returnValue['catalogKey'] = this.catalogKey : undefined;
    this.introVideoUrl != undefined ? returnValue['introVideoUrl'] = this.introVideoUrl : undefined;
    this.paymentOptions != undefined ? returnValue['paymentOptions'] = this.paymentOptions : undefined;
    this.shippingInstructions != undefined ? returnValue['shippingInstructions'] = this.shippingInstructions : undefined;
    this.receivingInstructions != undefined ? returnValue['receivingInstructions'] = this.receivingInstructions : undefined;
    this.liveBidLocation != undefined ? returnValue['liveBidLocation'] = this.liveBidLocation : undefined;
    this.bannerImageKey != undefined ? returnValue['bannerImageKey'] = this.bannerImageKey : undefined;
    this.hasCustomInvoiceDetails != undefined ? returnValue['hasCustomInvoiceDetails'] = this.hasCustomInvoiceDetails : undefined;
    this.hasStandardTermsAndConditions != undefined ? returnValue['hasStandardTermsAndConditions'] = this.hasStandardTermsAndConditions : undefined;
    this.hasCustomTermsAndConditions != undefined ? returnValue['hasCustomTermsAndConditions'] = this.hasCustomTermsAndConditions : undefined;
    this.customTermsAndConditions != undefined ? returnValue['customTermsAndConditions'] = this.customTermsAndConditions : undefined;
    this.hasStandardDeliveryAndPaymentTerms != undefined ? returnValue['hasStandardDeliveryAndPaymentTerms'] = this.hasStandardDeliveryAndPaymentTerms : undefined;
    this.hasCustomDeliveryAndPaymentTerms != undefined ? returnValue['hasCustomDeliveryAndPaymentTerms'] = this.hasCustomDeliveryAndPaymentTerms : undefined;
    this.customDeliveryAndPaymentTerms != undefined ? returnValue['customDeliveryAndPaymentTerms'] = this.customDeliveryAndPaymentTerms : undefined;
    this.timezone != undefined ? returnValue['timezone'] = this.timezone : undefined;
    this.useSellerContactInfo != undefined ? returnValue['useSellerContactInfo'] = this.useSellerContactInfo : undefined;
    return returnValue;
  }

  getLogoUrl(){
    return "https://" + this.config.image_path_url + "/" + this.auctionId + "/" + this.bannerImageKey;
  }

  getSalesLogoUrl(imageKey){
    return "https://" + this.config.image_path_url + "/" + this.auctionId + "/" + imageKey;
  }

  getCatalogUrl(){
    return "https://" + this.config.asset_path_url + "/" + this.auctionId + "/" + this.catalogKey;
  }

  getSalesLocation(){
    let city = this.sellerCity != undefined ? this.sellerCity + ", " : "";
    let state = this.sellerState != undefined ? this.sellerState + " " : "";
    let zip = this.sellerZip != undefined ? this.sellerZip : "";
    return (city + state + zip);
  }

  getLocation(){
    var returnValue = "";
    if(this.locationType == AuctionLocationType.ONLINE_ONLY){
      returnValue = "Online Only";
    }
    else{
      let city = this.locationCity != undefined ? this.locationCity + ", " : "";
      let state = this.locationState != undefined ? this.locationState : "";
      returnValue = city + state;
    }
    return returnValue;
  }

  getSellerLocation(){
    var returnValue = "";
    let city = this.sellerCity != undefined ? this.sellerCity + ", " : "";
    let state = this.sellerState != undefined ? this.sellerState : "";
    returnValue = city + state;
    return returnValue;
  }

  isSetup(){
    return this.status == AuctionStatus.SETUP;
  }

  isCalendarPosted(){
    return this.status == AuctionStatus.CALENDAR_POSTED;
  }

  isSaleInfoPosted(){
    return this.status == AuctionStatus.SALE_INFO_POSTED;
  }

  isLotsPosted(){
    return this.status == AuctionStatus.LOTS_POSTED;
  }

  isLive(){
    return this.status == AuctionStatus.LIVE || (this.timeStatistics && this.timeStatistics.auctionCurrentlyActive());
  }

  isClosed(){
    return this.status == AuctionStatus.CLOSED || (this.timeStatistics && this.timeStatistics.isClosed());
  }

  isSettled(){
    return this.status == AuctionStatus.SETTLED;
  }

  isArchived(){
    return this.status == AuctionStatus.ARCHIVED;
  }

  async getFileURL(filename:string){

    try{
      const response = (await API.graphql(graphqlOperation(manageFile,{itemId:this.auctionId,itemKey:filename,public:true}))) as {
        data: ManageFileMutation;
      };
      return response.data.manageFile;
    }
    catch(err){
      console.log('error getting file url',err);
      throw(err)
    }
  }

  initNewAuction(){
    this.status = AuctionStatus.SETUP;
    this.hasStandardTermsAndConditions = true;
    this.hasStandardDeliveryAndPaymentTerms = true;
    this.hasCustomTermsAndConditions = false;
    this.hasCustomDeliveryAndPaymentTerms = false;
    this.useSellerContactInfo = true;
    this.customDeliveryAndPaymentTerms = "";
    this.customTermsAndConditions = "";
    this.closingExtensionEnabled = false;
    let phone = {"phone":"","preferred":true,"mobile":false};
    this.sellerPhoneNumbers = [];
    this.sellerPhoneNumbers.push(phone);
  }

  saleInfoPosted(){
    return (this.status == AuctionStatus.SALE_INFO_POSTED ||
            this.status == AuctionStatus.LOTS_POSTED ||
            this.status == AuctionStatus.LIVE ||
            this.status == AuctionStatus.CLOSED);
  }

  lotsPosted(){
    return (this.status == AuctionStatus.LOTS_POSTED ||
            this.status == AuctionStatus.LIVE ||
            this.status == AuctionStatus.CLOSED);
  }

  async generateBidderReport(auctionId:string){
    try{
      const response = (await API.graphql(graphqlOperation(bidderReport,{auctionId:auctionId}))) as {
        data: BidderReportQuery;
      };
      // console.log('bidderReport response',response.data.bidderReport);
      return response.data.bidderReport;
    }
    catch(err){
      console.log('error generating bidderReport',err);
      throw(err)
    }
  }


}

export class LotModel {

  static WINNING_BID_STR               = "WINNING!";
  static OUT_BID_STR                   = "OUTBID!";
  static BID_UNSUCESSFUL_STR           = "YOUR BID WAS UNSUCCESSFUL";
  static AUCTION_LOT_CLOSED_STR        = "BIDDING HAS CLOSED FOR LOT";
  static NOT_AUTHORIZED_TO_BID_STR     = "NOT AUTHORIZED";

  static LOT_CATEGORIES = [
    {"key":AuctionLotCategory.COMMERCIAL,"value":"Commercial"},
    {"key":AuctionLotCategory.EMBRYO,"value":"Embryo"},
    {"key":AuctionLotCategory.FLUSH,"value":"Flush"},
    {"key":AuctionLotCategory.REGISTERED_BULL,"value":"Registered Bull"},
    {"key":AuctionLotCategory.REGISTERED_COW,"value":"Registered Cow"},
    {"key":AuctionLotCategory.REGISTERED_HEIFER,"value":"Registered Heifer"},
    {"key":AuctionLotCategory.PREGNANCY,"value":"Pregnancy"},
    {"key":AuctionLotCategory.SEMEN,"value":"Semen"},
    {"key":AuctionLotCategory.OTHER,"value":"Other"}
  ];

  static SEXED_CATEGORIES = [
    {"key":SexedCategory.BULL,"value":"Bull"},
    {"key":SexedCategory.COW,"value":"Cow"},
    {"key":SexedCategory.HEIFER,"value":"Heifer"},
    {"key":SexedCategory.SEXED,"value":"Sexed"},
    {"key":SexedCategory.UNSEXED,"value":"Unsexed"},
    {"key":SexedCategory.CONFIRMED_HEIFER,"value":"Confirmed Heifer"},
    {"key":SexedCategory.MALE,"value":"Male"},
    {"key":SexedCategory.FEMALE,"value":"Female"}
  ];

  static INTEREST_CATEGORIES = [
    // {"key":InterestType.WHOLE,"value":"Whole"},
    {"key":InterestType.THREE_FOURTHS,"value":"3/4"},
    {"key":InterestType.TWO_THIRDS,"value":"2/3"},
    {"key":InterestType.ONE_HALF,"value":"1/2"},
    {"key":InterestType.ONE_THIRD,"value":"1/3"},
    {"key":InterestType.ONE_FOURTH,"value":"1/4"}
  ];

  // static EMBRYO_TYPES = [
  //   {"key":LotEmbryoType.CONVENTIONAL,"value":"Conventional"},
  //   {"key":LotEmbryoType.IVF,"value":"IVF"}
  // ];

  static EXPORTABLE_TYPES = [
    {"key":ExportableType.Y,"value":"Y"},
    {"key":ExportableType.USA_ONLY,"value":"USA Only"}
  ];

  static EPDS_PRODUCTION = ["CED","BW","WW","YW","RADG","DMI","YH","SC"];
  static EPDS_MATERNAL = ["HP","CEM","MILK","MKH","MW","MH","$EN"];
  static EPDS_MANAGEMENT = ["DOC","CLAW","ANGLE","PAP"];
  static EPDS_WEIGHT = ["BW","200DW","400DW","600DW","WW","YW","MW"];
  static EPDS_FERTILITY = ["GL","MILK","TOTMAT","SS"];
  static EPDS_CARCASS = ["CW","EMA","RUMP","RIB","RBY","MARB","MARBF","RE","FAT","C","U"];
  static EPDS_$VALUES = ["$WBI","$SRI","$FTI","$F1I","$M","$W","$F","$G","$B","$C"];

  auctionId:string;
  lotId:string;
  title:string;
  lotOrder:number;
  biddingDisabled:boolean;
  customFields: CustomLotField[];
  lotNum:string;
  category:AuctionLotCategory;
  donorName:string;
  donorSire:string;
  donorDam:string;
  breed:string;
  consigner:string;
  specialTerms:string;
  deliveryMethods:[];
  startBid:number;
  floorPrice:number;
  interest:InterestType;
  embryoType:string;
  quantity:number;
  taxable:boolean;
  tag:string;
  regNum:string;
  regUrl:string;
  lineageHierarchy:LineageHierarchy;
  epdMeasures: EPDMeasures[];
  tattoo:string;
  dob:string;
  sexedCategory:SexedCategory;
  recipientId:string;
  exportable:ExportableType;
  footnote: string[];
  contactInfo:string;
  pictures:boolean;
  photos:LotImage[];
  videos:LotImage[];
  externalVideoUrl:string;
  bidIncrement:number;
  currentBid:number;
  nextBid:number;
  // bannerImageKey:string;
  businessAccountId:string;
  lastBidTime:string;
  version:number;
  bidCount:number;
  bidderAccountId:string;
  bidderId:string;
  bidderCount:number;
  currentMaxBid: number;
  currentMaxBidAccountId: string;
  actualEPD:boolean;
  bids:BidModel[];

  status: BidResultStatus;
  code: BidResultCode;
  message: string = "";
  isNewBidderId:boolean;

  bidsPagingTokens;
  currentBiddingPage:number = undefined;

  config = environment;
  winMsg:string = "";
  outBidMsg:string = "";

  constructor() { }

  load(item:{}){
    this.auctionId = item['auctionId'] != undefined ? item['auctionId'] : "";
    this.lotId = item['lotId'] != undefined ? item['lotId'] : "";
    this.lotNum = item['lotNum'] != undefined ? item['lotNum'] : undefined;
    this.title = item['title'] != undefined ? item['title'] : "";
    this.lotOrder = item['lotOrder'] != undefined ? item['lotOrder'] : undefined;
    this.biddingDisabled = item['biddingDisabled'] != undefined ? item['biddingDisabled'] : false;
    this.customFields = item['customFields'] != undefined ? item['customFields'] : undefined;
    this.category = item['category'] != undefined ? item['category'] : "";
    this.donorName = item['donorName'] != undefined ? item['donorName'] : undefined;
    this.donorSire = item['donorSire'] != undefined ? item['donorSire'] : undefined;
    this.donorDam = item['donorDam'] != undefined ? item['donorDam'] : undefined;
    this.breed = item['breed'] != undefined ? item['breed'] : undefined;
    this.consigner = item['consigner'] != undefined ? item['consigner'] : undefined;
    this.specialTerms = item['specialTerms'] != undefined ? item['specialTerms'] : undefined;
    this.deliveryMethods = item['deliveryMethods'] != undefined ? item['deliveryMethods'] : [];
    this.startBid = item['startBid'] != undefined ? item['startBid'] : undefined;
    this.floorPrice = item['floorPrice'] != undefined ? item['floorPrice'] : undefined;
    this.interest = item['interest'] != undefined ? item['interest'] : undefined;
    this.embryoType = item['embryoType'] != undefined ? item['embryoType'] : undefined;
    this.quantity = item['quantity'] != undefined ? item['quantity'] : undefined;
    this.taxable = item['taxable'] != undefined ? item['taxable'] : undefined;
    this.tag = item['tag'] != undefined ? item['tag'] : undefined;
    this.regNum = item['regNum'] != undefined ? item['regNum'] : undefined;
    this.regUrl = item['regUrl'] != undefined ? item['regUrl'] : undefined;
    this.lineageHierarchy = item['lineageHierarchy'] != undefined ? item['lineageHierarchy'] : undefined;
    this.epdMeasures = item['epdMeasures'] != undefined ? item['epdMeasures'] : undefined;
    this.tattoo = item['tattoo'] != undefined ? item['tattoo'] : undefined;
    this.dob = item['dob'] != undefined ? item['dob'] : undefined;
    this.sexedCategory = item['sexedCategory'] != undefined ? item['sexedCategory'] : undefined;
    this.recipientId = item['recipientId'] != undefined ? item['recipientId'] : undefined;
    this.exportable = item['exportable'] != undefined ? item['exportable'] : undefined;
    this.footnote = item['footnote'] != undefined ? item['footnote'] : undefined;
    this.contactInfo = item['contactInfo'] != undefined ? item['contactInfo'] : undefined;
    this.pictures = item['pictures'] != undefined ? item['pictures'] : undefined;
    this.photos = item['photos'] != undefined ? item['photos'] : [];
    this.videos = item['videos'] != undefined ? item['videos'] : [];
    this.externalVideoUrl = item['externalVideoUrl'] != undefined ? item['externalVideoUrl'] : undefined;
    this.bidIncrement = item['bidIncrement'] != undefined ? item['bidIncrement'] : undefined;
    this.currentBid = item['currentBid'] != undefined ? item['currentBid'] : undefined;
    this.nextBid = item['nextBid'] != undefined ? item['nextBid'] : undefined;
    // this.bannerImageKey = item['bannerImageKey'] != undefined ? item['bannerImageKey'] : undefined;
    this.businessAccountId = item['businessAccountId'] != undefined ? item['businessAccountId'] : undefined;
    this.bidCount = item['bidCount'] != undefined ? item['bidCount'] : undefined;
    this.bidderAccountId = item['bidderAccountId'] != undefined ? item['bidderAccountId'] : undefined;
    this.bidderId = item['bidderId'] != undefined ? item['bidderId'] : undefined;
    this.bidderCount = item['bidderCount'] != undefined ? item['bidderCount'] : undefined;
    this.actualEPD = item['actualEPD'] != undefined ? item['actualEPD'] : true;
    this.lastBidTime = item['lastBidTime'] != undefined ? item['lastBidTime'] : undefined;
    this.version = item['version'] != undefined ? item['version'] : undefined;
    this.currentMaxBid = item['currentMaxBid'];
    this.currentMaxBidAccountId = item['currentMaxBidAccountId'];

    this.bids = [];
    if( item['bids'] != undefined && item['bids']['items'] != undefined && item['bids']['items'].length > 0){
      for(let bid of item['bids']['items']){
        var bidModel = new BidModel();
        bidModel.load(bid);
        this.bids.push(bidModel);
      }
    }

    this.bidsPagingTokens = [];
    if( item['bids'] != undefined){
      this.currentBiddingPage = 0;
      let pagingTokens = {
        "page":0,
        "prev":undefined,
        "current":undefined,
        "next":item['bids']['nextToken']
      };
      this.bidsPagingTokens.push(pagingTokens);
    }

    return this;
  }

  async getNextBids(bidLimit:number=10){

    if(this.currentBiddingPage == undefined){
      this.bidsPagingTokens = [];
      this.currentBiddingPage = 0;
      let nextToken = await this.pageBids(bidLimit,undefined);
      let pagingTokens = {"page":this.currentBiddingPage,"prev":undefined,"current":undefined ,"next":nextToken };
      this.bidsPagingTokens.push(pagingTokens);
    }
    else if(this.moreBidsAvailable()){
      let current = this.getBiddingPage(this.currentBiddingPage);
      let next = this.getBiddingPage(this.currentBiddingPage+1);
      let nextToken = await this.pageBids(bidLimit,current['next']);
      this.currentBiddingPage = this.currentBiddingPage + 1;
      if(next == undefined){
        let pagingTokens = {"page":this.currentBiddingPage,"prev":current['current'],"current":current['next'] ,"next":nextToken };
        this.bidsPagingTokens.push(pagingTokens);
      }
    }
  }

  async getPreviousBids(bidLimit:number=10){
    if(this.previousBidsAvailable()){
      this.currentBiddingPage = this.currentBiddingPage - 1;
      let current = this.getBiddingPage(this.currentBiddingPage);
      let nextToken = await this.pageBids(bidLimit,current['current']);
    }
  }

  getBiddingPage(biddingPage){
    return this.bidsPagingTokens.find((el)=>{return el['page'] == biddingPage})
  }

  async pageBids(bidLimit,bidsToken:string) {
    let queryVars = {};
    queryVars['auctionId'] = this.auctionId;
    queryVars['lotId'] = this.lotId;
    queryVars['bidLimit'] = bidLimit;
    bidsToken != undefined ? queryVars['bidNextToken'] = bidsToken : "";

    try{
      const response = (await API.graphql(graphqlOperation(auctionLotBidsCustom,queryVars))) as {
        data: AuctionLotBidsCustomQuery;
      };
      // console.log('AuctionLotBidsCustomQuery',response.data);
      var item = response.data.auctionLot;

      this.bids = [];
      if( item['bids']['items'] != undefined && item['bids']['items'].length > 0){
        for(let bid of item['bids']['items']){
          var bidModel = new BidModel();
          bidModel.load(bid);
          this.bids.push(bidModel);
        }
      }

      return item['bids']['nextToken'];

    }
    catch(err){
      console.log('error retrieving lot',err);
      throw(err)
    }
    return this;
  }

  moreBidsAvailable(){
    let biddingPage = this.getBiddingPage(this.currentBiddingPage);
    return (biddingPage != undefined && biddingPage['next'] != undefined);
  }

  previousBidsAvailable(){
    return this.currentBiddingPage > 0;
  }

  async get(auctionId:string, lotId:string) {
    let queryVars = {};
    queryVars['auctionId'] = auctionId;
    queryVars['lotId'] = lotId;

    try{
      const response = (await API.graphql(graphqlOperation(auctionLotCustom,queryVars))) as {
        data: AuctionLotCustomQuery;
      };
      console.log('get lot',response.data);
      this.load(response.data.auctionLot);
    }
    catch(err){
      console.log('error retrieving lot',err);
      throw(err)
    }
    return this;
  }

  async loadVersion() {
    let queryVars = {};
    queryVars['auctionId'] = this.auctionId;
    queryVars['lotId'] = this.lotId;

    try{
      const response = (await API.graphql(graphqlOperation(auctionLotVersionCustom,queryVars))) as {
        data: AuctionLotVersionCustomQuery;
      };
      // console.log('auctionLotVersionCustom',response.data);
      this.version = response.data.auctionLot['version'];
    }
    catch(err){
      console.log('error retrieving lot',err);
      throw(err)
    }
    return this.version;
  }

  async loadCounts() {
    let queryVars = {};
    queryVars['auctionId'] = this.auctionId;
    queryVars['lotId'] = this.lotId;

    try{
      const response = (await API.graphql(graphqlOperation(auctionLotCountsCustom,queryVars))) as {
        data: AuctionLotCountsCustomQuery;
      };
      // console.log('auctionLotCountsCustom',response.data);
      this.bidCount = response.data.auctionLot['bidCount'];
      this.bidderCount = response.data.auctionLot['bidderCount'];
    }
    catch(err){
      console.log('error retrieving lot',err);
      throw(err)
    }
    return this.version;
  }


  async update(){
    if(this.lotId == undefined || this.lotId.length == 0){
      return this.create();
    }

    try{
      let updateVars = {};
      updateVars['auctionId'] = this.auctionId;
      updateVars['lotId'] = this.lotId;
      updateVars['expectedVersion'] = this.version;
      updateVars['input'] = this.getUpdateVars();

      const response = (await API.graphql(graphqlOperation(updateAuctionLot,updateVars))) as {
        data: UpdateAuctionLotMutation;
      };

      if(response.data == undefined || response.data.updateAuctionLot == undefined){
        throw new Error('Error updating auction lot. Contact your administrator.');
      }
      // console.log('auction update',response);
      await this.get(this.auctionId,this.lotId);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating auction lot',err);
      throw(errorMsg)
    }
  }

  async updateLotPriceParms(floorPrice:number,bidIncrement:number){
    try{
      await this.loadVersion();
      let updateVars = {};
      updateVars['auctionId'] = this.auctionId;
      updateVars['lotId'] = this.lotId;
      updateVars['expectedVersion'] = this.version;
      let nextBid:number = this.currentBid + bidIncrement;
      updateVars['input'] = {"floorPrice":floorPrice,"bidIncrement":bidIncrement, "nextBid":nextBid };

      const response = (await API.graphql(graphqlOperation(updateAuctionLotPriceParmsCustom,updateVars))) as {
        data: UpdateAuctionLotPriceParmsCustomMutation;
      };

      if(response.data == undefined || response.data.updateAuctionLot == undefined){
        throw new Error('Error updating auction lot price parameters. Contact your administrator.');
      }
      // console.log('auction lot price parameters',response);
      this.floorPrice = response.data.updateAuctionLot['floorPrice'];
      this.bidIncrement = response.data.updateAuctionLot['bidIncrement'];
      this.nextBid = response.data.updateAuctionLot['nextBid'];
      this.version = response.data.updateAuctionLot['version'];
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating auction lot',err);
      throw(errorMsg)
    }
  }

  async create(){
    let updateVars = {};
    updateVars['auctionId'] = this.auctionId;
    updateVars['input'] = this.getUpdateVars();

    try{
      const response = (await API.graphql(graphqlOperation(createAuctionLot,updateVars))) as {
        data: CreateAuctionLotMutation;
      };

      if(response.data == undefined || response.data.createAuctionLot == undefined){
        throw new Error('Error creating auction lot. Contact your administrator.');
      }
      // console.log('product create',response);
      this.auctionId = response.data.createAuctionLot.auctionId;
      this.lotId = response.data.createAuctionLot.lotId;
      await this.get(this.auctionId,this.lotId);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating auction lot',err);
      throw(errorMsg)
    }
  }

  async delete(){

    try{
      let updateVars = {};
      updateVars['auctionId'] = this.auctionId;
      updateVars['lotId'] = this.lotId;

      const response = (await API.graphql(graphqlOperation(deleteAuctionLot,updateVars))) as {
        data: DeleteAuctionLotMutation;
      };

      if(response.data == undefined || response.data.deleteAuctionLot == undefined){
        throw new Error('Error deleting auction lot. Contact your administrator.');
      }
      return response.data.deleteAuctionLot;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting auction lot',err);
      throw(errorMsg)
    }
  }

  async bidNow(bidderAccountId){
    var bidPrice = 0;
    if(this.nextBid != undefined){
      bidPrice = this.nextBid;
    }
    else if(this.startBid != undefined){
       bidPrice = this.startBid;
    }

    if(bidPrice > 0){
      try{
        var response = await this.updateBid(bidPrice,undefined,bidderAccountId);
        // console.log('response',response);
        this.processBidResponse(response);
        return response;
      }
      catch(err){
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error updating bid',err);
        throw(errorMsg)
      }
    }
  }

  async proxyBidNow(bidderAccountId){
    var bidPrice = 0;
    if(this.nextBid != undefined){
      bidPrice = this.nextBid;
    }
    else if(this.startBid != undefined){
       bidPrice = this.startBid;
    }

    if(bidPrice > 0){
      try{
        var response = await this.updateBid(bidPrice,undefined,bidderAccountId);
        this.processBidResponse(response);
        return response;
      }
      catch(err){
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error updating bid',err);
        throw(errorMsg)
      }
    }
  }

  processBidResponse(response){
    this.winMsg = "";
    this.outBidMsg = "";
    if(response.code == BidResultCode.BID_SUCCESS){
      this.winMsg = LotModel.WINNING_BID_STR;
    }
    else if(response.code == BidResultCode.BID_TOO_LOW){
      this.outBidMsg = LotModel.OUT_BID_STR;
    }
    else if(response.code == BidResultCode.AUCTION_LOT_CLOSED){
      this.outBidMsg = LotModel.AUCTION_LOT_CLOSED_STR;
    }
    else if(response.code == BidResultCode.NOT_AUTHORIZED){
      this.outBidMsg = LotModel.NOT_AUTHORIZED_TO_BID_STR;
    }
    else {
      this.outBidMsg = LotModel.BID_UNSUCESSFUL_STR;
    }

  }

  async submitMaxBid(maxBidPrice: number, accountId: string) {
    try {
      let updateVars: SubmitMaxBidMutationVariables = {
        auctionId: this.auctionId,
        lotId: this.lotId,
        maxBidPrice: maxBidPrice
      };
      const response = (await API.graphql(graphqlOperation(submitMaxBid, updateVars))) as {
        data: SubmitMaxBidMutation;
      };

      if (response.data == undefined || response.data.submitMaxBid == undefined){
        throw new Error('Error updating bid. Contact your administrator.');
      }

      const payload = response.data.submitMaxBid.data;

      this.bidCount = payload.bidCount;
      this.bidIncrement = payload.bidIncrement;
      this.bidderId = payload.bidderId;
      this.currentBid = payload.currentBid;
      this.isNewBidderId = payload.isNewBidderId;
      this.nextBid = payload.nextBid;
      this.currentMaxBid = maxBidPrice;
      this.currentMaxBidAccountId = accountId;
    }
    catch (err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error setting max bid',err);
      throw(errorMsg)
    }
  }

  async updateBid(bidPrice,maxBidPrice=undefined,targetAccountId=undefined){

    try{
      this.message = "";
      let updateVars = {};
      updateVars['auctionId'] = this.auctionId;
      updateVars['lotId'] = this.lotId;
      updateVars['bidPrice'] = bidPrice;
      maxBidPrice != undefined ? updateVars['maxBidPrice'] = maxBidPrice : "";
      targetAccountId != undefined ? updateVars['targetAccountId'] = targetAccountId : "";

      const response = (await API.graphql(graphqlOperation(submitBid,updateVars))) as {
        data: SubmitBidMutation;
      };

      // console.log('response for updateBid',response);
      if(response.data == undefined || response.data.submitBid == undefined){
        throw new Error('Error updating bid. Contact your administrator.');
      }

      this.bidCount = response.data.submitBid.data.bidCount;
      this.bidIncrement = response.data.submitBid.data.bidIncrement;
      this.bidderId = response.data.submitBid.data.bidderId;
      this.currentBid = response.data.submitBid.data.currentBid;
      this.isNewBidderId = response.data.submitBid.data.isNewBidderId;
      this.nextBid = response.data.submitBid.data.nextBid;

      this.message = response.data.submitBid.message;
      this.code = response.data.submitBid.code;
      this.status = response.data.submitBid.status;

      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating bid',err);
      throw(errorMsg)
    }
  }

  getUpdateVars(){
    var returnValue = {};
    returnValue['title'] = this.title;
    returnValue['lotOrder'] = this.lotOrder;
    returnValue['biddingDisabled'] = this.biddingDisabled;
    returnValue['customFields'] = this.customFields;
    returnValue['lotNum'] = this.lotNum;
    returnValue['category'] = this.category;
    returnValue['donorName'] = this.donorName;
    returnValue['donorSire'] = this.donorSire;
    returnValue['donorDam'] = this.donorDam;
    returnValue['breed'] = this.breed;
    returnValue['consigner'] = this.consigner;
    returnValue['specialTerms'] = this.specialTerms;
    returnValue['deliveryMethods'] = this.deliveryMethods;
    returnValue['startBid'] = this.startBid;
    returnValue['floorPrice'] = this.floorPrice;
    returnValue['interest'] = this.interest;
    returnValue['embryoType'] = this.embryoType;
    returnValue['quantity'] = this.quantity;
    returnValue['taxable'] = this.taxable;
    returnValue['tag'] = this.tag;
    returnValue['regNum'] = this.regNum;
    returnValue['regUrl'] = this.regUrl;
    returnValue['lineageHierarchy'] = this.lineageHierarchy;
    returnValue['epdMeasures'] = this.epdMeasures;
    returnValue['tattoo'] = this.tattoo;
    returnValue['dob'] = this.dob;
    returnValue['sexedCategory'] = this.sexedCategory;
    returnValue['recipientId'] = this.recipientId;
    returnValue['exportable'] = this.exportable;
    returnValue['footnote'] = this.footnote;
    returnValue['contactInfo'] = this.contactInfo;
    returnValue['pictures'] = this.pictures;
    returnValue['photos'] = this.photos;
    returnValue['videos'] = this.videos;
    returnValue['externalVideoUrl'] = this.externalVideoUrl;
    returnValue['bidIncrement'] = this.bidIncrement;
    returnValue['nextBid'] = this.nextBid;
    returnValue['businessAccountId'] = this.businessAccountId;
    returnValue['actualEPD'] = this.actualEPD;
    return returnValue;
  }

  getLotCoverImage(){
    var storageName = "";
    var returnValue = "";
    if(this.photos != undefined && this.photos.length > 0){
      let coverLotImage = this.photos.find((el)=>{ return el.cover==true});
      if(coverLotImage != undefined){
        storageName = coverLotImage.storageName;
      }
      else {
        storageName = this.photos[0].storageName;
      }
      returnValue = "https://" + this.config.image_path_url + "/" + this.lotId + "/" + storageName;
    }
    return returnValue;
  }

  getPhotoUrls(){
    var returnValue = [];
    for(let item of this.photos){
      let url = "https://" + this.config.image_path_url + "/" + this.lotId + "/" + item.storageName;
      returnValue.push(url);
    }
    return returnValue;
  }

  getPhotoUrl(key:LotImage){
    let url = "https://" + this.config.image_path_url + "/" + this.lotId + "/" + key.storageName;
    return url;
  }

  getVideoUrls(){
    var returnValue = [];
    for(let item of this.videos){
      let url = "https://" + this.config.asset_path_url + "/" + this.lotId + "/" + item.storageName;
      returnValue.push(url);
    }
    return returnValue;
  }

  getVideoUrl(key:LotImage){
      let url = "https://" + this.config.asset_path_url + "/" + this.lotId + "/" + key.storageName;
      return url;
  }

  getFirstVideoUrl(){
    var returnValue = "";
    for(let item of this.videos){
      returnValue = "https://" + this.config.asset_path_url + "/" + this.lotId + "/" + item.storageName;
      break;
    }
    return returnValue;
  }

  async getFileURL(filename:string){

    try{
      const response = (await API.graphql(graphqlOperation(manageFile,{itemId:this.lotId,itemKey:filename,public:true}))) as {
        data: ManageFileMutation;
      };
      // console.log('getFileURL for ',filename," ",response);
      return response.data.manageFile;
    }
    catch(err){
      console.log('error getting file url',err);
      throw(err)
    }
  }

  async cloneAuctionLotAndAssets(auctionId:string,lotId:string){
      let queryVars = {
        auctionId: auctionId,
        lotId: lotId
      }

    try{
      const response = (await API.graphql(graphqlOperation(cloneAuctionLotAndAssets,queryVars))) as {
        data: CloneAuctionLotAndAssetsMutation;
      };

      if(response.data == undefined || response.data.cloneAuctionLotAndAssets == undefined){
        throw new Error('Error cloning auction lot and assets. Contact your administrator.');
      }
      // console.log('cloneAuctionLotAndAssets',response);
      let newLotId = response.data.cloneAuctionLotAndAssets['lotId'];
      return this.get(auctionId,newLotId);
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error cloning auction lot and assets',err);
      throw(errorMsg)
    }
  }

  getInitializedEPDs(){
    var returnValue = [];

    for(let item of LotModel.EPDS_PRODUCTION){
      let epd = {
        category:EPDCategory.PRODUCTION,
        measure:item,
        value:"",
        accuracy:"",
        progeny:"",
        percentage:"",
        order:""
      };
      returnValue.push(epd);
    }

    for(let item of LotModel.EPDS_MATERNAL){
      let epd = {
        category:EPDCategory.MATERNAL,
        measure:item,
        value:"",
        accuracy:"",
        progeny:"",
        percentage:"",
        order:""
      };
      returnValue.push(epd);
    }

    for(let item of LotModel.EPDS_MANAGEMENT){
      let epd = {
        category:EPDCategory.MANAGEMENT,
        measure:item,
        value:"",
        accuracy:"",
        progeny:"",
        percentage:"",
        order:""
      };
      returnValue.push(epd);
    }

    for(let item of LotModel.EPDS_CARCASS){
      let epd = {
        category:EPDCategory.CARCASS,
        measure:item,
        value:"",
        accuracy:"",
        progeny:"",
        percentage:"",
        order:""
      };
      returnValue.push(epd);
    }

    for(let item of LotModel.EPDS_$VALUES){
      let epd = {
        category:EPDCategory.DOLLAR_VALUES,
        measure:item,
        value:"",
        accuracy:"",
        progeny:"",
        percentage:"",
        order:""
      };
      returnValue.push(epd);
    }

    return returnValue;

  }

  getInitializedLineage(){
    var returnValue = {
      sire:"",
      sireRegNum:"",
      sireUrl:"",
      dam:"",
      damRegNum:"",
      damUrl:"",
      sireParents : {
        sire:"",
        sireRegNum:"",
        sireUrl:"",
        dam:"",
        damRegNum:"",
        damUrl:"",
        sireParents:undefined,
        damParents:undefined
      },
      damParents : {
        sire:"",
        sireRegNum:"",
        sireUrl:"",
        dam:"",
        damRegNum:"",
        damUrl:"",
        sireParents:undefined,
        damParents:undefined
      }
    };
    return returnValue;

  }

  getLotCategoryFriendlyName(){
    let returnValue = "";
    if(this.category != undefined){
      let item = LotModel.LOT_CATEGORIES.find((el)=>{return el.key==this.category})
      returnValue = item != undefined ? item.value : "";
    }
    return returnValue;
  }

  epdsExist(){
    var returnValue = false;
    if(this.epdMeasures != undefined && this.epdMeasures.length > 0){
      for(let item of this.epdMeasures){
        if(!this.isEmpty(item.value)){
          returnValue = true;
          break;
        }
      }
    }

    return returnValue;
  }

  epdsExistForCategory(epds){
    var returnValue = false;
    if(epds != undefined && epds.length > 0){
      for(let item of epds){
        if(!this.isEmpty(item.value)){
          returnValue = true;
          break;
        }
      }
    }
    return returnValue;
  }

  lineageHierachyExists(){
    var returnValue = false;
    if(this.lineageHierarchy != undefined){
      if(this.lineageHierachyHasValues(this.lineageHierarchy)  ||
         this.lineageHierachyHasValues(this.lineageHierarchy.sireParents)  ||
         this.lineageHierachyHasValues(this.lineageHierarchy.damParents)){
           returnValue = true;
      }
    }
    return returnValue;
  }


  lineageHierachyHasValues(lineageHierarchy){
    var returnValue = false;
    if(lineageHierarchy != undefined){
      if( !this.isEmpty(lineageHierarchy.sire) ||
          !this.isEmpty(lineageHierarchy.sireRegNum) ||
          !this.isEmpty(lineageHierarchy.sireUrl) ||
          !this.isEmpty(lineageHierarchy.dam) ||
          !this.isEmpty(lineageHierarchy.damRegNum) ||
          !this.isEmpty(lineageHierarchy.damUrl) ){
            returnValue = true;
          }
    }
    return returnValue;
  }

  isEmpty(text: any) {
    return (typeof text === 'undefined') || text === '' || text === null
  }

}

export class AuctionReportLineItem {
  lotId: string;
  lotNum: string;
  title: string;
  finalBid: number;
  quantity: number;
  interest: InterestType;
  finalPrice: number;
  saleStatus: SaleStatus;
  invoiceId: string;
  invoice: Invoice;
  businessAccountId: string;
  winningBidderFirstName: string;
  winningBidderLastName: string;
  winningBidderAddress: Array<string>;
  winningBidderEmail: string;
  paymentStatus: PaymentStatus;
  sellerName: string;
  sellerPhoneNumber: string;
  sellerEmail: string;
  additionalLineItems: Array<AdjustmentLineItem>;
}

export class AdjustmentLineItem {
  amount: number;
  note: string;
}

export class SendInvoicesResult {
  auctionId: string;
  invoiceSentDate: string;
  invoices: Array<Invoice>;
}

export class SendInvoiceResult {
  auctionId: string;
  invoice: Invoice;
}

export interface AuctionReportPayment {
  paymentDate: string;
  amount: number;
  fees: number;
}

export class AuctionReportModel {
  public auctionId: string;
  public invoiceSentDate: string;
  public payments: AuctionReportPayment[];
  public auctionName: string;
  public auctionStartDate: string;
  public auctionClosingDate: string;
  public totalGrossSales: number | null;
  public totalBidderCount: number | null;
  public totalLotCount: number | null;
  public version: number;
  public lineItems: AuctionReportLineItem[] = [];

  constructor() { }

  public async get(auctionId: string, sortField: string, sortDirection: string, lotLimit: number, lastItemId: string): Promise<AuctionReportModel> {
    try {
      if (!auctionId) return null;

      let queryVars: AuctionReportCustomQueryVariables = {
        auctionId: auctionId,
        limit: lotLimit,
        sortField: AuctionReportSortField[sortField],
        sortDirection: SortDirection[sortDirection],
      }
      if (lastItemId) {
        queryVars.nextToken = lastItemId;
      }

      const response = (await API.graphql(graphqlOperation(auctionReportCustom, queryVars))) as {
        data: AuctionReportCustomQuery;
      };

      const auctionReportResult = response.data.auctionReport;
      if (response.data == undefined) {
        throw new Error('Error fetching auction report.');
      } else if (auctionReportResult == undefined) {
        return null;
      }
      this.load(auctionReportResult)
      return this;
    }
    catch(err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('Error fetching auction report', err);
      throw(errorMsg);
    }
  }

  public async updateWinningBuyerInfo(lineItem: AuctionReportLineItem): Promise<AuctionReportLineItem> {
    const input = {
      winningBidderFirstName: lineItem.winningBidderFirstName,
      winningBidderLastName: lineItem.winningBidderLastName,
      winningBidderEmail: lineItem.winningBidderEmail,
    }
    return this.updateLineItem(lineItem, input);
  }

  public async updateSaleStatus(lineItem: AuctionReportLineItem): Promise<AuctionReportLineItem> {
    const input = {
      saleStatus: lineItem.saleStatus
    }
    return this.updateLineItem(lineItem, input);
  }

  public async updatePaymentStatus(lineItem: AuctionReportLineItem): Promise<AuctionReportLineItem> {
    const input = {
      paymentStatus: lineItem.paymentStatus
    }
    return this.updateLineItem(lineItem, input);
  }

  public async adjustLineItemAmounts(lineItem: AuctionReportLineItem): Promise<AuctionReportLineItem> {
    const input = {
      quantity: lineItem.quantity,
      finalPrice: lineItem.finalPrice,
      additionalLineItems: this.trimEmptyLineItems(lineItem.additionalLineItems),
    }

    return this.updateLineItem(lineItem, input);
  }

  private trimEmptyLineItems(lineItems: AdjustmentLineItem[]) {
    var result: AdjustmentLineItem[] = []
    lineItems.forEach(lineItem => {
      if (lineItem.amount) {
        result.push(lineItem);
      }
    })
    return result;
  }

  public async updatePayments(payments: AuctionReportPayment[]): Promise<AuctionReportModel> {
    let queryVars: UpdateAuctionReportMutationVariables = {
      auctionId: this.auctionId,
      expectedVersion: this.version,
      input: { payments: payments }
    }

    const response = (await API.graphql(graphqlOperation(updateAuctionReport, queryVars))) as {
      data: UpdateAuctionReportMutation;
    };

    const auctionReportResult: any = response.data.updateAuctionReport;
    if (response.data == undefined) {
      throw new Error('Could not update the auction report.');
    } else if (auctionReportResult == undefined) {
      return null;
    }
    return auctionReportResult;
  }

  private async updateLineItem(lineItem: AuctionReportLineItem, fieldInput: any): Promise<AuctionReportLineItem> {
    let queryVars: UpdateAuctionReportLineItemMutationVariables = {
      auctionId: this.auctionId,
      lotId: lineItem.lotId,
      input: fieldInput
    }

    const response = (await API.graphql(graphqlOperation(updateAuctionReportLineItem, queryVars))) as {
      data: UpdateAuctionReportLineItemMutation;
    };

    const auctionReportResult: AuctionReportLineItem = response.data.updateAuctionReportLineItem;
    if (response.data == undefined) {
      throw new Error('Error performing update.');
    } else if (auctionReportResult == undefined) {
      return null;
    }
    return auctionReportResult;
  }

  public async moveLineItemsToDirectPaidStatus() {
    var that = this;
    const updatePaymentStatuses = async() => {
      const promises = (that.lineItems || []).map(async item => {
        item.paymentStatus = PaymentStatus.DIRECT_PAID;
        await that.updatePaymentStatus(item);
      });
      await Promise.all(promises);
    }
    await updatePaymentStatuses();
  }

  public async sendInvoice(sellerKey: string, buyerKey: string) {
    let queryVars: SendInvoiceMutationVariables = {
      auctionId: this.auctionId,
      sellerEmail: sellerKey,
      buyerEmail: buyerKey
    }

    const response = (await API.graphql(graphqlOperation(sendInvoice, queryVars))) as {
      data: SendInvoiceMutation;
    };

    const result: SendInvoiceResult = response.data.sendInvoice;
    if (response.data == undefined) {
      throw new Error('Error performing update.');
    } else if (result == undefined) {
      return null;
    }
    return result;
  }

  public async sendInvoices(): Promise<SendInvoicesResult> {
    let queryVars: SendInvoicesMutationVariables = {
      auctionId: this.auctionId,
    }

    const response = (await API.graphql(graphqlOperation(sendInvoices, queryVars))) as {
      data: SendInvoicesMutation;
    };

    const result: SendInvoicesResult = response.data.sendInvoices;
    if (response.data == undefined) {
      throw new Error('Error performing update.');
    } else if (result == undefined) {
      return null;
    }
    return result;
  }

  load(item: {}) {
    Object.assign(this, item);
    return this;
  }
}


export class BidModel {
  auctionId:string;
  lotId:string;
  bidType: BidType;
  bidderFirstName:string;
  bidderLastName:string;
  price:number;
  bidTime: string;
  bidderAccountId: string;
  bidderId:string;

  load(item:{}){
    this.auctionId = item['auctionId'] != undefined ? item['auctionId'] : "";
    this.lotId = item['lotId'] != undefined ? item['lotId'] : "";
    this.bidType = item['bidType'] != undefined ? item['bidType'] : "";
    this.bidderFirstName = item['bidderFirstName'] != undefined ? item['bidderFirstName'] : "";
    this.bidderLastName = item['bidderLastName'] != undefined ? item['bidderLastName'] : "";
    this.price = item['price'] != undefined ? item['price'] : "";
    this.bidTime = item['bidTime'] != undefined ? item['bidTime'] : "";
    this.bidderAccountId = item['bidderAccountId'] != undefined ? item['bidderAccountId'] : "";
    this.bidderId = item['bidderId'] != undefined ? item['bidderId'] : undefined;
    return this;
  }

  isAdmin(){
    return this.bidType == BidType.ADMIN;
  }
}

export class BidderModel {
  auctionId:string;
  lotId:string;
  bidderFirstName:string;
  bidderLastName:string;
  bidderAccountId: string;

  load(item:{}){
    this.auctionId = item['auctionId'] != undefined ? item['auctionId'] : "";
    this.lotId = item['lotId'] != undefined ? item['lotId'] : "";
    this.bidderFirstName = item['bidderFirstName'] != undefined ? item['bidderFirstName'] : "";
    this.bidderLastName = item['bidderLastName'] != undefined ? item['bidderLastName'] : "";
    this.bidderAccountId = item['bidderAccountId'] != undefined ? item['bidderAccountId'] : "";
    return this;
  }

}