<div class="container-fluid mt-3 py-4 px-3" *ngIf="auction">
    <div class="row">
      <div class="col-md-8" *ngIf="saleOverviewExists()">
        <div class="row">
          <div class="col">
              <app-section-header>Sale Overview</app-section-header>
          </div>
        </div>

        <div class="row mt-2" *ngFor="let item of auction.saleOverview">
            <div class="col">
                <div>
                    <div markdown>{{ item }}</div>
                </div>
            </div>
        </div>

      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col">
            <app-section-header>Sale Contact Info</app-section-header>
            <div class="description-text" *ngIf="getSellerContactName().length > 0">{{ getSellerContactName() }}</div>
            <div class="description-text" *ngFor="let item of getSellerContactPhoneNumbers()">{{ item.phone }}</div>
            <div class="link-text" *ngIf="getSellerContactEmail().length > 0"><a class="link-text" [href]="getSellerContactMailToUrl()" target="_blank">{{ getSellerContactEmail() }}</a></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="auction && auction.catalogKey">
          <div class="col">
            <app-section-header>Catalog</app-section-header>
            <div class="link-text"><a class="link-text" target="_blank" [href]="auction.getCatalogUrl()">View</a></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="isLocationVisible()">
          <div class="col">
            <app-section-header>Location</app-section-header>
            <div class="description-text">{{ getPhysicalLocation() }}</div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="!isConsignmentSale()">
          <div class="col">
            <app-section-header>Seller</app-section-header>
            <div class="description-text" *ngIf="getSellerBusinessName().length > 0">{{ getSellerBusinessName() }}</div>
            <div class="description-text" *ngIf="getSellerAddressOne().length > 0">{{ getSellerAddressOne() }}</div>
            <div class="description-text" *ngIf="getSellerAddressTwo().length > 0">{{ getSellerAddressTwo() }}</div>
            <div class="description-text" *ngIf="getPhysicalLocation().length > 0">{{ getPhysicalLocation() }}</div>
            <div class="description-text" *ngFor="let item of getSellerPhoneNumbers()">{{ item.phone }}</div>
            <div class="link-text" *ngIf="getSellerEmail().length > 0"><a class="link-text" [href]="getSellerMailToUrl()" target="_blank">{{ getSellerEmail() }}</a></div>
            <div class="link-text" *ngIf="getSellerWebSite().length > 0"><a class="link-text" [href]="getSellerWebSiteUrl()" target="_blank">{{ getSellerWebSite() }}</a></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="auction.salesInfoNotes">
          <div class="col">
            <app-section-header>Auction Notes</app-section-header>
            <div class="description-text">{{ auction.salesInfoNotes }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row banner-image-row d-flex flex-row flex-wrap" *ngIf="getSalesBannerURL() != ''">
      <img class="lotpic" src="{{ getSalesBannerURL() }} ">
    </div>
    <div class="row mt-3">
      <div class="col-sm-3">
        <div><a routerLink="/buyers" fragment="delivery-payment" class="link-text">Delivery and Payment</a></div>
        <p *ngIf="auction.hasCustomDeliveryAndPaymentTerms">{{ auction.customDeliveryAndPaymentTerms }}</p>
        <div class="mt-2"><a routerLink="/buyers" fragment="terms-and-conditions" class="link-text">Terms and Conditions</a></div>
        <p *ngIf="auction.hasCustomTermsAndConditions">{{ auction.customTermsAndConditions }}</p>
      </div>
      <div class="col-sm-9 mt-3 mt-sm-0">
        <app-section-header>{{theme.app_title}} Sale Rep Contact</app-section-header>
        <div class="description-text">{{ auction.salesRepName }}</div>
        <div class="description-text">{{ auction.salesRepContact }}</div>
        <div><a class="link-text" [href]="'mailto:' + auction.salesRepEmail">{{ auction.salesRepEmail }}</a></div>
      </div>
    </div>
</div>