/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuction = /* GraphQL */ `
  mutation CreateAuction($input: CreateAuctionInput) {
    createAuction(input: $input) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      closingExtensionTriggered
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      delisted
      createdAt
      updatedAt
      version
      lots {
        nextToken
      }
      lot {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
      }
    }
  }
`;
export const updateAuction = /* GraphQL */ `
  mutation UpdateAuction(
    $auctionId: ID!
    $expectedVersion: Int!
    $input: UpdateAuctionInput
  ) {
    updateAuction(
      auctionId: $auctionId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      closingExtensionTriggered
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      delisted
      createdAt
      updatedAt
      version
      lots {
        nextToken
      }
      lot {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
      }
    }
  }
`;
export const deleteAuction = /* GraphQL */ `
  mutation DeleteAuction($auctionId: ID!) {
    deleteAuction(auctionId: $auctionId) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      closingExtensionTriggered
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      delisted
      createdAt
      updatedAt
      version
      lots {
        nextToken
      }
      lot {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
      }
    }
  }
`;
export const deleteAuctionAndAssets = /* GraphQL */ `
  mutation DeleteAuctionAndAssets($auctionId: ID!) {
    deleteAuctionAndAssets(auctionId: $auctionId) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      closingExtensionTriggered
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      delisted
      createdAt
      updatedAt
      version
      lots {
        nextToken
      }
      lot {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
      }
    }
  }
`;
export const cloneAuctionAndAssets = /* GraphQL */ `
  mutation CloneAuctionAndAssets($auctionId: ID!) {
    cloneAuctionAndAssets(auctionId: $auctionId) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      closingExtensionTriggered
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      delisted
      createdAt
      updatedAt
      version
      lots {
        nextToken
      }
      lot {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
      }
    }
  }
`;
export const addAuctionBusiness = /* GraphQL */ `
  mutation AddAuctionBusiness($auctionId: ID!, $businessAccountId: ID!) {
    addAuctionBusiness(
      auctionId: $auctionId
      businessAccountId: $businessAccountId
    ) {
      businessAccountId
    }
  }
`;
export const deleteAuctionBusiness = /* GraphQL */ `
  mutation DeleteAuctionBusiness($auctionId: ID!, $businessAccountId: ID!) {
    deleteAuctionBusiness(
      auctionId: $auctionId
      businessAccountId: $businessAccountId
    ) {
      businessAccountId
    }
  }
`;
export const createAuctionLot = /* GraphQL */ `
  mutation CreateAuctionLot($auctionId: ID!, $input: CreateAuctionLotInput) {
    createAuctionLot(auctionId: $auctionId, input: $input) {
      auctionId
      lotId
      lotNum
      title
      lotOrder
      biddingDisabled
      customFields {
        customLabel
        customValue
      }
      embryoType
      category
      donorName
      donorSire
      donorDam
      breed
      consigner
      specialTerms
      deliveryMethods
      startBid
      floorPrice
      interest
      quantity
      taxable
      tag
      regNum
      regUrl
      lineageHierarchy {
        sire
        sireRegNum
        sireUrl
        dam
        damRegNum
        damUrl
      }
      epdMeasures {
        category
        measure
        value
        accuracy
        progeny
        percentage
        order
      }
      tattoo
      dob
      sexedCategory
      recipientId
      exportable
      footnote
      contactInfo
      pictures
      photos {
        storageName
        fileName
        caption
        cover
      }
      videos {
        storageName
        fileName
        caption
        cover
      }
      externalVideoUrl
      bidIncrement
      currentBid
      nextBid
      bannerImageKey
      businessAccountId
      lastBidTime
      version
      bidCount
      bidderAccountId
      bidderId
      bidderCount
      bids {
        nextToken
      }
      actualEPD
      importError
      importErrors
      currentMaxBid
      currentMaxBidAccountId
    }
  }
`;
export const updateAuctionLot = /* GraphQL */ `
  mutation UpdateAuctionLot(
    $auctionId: ID!
    $lotId: ID!
    $expectedVersion: Int!
    $input: UpdateAuctionLotInput!
  ) {
    updateAuctionLot(
      auctionId: $auctionId
      lotId: $lotId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      auctionId
      lotId
      lotNum
      title
      lotOrder
      biddingDisabled
      customFields {
        customLabel
        customValue
      }
      embryoType
      category
      donorName
      donorSire
      donorDam
      breed
      consigner
      specialTerms
      deliveryMethods
      startBid
      floorPrice
      interest
      quantity
      taxable
      tag
      regNum
      regUrl
      lineageHierarchy {
        sire
        sireRegNum
        sireUrl
        dam
        damRegNum
        damUrl
      }
      epdMeasures {
        category
        measure
        value
        accuracy
        progeny
        percentage
        order
      }
      tattoo
      dob
      sexedCategory
      recipientId
      exportable
      footnote
      contactInfo
      pictures
      photos {
        storageName
        fileName
        caption
        cover
      }
      videos {
        storageName
        fileName
        caption
        cover
      }
      externalVideoUrl
      bidIncrement
      currentBid
      nextBid
      bannerImageKey
      businessAccountId
      lastBidTime
      version
      bidCount
      bidderAccountId
      bidderId
      bidderCount
      bids {
        nextToken
      }
      actualEPD
      importError
      importErrors
      currentMaxBid
      currentMaxBidAccountId
    }
  }
`;
export const deleteAuctionLot = /* GraphQL */ `
  mutation DeleteAuctionLot($auctionId: ID!, $lotId: ID!) {
    deleteAuctionLot(auctionId: $auctionId, lotId: $lotId) {
      auctionId
      lotId
      lotNum
      title
      lotOrder
      biddingDisabled
      customFields {
        customLabel
        customValue
      }
      embryoType
      category
      donorName
      donorSire
      donorDam
      breed
      consigner
      specialTerms
      deliveryMethods
      startBid
      floorPrice
      interest
      quantity
      taxable
      tag
      regNum
      regUrl
      lineageHierarchy {
        sire
        sireRegNum
        sireUrl
        dam
        damRegNum
        damUrl
      }
      epdMeasures {
        category
        measure
        value
        accuracy
        progeny
        percentage
        order
      }
      tattoo
      dob
      sexedCategory
      recipientId
      exportable
      footnote
      contactInfo
      pictures
      photos {
        storageName
        fileName
        caption
        cover
      }
      videos {
        storageName
        fileName
        caption
        cover
      }
      externalVideoUrl
      bidIncrement
      currentBid
      nextBid
      bannerImageKey
      businessAccountId
      lastBidTime
      version
      bidCount
      bidderAccountId
      bidderId
      bidderCount
      bids {
        nextToken
      }
      actualEPD
      importError
      importErrors
      currentMaxBid
      currentMaxBidAccountId
    }
  }
`;
export const cloneAuctionLotAndAssets = /* GraphQL */ `
  mutation CloneAuctionLotAndAssets($auctionId: ID!, $lotId: ID!) {
    cloneAuctionLotAndAssets(auctionId: $auctionId, lotId: $lotId) {
      auctionId
      lotId
      lotNum
      title
      lotOrder
      biddingDisabled
      customFields {
        customLabel
        customValue
      }
      embryoType
      category
      donorName
      donorSire
      donorDam
      breed
      consigner
      specialTerms
      deliveryMethods
      startBid
      floorPrice
      interest
      quantity
      taxable
      tag
      regNum
      regUrl
      lineageHierarchy {
        sire
        sireRegNum
        sireUrl
        dam
        damRegNum
        damUrl
      }
      epdMeasures {
        category
        measure
        value
        accuracy
        progeny
        percentage
        order
      }
      tattoo
      dob
      sexedCategory
      recipientId
      exportable
      footnote
      contactInfo
      pictures
      photos {
        storageName
        fileName
        caption
        cover
      }
      videos {
        storageName
        fileName
        caption
        cover
      }
      externalVideoUrl
      bidIncrement
      currentBid
      nextBid
      bannerImageKey
      businessAccountId
      lastBidTime
      version
      bidCount
      bidderAccountId
      bidderId
      bidderCount
      bids {
        nextToken
      }
      actualEPD
      importError
      importErrors
      currentMaxBid
      currentMaxBidAccountId
    }
  }
`;
export const updateLotOrder = /* GraphQL */ `
  mutation UpdateLotOrder($auctionId: ID!, $lots: [LotOrderInput]) {
    updateLotOrder(auctionId: $auctionId, lots: $lots) {
      auctionId
      lotId
      lotOrder
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserAccountInput) {
    createUser(input: $input) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const updateUserContactDetails = /* GraphQL */ `
  mutation UpdateUserContactDetails(
    $accountId: ID!
    $expectedVersion: Int!
    $input: UserContactDetailsInput
  ) {
    updateUserContactDetails(
      accountId: $accountId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($accountId: ID!) {
    deleteUser(accountId: $accountId) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const updateUserEmail = /* GraphQL */ `
  mutation UpdateUserEmail($accountId: ID!, $newemail: AWSEmail!) {
    updateUserEmail(accountId: $accountId, newemail: $newemail) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const deleteUserAccount = /* GraphQL */ `
  mutation DeleteUserAccount($accountId: ID!) {
    deleteUserAccount(accountId: $accountId) {
      status
      message
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness($input: CreateBusinessAccountInput) {
    createBusiness(input: $input) {
      accountId
      businessName
      email
      familyName
      givenName
      nameTitle
      providerName
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      businessWebSite
      businessNotes
      businessPhoneNumbers {
        phone
        preferred
        mobile
      }
      proxyUsers {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      businessTimezone
      version
    }
  }
`;
export const updateBusinessContactDetails = /* GraphQL */ `
  mutation UpdateBusinessContactDetails(
    $accountId: ID!
    $expectedVersion: Int!
    $input: BusinessAccountContactDetailsInput
  ) {
    updateBusinessContactDetails(
      accountId: $accountId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      accountId
      businessName
      email
      familyName
      givenName
      nameTitle
      providerName
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      businessWebSite
      businessNotes
      businessPhoneNumbers {
        phone
        preferred
        mobile
      }
      proxyUsers {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      businessTimezone
      version
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness($accountId: ID!) {
    deleteBusiness(accountId: $accountId) {
      accountId
      businessName
      email
      familyName
      givenName
      nameTitle
      providerName
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      businessWebSite
      businessNotes
      businessPhoneNumbers {
        phone
        preferred
        mobile
      }
      proxyUsers {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      businessTimezone
      version
    }
  }
`;
export const addBusinessUser = /* GraphQL */ `
  mutation AddBusinessUser($accountId: ID!, $businessAccountId: ID!) {
    addBusinessUser(
      accountId: $accountId
      businessAccountId: $businessAccountId
    ) {
      accountId
    }
  }
`;
export const deleteBusinessUser = /* GraphQL */ `
  mutation DeleteBusinessUser($accountId: ID!, $businessAccountId: ID!) {
    deleteBusinessUser(
      accountId: $accountId
      businessAccountId: $businessAccountId
    ) {
      accountId
    }
  }
`;
export const addNewUser = /* GraphQL */ `
  mutation AddNewUser(
    $email: ID!
    $givenName: ID!
    $familyName: ID!
    $phone: String!
    $userRoles: [String]
  ) {
    addNewUser(
      email: $email
      givenName: $givenName
      familyName: $familyName
      phone: $phone
      userRoles: $userRoles
    ) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const updateAuctionReport = /* GraphQL */ `
  mutation UpdateAuctionReport(
    $auctionId: ID!
    $expectedVersion: Int!
    $input: AuctionReportInput!
  ) {
    updateAuctionReport(
      auctionId: $auctionId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      status
      message
      auctionId
      auctionName
      auctionStartDate
      auctionClosingDate
      totalGrossSales
      totalBidderCount
      totalLotCount
      invoiceSentDate
      payments {
        paymentDate
        amount
        fees
      }
      lineItems {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      nextToken
      version
    }
  }
`;
export const updateAuctionReportLineItem = /* GraphQL */ `
  mutation UpdateAuctionReportLineItem(
    $auctionId: ID!
    $lotId: ID!
    $input: AuctionReportLineItemInput!
  ) {
    updateAuctionReportLineItem(
      auctionId: $auctionId
      lotId: $lotId
      input: $input
    ) {
      auctionId
      auctionName
      auctionStartDate
      lotId
      lotNum
      title
      businessAccountId
      winningBidderFirstName
      winningBidderLastName
      winningBidderAddress
      winningBidderPhoneNumber
      winningBidderEmail
      finalBid
      quantity
      interest
      finalPrice
      saleStatus
      paymentStatus
      invoiceId
      invoice {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      sellerName
      sellerPhoneNumber
      sellerEmail
      additionalLineItems {
        amount
        note
      }
      version
    }
  }
`;
export const sendInvoices = /* GraphQL */ `
  mutation SendInvoices($auctionId: ID!) {
    sendInvoices(auctionId: $auctionId) {
      auctionId
      invoiceSentDate
      invoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
    }
  }
`;
export const sendInvoice = /* GraphQL */ `
  mutation SendInvoice(
    $auctionId: ID!
    $sellerEmail: AWSEmail!
    $buyerEmail: AWSEmail!
  ) {
    sendInvoice(
      auctionId: $auctionId
      sellerEmail: $sellerEmail
      buyerEmail: $buyerEmail
    ) {
      auctionId
      invoice {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
    }
  }
`;
export const publishAuctionChanged = /* GraphQL */ `
  mutation PublishAuctionChanged($auctionId: ID!, $data: AuctionChangeInput!) {
    publishAuctionChanged(auctionId: $auctionId, data: $data) {
      auctionId
      eventType
      auctionData {
        closingExtensionInterval
        closingExtensionTriggered
        status
        startTime
        endTime
        bidIncrement
        floorPrice
      }
      lotData {
        auctionId
        lotId
        startBid
        currentBid
        nextBid
        bidCount
        bidIncrement
        bidderCount
        bidderId
        bidderAccountId
        floorPrice
        biddingDisabled
      }
    }
  }
`;
export const submitBid = /* GraphQL */ `
  mutation SubmitBid(
    $auctionId: ID!
    $lotId: ID!
    $bidPrice: Float!
    $maxBidPrice: Float
    $targetAccountId: ID
  ) {
    submitBid(
      auctionId: $auctionId
      lotId: $lotId
      bidPrice: $bidPrice
      maxBidPrice: $maxBidPrice
      targetAccountId: $targetAccountId
    ) {
      status
      code
      message
      data {
        auctionId
        lotId
        currentBid
        nextBid
        bidCount
        bidIncrement
        bidderCount
        bidderId
        isNewBidderId
      }
    }
  }
`;
export const submitMaxBid = /* GraphQL */ `
  mutation SubmitMaxBid(
    $auctionId: ID!
    $lotId: ID!
    $maxBidPrice: Float
    $targetAccountId: ID
  ) {
    submitMaxBid(
      auctionId: $auctionId
      lotId: $lotId
      maxBidPrice: $maxBidPrice
      targetAccountId: $targetAccountId
    ) {
      status
      code
      message
      data {
        auctionId
        lotId
        currentBid
        nextBid
        bidCount
        bidIncrement
        bidderCount
        bidderId
        isNewBidderId
      }
    }
  }
`;
export const deleteBid = /* GraphQL */ `
  mutation DeleteBid($auctionId: ID!, $lotId: ID!, $bidTime: AWSDateTime!) {
    deleteBid(auctionId: $auctionId, lotId: $lotId, bidTime: $bidTime) {
      status
      message
    }
  }
`;
export const manageFile = /* GraphQL */ `
  mutation ManageFile($itemId: ID!, $itemKey: String!, $public: Boolean!) {
    manageFile(itemId: $itemId, itemKey: $itemKey, public: $public) {
      fileKey
      downloadUrl
      uploadUrl
    }
  }
`;
export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($invoiceId: ID!, $paymentType: PaymentType!) {
    createPaymentIntent(invoiceId: $invoiceId, paymentType: $paymentType) {
      code
    }
  }
`;
export const initiateTask = /* GraphQL */ `
  mutation InitiateTask($taskKey: String!, $payload: AWSJSON) {
    initiateTask(taskKey: $taskKey, payload: $payload) {
      id
      status
      payload
    }
  }
`;
export const publishResult = /* GraphQL */ `
  mutation PublishResult($id: ID!, $status: ResultStatus, $output: AWSJSON) {
    publishResult(id: $id, status: $status, output: $output) {
      id
      status
      payload
    }
  }
`;
