import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-auctions-home',
  templateUrl: './auctions-home.component.html',
  styleUrls: ['./auctions-home.component.scss'],
})
export class AuctionsHomeComponent implements OnInit {

  @Output() public messageEvent = new EventEmitter<any>();
  public theme = environment.theme_config;

  constructor(private authService: AuthService) { }

  public async ngOnInit(): Promise<void> {
    await this.authService.getAuthenticatedUser();
  }

}
