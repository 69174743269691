import { Component, Input, OnInit, Output } from '@angular/core';

export type BenefitCard = {
  mainTitle: string;
  items: BenefitItem[];
}

export type BenefitItem = {
  subtitleImageSrc: string;
  subtitle: string;
  description: string;
  actionLinkTitle?: string;
  actionLinkSrc?: string;
  actionLinkIcon?: string;
  actionLinkSignUp?: boolean;
}

import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../auth/authhome/authhome.component';

@Component({
  selector: 'app-benefits-card',
  templateUrl: './benefits-card.component.html',
  styleUrls: ['./benefits-card.component.scss']
})
export class BenefitsCardComponent implements OnInit {

  constructor(private dialogService: NbDialogService) { }

  @Input() cardDetail: BenefitCard;

  ngOnInit(): void {
  }

  public onSignUp() {
    this.openAuth("signup");
  }

  private openAuth(pageName) {
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName: pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  processMsg(event) {
    // if (!event) return;

    // var msg = event['message'];
    // if (["signinsuccessful", "signupcomplete"].includes(msg)) {
    //   this.router.navigate(["/"]);
    // }
  }

}
