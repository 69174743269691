<div class="container-fluid">
  <div class="auction-background">
    <img [srcset]="theme.auction_listing_banner_url_srcset.join(',')"
      sizes="
      (max-width: 576px) 480px,
        100%"
      [src]="theme.auction_listing_banner_url"
      alt="Auction header background"
      class="img-background">

    <div class="title">Your online source for<br/>QUALITY GENETICS.</div>
  </div>

  <app-auctions-list></app-auctions-list>
</div> <!-- END container-fluid -->