<div class="container-fluid container-horiz-padded container-vert-padded no-gutters">

  <div class="row">
    <div class="col-1 col-md-3 col-lg-2 buyer-link-container d-none d-md-block">
      <div class="fixed">
        <div><a routerLink="/buyers" fragment="buying-info" class="buyer-link" *ngIf="theme.buyer.show_buying_on">Buying on {{theme.app_title}}</a></div>
        <div><a routerLink="/buyers" fragment="why-buy" class="buyer-link buyer-link-focused" *ngIf="theme.buyer.show_why_buy">Why Buy with {{theme.app_title}}</a></div>
        <div><a routerLink="/buyers" fragment="how-it-works" class="buyer-link">How it Works</a></div>
        <div><a routerLink="/buyers" fragment="auction-format" class="buyer-link buyer-link-focused">Auction Format</a></div>
        <div class="mt-3"><a routerLink="/buyers" fragment="when-you-win" class="buyer-link">When You Win</a></div>
        <div><a routerLink="/buyers" fragment="delivery-payment" class="buyer-link buyer-link-focused">Delivery & Payment</a></div>
        <div><a routerLink="/buyers" fragment="terms-and-conditions" class="buyer-link buyer-link-focused">Terms & Conditions</a></div>
        <div class="mt-3"><a routerLink="/buyers" fragment="service-providers" class="buyer-link">Service Providers</a></div>
        <div><a routerLink="/buyers" fragment="livestock-insurance" class="buyer-link buyer-link-focused">Livestock Insurance</a></div>
        <div><a routerLink="/buyers" fragment="trucking" class="buyer-link buyer-link-focused">Trucking</a></div>
      </div>
    </div>
    <div class="col-11 col-md-9 col-lg-10">
      <ng-container *ngIf="theme.buyer.show_buying_on">
        <app-section-header class="anchor" id="buying-info" type="primary">Buying on {{theme.app_title}}</app-section-header>
        <img src="assets/icons/buying-on.png" />

        <p class="mt-2">Today, the leading edge of livestock sales is online, where auction websites make bidding on animals and top-quality genetics easier and more approachable than ever.</p>
        <p>In 2012, our founder and owner Jeremy Haag recognized that no online platform existed for {{theme.app_breed}} breeders to market their breed-specific knowledge and genetics. So he created {{theme.app_title}}, the first auction website to specialize solely in the {{theme.app_breed}} breed.</p>
        <p>The {{theme.app_title}} team are life-long students of {{theme.app_breed}} breeding, showing and sales. We’ve diligently built relationships with {{theme.app_breed}} breeders across the country, so that auctions on {{theme.app_title}} feature a carefully curated selection of the best animals. And we understand that the future of livestock auctioneering is online, so we’ve built our website and mobile app to make online bidding fast, simple and secure.</p>
      </ng-container>

      <ng-container *ngIf="theme.buyer.show_why_buy">
        <app-section-header class="anchor" id="why-buy" type="primary">Why Buy on {{theme.app_title}}?</app-section-header>

        <p>Other livestock auction sites exist, but {{theme.app_title}} is the premier platform for buying and selling {{theme.app_breed}}. Here’s why:</p>

        <ul>
          <li><strong>Unmatched Expertise In {{theme.app_breed}}.</strong> Owner Jeremy Haag is a third-generation {{theme.app_breed}} breeder, was sales manager at the American {{theme.app_breed}} Hall Of Fame for 15 years, and has co-authored the definitive guide to {{theme.app_breed}} breeding history. There simply isn’t another auction website with more {{theme.app_breed}} prowess or passion.</li>
          <li><strong>Breed-Specific Focus.</strong> {{theme.app_title}} is the first breed-specific auction website, and our team has conducted {{theme.app_breed}} auctions in 38 states, so all of our animals are listed at carefully appraised price-points.</li>
          <li><strong>Easy To Use Bidding And Selling Features.</strong> Don’t be intimidated by attending your first online auction — the {{theme.app_title}} Native Mobile App and bidding portal make buying or selling accessible and secure.</li>
          <li><strong>Proven Track Record.</strong> Since our founding in 2012, we’ve prided ourselves on our customer successes, so you can be assured that your {{theme.app_title}} auction experience will be a profitable one.</li>
        </ul>
      </ng-container>

      <app-section-header class="anchor" id="how-it-works" class="mt-5" type="primary">How it Works</app-section-header>

      <div class="row">
        <div class="col-md-4 col-lg-3 features-container">
          <img src="assets/icons/live-app.png" />
          <h6 class="mt-2">{{theme.app_title}} App</h6>
          <p>Coming Soon!</p>
          <ng-template *ngIf="false">
            <div class="mt-2"><img src="assets/icons/download-on-the-app-store.svg"></div>
            <div class="mt-2"><img src="assets/icons/google-play-badge.png" class="google-play-icon"></div>
          </ng-template>
        </div>
        <div class="col-md-4 col-lg-3 features-container">
          <img src="assets/icons/bidding-portal.png" />
          <h6 class="mt-2">Bidding Portal</h6>
          <p>Create a secure account and profile on our bidder portal and enjoy a quick-access hub to your auction wins and invoices.</p>
        </div>
      </div>
      <div class="row mt-md-5">
        <div class="col-md-4 col-lg-3 features-container">
          <img src="assets/icons/signing-in-to-bid.png" />
          <h6 class="mt-2">Signing in to Bid</h6>
          <p>At the press of a button, and be ready to bid at a moment's notice. You’ll need to be signed in to the web or mobile application to place bids.</p>
        </div>
        <div class="col-md-4 col-lg-3 features-container">
          <img src="assets/icons/bidder-numbers.png" />
          <h6 class="mt-2">Bidder Numbers</h6>
          <p>You don’t need to remember a Bidder Number anymore–you’ll be assigned one for each lot you bid on. This means you can bid anonymously but still have access to your history on our system.</p>
        </div>
      </div>

      <app-section-header class="anchor" id="auction-format" type="primary">Auction Format</app-section-header>

      <p>We use the horse race style closing format.</p>

      <p>When the auction time expires, all lots will close simultaneously. Extended bidding will continue after the auction closes until there are no bids on any lot for a time interval of between 5 and 1 minutes.</p>

      <p>A few benefits to this style of auction are: </p>

      <ol>
        <li>the buyer has the option to switch to any lot(s) in the offering if he/she is outbid on his/her current choice; </li>
        <li>the buyer does not have to deal with the lot order of the sale because all lots are offered and bid on simultaneously; and </li>
        <li>the buyer has more choice and more time to make their decision.</li>
      </ol>

      <p><strong>Extended Bidding</strong> means that the auction is not declared closed until there has been a set period of inactivity on all lots. If there is any bidding during the inactivity period, the auction is extended. Time remaining is displayed at the auction and lot level. Even when the bidding is extened, there will be no bids accepted after 11:59 in the time zone of the auction.</p>

      <p>Examples:</p>
      <ol>
        <li>Auction Closes at 7pm. There is no bidding from 6:55 pm to 7pm. All lots are declared closed and the sale ends at 7pm.</li>
        <li>Auction closes at 7pm. There is a bid placed at 6:58pm. The Auction is extended 5 additional minutes. No more bidding occurs. All lots are declared closed and the sale ends at 7:03pm.</li>
        <li>Auction Closes at 7pm. There is a bid placed at 6:58pm. The Auction is extended by 5 minutes until 7:03 pm. Another bid is placed at 7:02 pm. The Auction is extended by 5 minutes until 7:07 pm. No more bids are placed. All lots are declared closed and the sale ends at 7:07 pm        </li>
      </ol>

      <app-section-header class="anchor" id="when-you-win" type="primary">When You Win</app-section-header>
      <img src="assets/icons/when-you-win.png" />

      <p class="mt-2">When the sale ends, the highest bidder wins the lot and pays for the item. Remember, a bid is a binding contract. When you bid on an item in an auction, you're committing to buy it if you win.</p>

      <app-section-header class="anchor" id="delivery-payment" type="primary">Delivery & Payment</app-section-header>

      <p>Payments must be received prior to pickup or delivery and 30 days from an auction’s close. A late processing fee of $50 will automatically be added to your invoice for all payments not received within the 30 day payment window. Invoices are available to view and pay in your Bidder’s Portal (you must be signed in to access it). Failure to pay may mean being denied access to all future auctions on {{theme.app_title}}. There are no buyer’s premiums in effect for any auctions.</p>
      <p>All bids and payments are in U.S. dollars. Accepted forms of payment are farm, corporate, personal or cashier’s check, money order, ACH bank transfer, or credit card.</p>
      <p>All deliveries must be arranged between buyer and seller. All delivery costs are the responsibility of the buyer.</p>

      <ng-container *ngIf="theme.buyer.show_payment_options">
        <p><strong>Credit card purchases </strong>will be accepted immediately through {{theme.app_title}}’s online payment servicing partner directly through the online invoice. A fee of 5% applies to all credit card purchases.</p>

        <p><strong>Checks and money orders </strong>should be made payable to:<br>
            <span class="ml-2">G2 Live LLC</span><br>
            <span class="ml-2">41338 Ridgeway Road</span><br>
            <span class="ml-2">Charlo, MT 59824</span><br>
        </p>
      </ng-container>
      <p *ngIf="theme.buyer.payment_footnote"><strong>{{ theme.buyer.payment_footnote }}</strong></p>

      <p>All checks must include the applicable invoice number in the memo line and be mailed with a copy of the invoice. Checks from outside the U.S. will not be accepted.</p>

      <app-section-header class="anchor" id="terms-and-conditions" type="primary">Terms & Conditions</app-section-header>

      <p>
        “Seller” shall mean the party who is placing the lot(s) or merchandise for sale.<br>
        “Bidder” shall mean the party submitting an offer to purchase the lot(s) or merchandise for sale.<br>
         “Buyer” shall mean “Bidder” who has submitted the successful offer to purchase the lot(s) or merchandise for sale.<br>
        “Sale Manager/Management” shall mean G2 Live LLC.<br>
      </p>

      <p>
        All {{theme.app_breed}} cattle represented on G2 Live LLC will sell under the standard terms and conditions of the American {{theme.app_breed}} Association, including dispersal terms when stated in a dispersal setting. G2 Live LLC makes no warranty, express or implied, as to the information used to describe lots or any other merchandise sold on this site. All lots and merchandise are presented as described by the Seller. LOTS ARE SOLD AS IS, WHERE IS AND WITH ALL FAULTS. Bidder acknowledges that all lots were available for inspection prior to the auction and, by these terms, it is the Bidder’s responsibility to personally inspect all lots that he/she or it may purchase. Failure on part of the Bidder to inspect lots, or otherwise be fully informed as to the nature, quality and condition of lots purchased, will not constitute grounds for any type of refund or termination of the sale agreement between the Buyer and Seller. All lots and merchandise sold become the property of Buyer at the conclusion of the auction. Sale Management is not responsible for the condition or health of lots sold and purchased, either at the time of sale or time of delivery.
      </p>

      <p>
        THE IMPLIED WARRANTIES OF MERCHANTABILITY, SUITABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ALL OTHER WARRANTIES, EITHER EXPRESS OR IMPLIED, ARE SPECIFICALLY EXCLUDED from this contract for sale and this transaction and shall NOT apply to the merchandise that is the subject of this sale and this transaction. Buyers hereby agree to indemnify and hold G2 Live LLC, its members, parent subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including reasonable attorney’s fees, against any and all causes of action which relate to the condition of the lots, merchandise or the descriptions thereof sold on the G2 Live LLC website online auction platform.
      </p>

      <h6>Bidding</h6>
      <p>
        All bidding and payments are in U.S. currency unless otherwise stated in Terms and Conditions or on the home page of that particular sale. Bidding is done in increments, which may vary by both species and individual lot(s) for each sale. by lot, or during a sale. A Bidder may be outbid at any time before the close of an auction. Bidders will NOT automatically receive notification if they have been outbid; thus, careful monitoring of the auction is encouraged when bidding. Unless otherwise indicated, lots and merchandise offered on the G2 Live LLC site platform will sell subject to a reserve price established by the Seller. Both the Seller and sale management may bid on the Seller’s behalf in an amount not to exceed the reserve price. Seller is under no obligation to sell lots or merchandise if a bid does not meet the minimum acceptable price set by Seller.
      </p>

      <p>
        Bidder is responsible for any bids placed under his/her or its bidding number, account his/her/their username and password. The security of a bidding number username and password is the sole responsibility of the Bidder. If at anytime a Bidder’s number and/or account password is compromised for any reason, the Bidder shall notify G2 Live LLC immediately to have the bidder’s number and/or account password changed.
      </p>

      <p>
        Placing a bid represents a valid offer between the Bidder and Seller. Once a bid is submitted and confirmed by the Bidder, it cannot be retracted. If a Bidder places the winning bid, the offer then becomes a binding contract to purchase the lot(s) or merchandise, and the Bidder is obligated to buy the lot(s) or merchandise at the final bidding price. Fraudulent bids can and will be pursued in a court of law. The Seller and Sale Manager reserve the right to accept or reject any and all bids unless otherwise stated.
      </p>

      <p>
        In the case of a software malfunction on the part of the Sale Management, the sale will be stopped and all Bidders notified. Handling of the situation thereafter will be at the sole discretion of the Sale Manager. In the event the auction is cancelled, G2 Live LLC shall not charge any party for any fees and all bids shall be considered cancelled.
      </p>

      <h6>Payment</h6>
      <p>
        When bidding ends, all sales shall be considered final and the Bidder who submitted the highest bid shall then become the Buyer of the lot(s). All lots purchased by a Buyer through G2 Live LLC must be paid within 7 days 45 days from the auction close. Accepted forms of payment include check (personal or farm), certified check, money order, or credit card (Visa, Master Card). G2 Live LLC does not accept personal or farm checks from outside the U.S.
      </p>

      <h6>Checks</h6>

      <p>
        Checks should be made payable to G2 Live LLC. Checks can be mailed to 41338 Ridgeway Road, Charlo, MT 59824 and a copy of the invoice should be included in the envelope.
      </p>

      <p>
        A 5.0% processing and handling fee will be applied on all credit card transactions. A $50 fee will be assessed on all returned checks. Failure to pay within the allotted timeframe will result in a 15% finance charge, and the registered Bidder may be banned from all future G2 Live LLC sales. Late payments will result in a late payment processing fee automatically added to the invoice. Failure to pay may result in a Bidder being banned from all future G2 Live LLC sale. G2 Live LLC reserves the right to process a Buyer’s credit card on file for any lot(s) or merchandise purchased if payment has not been made within 30 days 45 days of the close of a sale. If a Bidder cannot pay for his/her/their purchases immediately following the sale, he/she/they should not bid on the lot(s).
      </p>

      <h6>Invoicing</h6>
      <p>
        An invoice with payment instructions for lot(s) purchased will be e-mailed to the Buyer immediately within 24 hours. This correspondence will be sent to the Buyer’s e-mail address on file. The invoices will be sent out the following evening of the closed sale. If the Buyer does not receive an invoice the following evening the Buyer shall contact G2 Live LLC immediately to request an invoice. Failure to complete payment according to the terms above shall result in all applicable fees, finance charges, and a potential ban from all future G2 Live LLC sales.
      </p>

      <h6>Sale Close</h6>
      <p>
        All auctions hosted by G2 Live LLC are conducted with extended bidding in a horserace-style closing format, unless otherwise stated. If there is any bidding activity in the last 5 minutes of an auction, that auction will enter into extended bidding. Extended bidding time increments will start at 5 minutes, however, the Seller may shorten that time duration at any point during extended bidding. are set by the Sale Manager and Seller, and may change during a live auction. Extended bidding will continue until there has been no bidding activity for the duration of the set extended bidding time increment. At that time, the sale will close and all lots will be declared sold to the highest bidder. Both the Sale Manager and Seller reserve the right to extend or end bidding at any time.
      </p>

      <h6>Withdrawal of Lots</h6>
      <p>
        Occasionally, lots may need to be removed from an online sale that has already been posted. The Seller reserves the right to withdraw lots at any time before or during the auction. In the event the Seller withdraws a lot during an auction, all bids on that lot shall be considered withdrawn.
      </p>

      <h6>Registration Papers & Delivery</h6>
      <p>
        All lots purchased through G2 Live LLC will become the property of the Buyer at the conclusion of the auction and, as such, all risk of damage, death or injury to lots purchased becomes the Buyer’s risk. Sale Management urges all new buyers to take out insurance on new purchases concluding the close of the sale.
      </p>

      <p>
        Delivery of all lots purchased through G2 Live LLC must be coordinated between the Buyer and Seller. Any fees incurred with delivery are the sole responsibility of the Buyer, unless other arrangements are agreed upon subsequent to the auction between the Buyer and Seller. Any questions regarding delivery should be directed to the Seller. If Seller delivers any lot(s) sold to the Buyer before payment has been received, collecting payment for said lot(s) becomes the sole responsibility of the Seller.
      </p>

      <h6>Registration & Health Papers</h6>
      <p>
        Registration papers shall be delivered from the Seller to the Buyer within 45 days of cleared payment. The Seller is responsible for the transfer of registration papers. Sale Management assumes no responsibility for the completion of this transfer.
      </p>
      <p>
        Health papers may be furnished by the Seller, at the request of the Buyer. Any fees related to the furnishing of health papers are the sole responsibility of the Buyer.
      </p>

      <h6>Liability</h6>
      <p>
        Sale Management shall not be liable for lost profit or special, incidental or consequential damages out of or in connection with any sale transaction. Bidders, Buyers and Sellers hereby agree to indemnify and hold G2 Live LLC, its members, parent subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third party due to or arising out of Bidder’s, Buyer’s or Seller’s use of the this service, the violation of these terms of service, for any information transmitted by any of the aforementioned on the G2 Live LLC sales site.
      </p>

      <p>
        Sale Management has the sole and final discretion to determine winning bidders and, in the event of a dispute between parties, the decision of the Sale Manager is final. Sale Manager reserves the right to reopen bidding to settle matters related to disputed bids or ties. Sale Manager reserves the right to postpone or cancel a sale for any reason.
      </p>

      <p>
        Sale Manager does not guarantee continuous, uninterrupted or secure access to online sales services, and operation of the online sale site may be interfered with by numerous factors outside of Sale Management’s control. Furthermore, due to the nature of online services, Sale Manager cannot guarantee or confirm the authentic identity of Bidders, Buyer or Sellers and shall have no liability for any false or misleading information transmitted by the aforementioned on the G2 Live LLC auction site.
      </p>

      <p>
        Sale Manager is not responsible for any accidents or injury related to the sale, purchase or delivery of any lot(s) sold.
      </p>

      <p>
        Sale Manager may refuse services to anyone at anytime at its sole discretion. If a Bidder provides false information, such as a false name or fraudulent contact information, he/she or it will be suspended or permanently banned from G2 Live LLC sales.
      </p>

      <app-section-header class="anchor" id="service-providers" type="primary">Service Providers</app-section-header>
      <img src="assets/icons/service-providers.png" />

      <p class="mt-2">After the excitement of an auction win, it’s time to consider the logistics of delivery.</p>
      <p>Your livestock is a significant investment, so protecting it is important. Livestock insurance helps safeguard your purchase against all kinds of unforeseen risks, like weather events, accidents and crime, and can cover your animal if it becomes infertile. {{theme.app_title}}’s livestock insurance partners are highly regarded and locally owned.</p>
      <p>Livestock trucking companies specialize in safely transporting animals, and will ensure your purchase gets from the seller to you. Our dependable service providers will make the livestock transportation process quick and easy.</p>

      <app-section-header class="anchor" id="livestock-insurance" type="primary">Livestock Insurance</app-section-header>

      <p>If you would like your business added to this list, please contact us at:<br>
         <a class="link-text" [href]="'mailto:' + theme.marketing_email_address + '?subject=Livestock Insurance'" target="_blank">{{theme.marketing_email_address}}</a><br>
         {{theme.marketing_phone_number}}
      </p>

      <div class="row mt-3">
        <div class="col d-flex flex-row flex-wrap photo-group-container">
          <div *ngFor="let data of livestockInsuranceAdCards" class="photo-card-container">
            <app-ad-card [data]="data"></app-ad-card>
          </div>
        </div>
      </div>

      <app-section-header class="anchor" id="trucking" type="primary">Trucking</app-section-header>

      <p>If you would like your business added to this list, please contact us at:<br>
        <a class="link-text" [href]="'mailto:' + theme.marketing_email_address + '?subject=Trucking'" target="_blank">{{theme.marketing_email_address}}</a><br>
        {{theme.marketing_phone_number}}
      </p>

      <div class="row mt-3 mb-3">
        <div class="col d-flex flex-row flex-wrap photo-group-container">
          <div *ngFor="let data of truckingAdCards" class="photo-card-container">
            <app-ad-card [data]="data"></app-ad-card>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>