import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Timezones } from '../../services/timezones';


import { AuctionModel } from '../../model/auction.model';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auction-card',
  templateUrl: './auction-card.component.html',
  styleUrls: ['./auction-card.component.scss']
})
export class AuctionCardComponent implements OnInit {
  @Input() auction: AuctionModel;
  public timezones;
  public timezoneRecord;

  public theme = environment.theme_config;

  @ViewChild('apptimecounter') apptimecounter;

  _AUCTIONLIVECARD_STYLE = { "padding": "0rem 1rem", "overflow": "visible" };
  _AUCTIONNOTLIVECARD_STYLE = {};

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.timezones = Timezones.timezones;
    this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == this.auction.timezone});
    if(this.timezoneRecord == undefined){
      this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == "US/Eastern"});
    }
  }

  getFormattedEndDate(){
    const date = Timezones.getDateWithTimezoneApplied_moment(this.auction.endTime, this.timezoneRecord.timezone);
    return Timezones.readableDateMoment(date);
  }

  getFormattedEndTime(){
    const date = Timezones.getDateWithTimezoneApplied_moment(this.auction.endTime, this.timezoneRecord.timezone);
    return Timezones.readableTimeMoment(date) + " " + this.timezoneRecord['short'];
  }

  onViewDetails(){
    this.router.navigate(['/auctions', this.auction.auctionId]);
  }

  getCardStyle(){
    return this.auction.isLive() || this.auction.isClosed() ? this._AUCTIONLIVECARD_STYLE : this._AUCTIONNOTLIVECARD_STYLE;
  }

  getBannerText() {
    if (this.auction.isLive()) {
      return "SALE OPEN";
    } else if (this.auction.isClosed()) {
      return "CLOSED";
    } else {
      return "";
    }
  }

  auctionActive(): boolean {
    return this.auction.isSaleInfoPosted() || this.auction.lotsPosted() || this.auction.isLive() || this.auction.isClosed();
  }


}

