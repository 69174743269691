<div class="container-fluid container-horiz-padded container-vert-padded no-gutters">

  <div class="row">
    <div class="col-1 col-md-3 col-lg-2 community-link-container d-none d-md-block">
      <div class="fixed">
        <div><a routerLink="/community" fragment="about-us" class="community-link">About Us</a></div>
        <div><a routerLink="/community" fragment="angus-live-story" class="community-link community-link-focused">The {{theme.app_title}} Story</a></div>
        <div><a routerLink="/community" fragment="the-team" class="community-link community-link-focused">The Team</a></div>
      </div>
    </div>

    <div class="col-11 col-md-9 col-lg-10">
      <app-section-header class="anchor" id="about-us" type="primary">About Us</app-section-header>
      <img src="../../../assets/icons/noun_Story_3203474_about.svg" />

      <p class="mt-2">
        Founded in 2021, {{theme.app_title}} was the first breed-specific online marketing firm created in the cattle industry. Since then, our platform has captured the largest portion of the Angus breed online auction market—at more than 85%. And we’re not stopping there—we’re building the best venue to market Angus Genetics anywhere in the world.
      </p>

      <p>
        But we’re not just focused on the established breeder—we are positioned to help emerging breeders market across all sectors of our industry. {{theme.app_title}} is a conduit for the new or small breeder to get the necessary exposure they need to survive. The younger breeder will
        heavily influence the future with more demand for online auctions and a native digital experiences. We have the technology and expertise to support you now and in the future.
      </p>

      <app-section-header class="anchor" id="angus-live-story" type="primary">The {{theme.app_title}} Story</app-section-header>
      <img src="../../../assets/icons/noun_Story_3203474_story.svg" />

      <p class="mt-2">
        {{theme.app_title}} began as a way for Angus breeders to have a breed-specific online marketing option for their programs. Other online sites focus on selling “everything” but we wanted to provide a service where our expertise and passion would stay breed specific and my roots have always been Angus.
      </p>

      <p>
        Jeremy Haad was raised on a small Angus farm in Northern Indiana. He is a third-generation Angus breeder. His paternal grandfather bought his first Angus female and bull over 50 years ago. Hosting their own production sale was always a dream of the Haag family, but they were a smaller operation—only calving 40-50 cows a year, and they never had enough inventory to warrant it. The idea came to Jerem;y that having an online avenue would be a great way for breeders of any size to have their own event and market their genetics to a broad spectrum of breeders from all over the country. And so, Jeremy created {{theme.app_title}}. Whether you have one head or five hundred head to sell, the options are unlimited on {{theme.app_title}}.
      </p>

      <p>
        A key component in initially building, and continuing to run {{theme.app_title}}, has been Jeremy’s lifelong friend Brian Snell. Brian and Jeremy went to school together since their elementary days and, with Brian’s skills in computer graphics, he and Jeremy collaborated to make this idea work. Jenny Porter-Haag—Jeremy’s wife—came on to onboard to assist in the accounting and sale preparation aspects of our business.
      </p>

      <p>
        This plan to market Angus cattle online came together with Jeremy’s Angus heritage combined with the knowledge of marketing and the skill set he learned from his 15 years of being a sale manager at the American Angus Hall of Fame, and continuing with the addition of an amazing staff and then in February of 2012 we hosted our very first online sale.
      </p>

      <p>
        Over the years {{theme.app_title}} has realized great success including a bull that went to a major AI stud from our very first sale and continued with selling the Grand Champion Female and Res. Grand Champion Female of the world’s toughest show, the National Junior Angus Show. We also sold the Grand Champion Female at the N.A.I.L.E, American Royal, National Western Stock Show, and numerous state and local shows as well and the show winnings continue today.
      </p>

      <p>
        We owe all the credit of our success with {{theme.app_title}} to the Angus breeders and buyers over the years to be their Go-To source to promote Angus genetics.
      </p>

      <app-section-header class="anchor" id="the-team" type="primary">The Team</app-section-header>

      <div class="row mt-3">
        <div class="col d-flex flex-row flex-wrap">
          <div class="card-container">
              <img src="../../../assets/icons/photoJeremy.png" />
          </div>
          <div class="card-container">
              <p>
                  <span class="title-text">Jeremy Haag</span><br>
                  {{theme.app_title}} Founder, Owner & Sale Manager<br>
                  <a class="link-text" href="mailto:jeremy@anguslive.com?subject={{theme.app_title}}" target="_blank">jeremy@anguslive.com</a>
              </p>
          </div>
        </div>
      </div>

      <p class="mt-3">
        Jeremy, a third-generation Angus breeder from Northern Indiana, grew up on his family farm near Plymouth, Indiana where he and his family have been breeding registered Angus cattle for over 50 years since 1965. His family bred the Indiana State Fair Grand Champion, Pilgrim Rachel 1297, who has become a cornerstone female of much of their present day Pilgrim Angus herd. Other famous individuals owned in partnership by Jeremy include a breed-leading sire for registrations.
      </p>

      <p>
        Jeremy grew up showing cattle in the 4-H program and competed on his local 4-H livestock judging team where they became state champions in 1997, and he was the second high individual at the state competition. Jeremy continued his livestock judging to the collegiate level and attended Joliet Jr. College where he competed at all 12 national competitions and highlights included: 4th High Individual at NAILE and 10th High Individual at the American Royal, 2nd High Individual in Beef at the National Western Stock Show. Following his junior college career, he attended Purdue University and was a member of the livestock judging team and a high individual in Beef at the All East Livestock Contest graduating with a degree in Animal Agribusiness. Jeremy was extremely active as a member of the National Jr. Angus Association, and he served two terms as president of the Indiana Jr. Angus Association representing Indiana in the National Jr. Angus Showmanship Competition in Des Moines, Iowa. As an adult, he was selected to judge the 2009 National Jr. Angus Showmanship Contest. Jeremy also graduated from Missouri Auction School.
      </p>

      <p>
        Jeremy has co-authored several books to date including Atlantic National 20th Anniversary, 40th Anniversary of the National Junior Angus Show, 50th Anniversary of Central Illinois Aberdeen-Angus Association Preview Show and Angus Legends Special Edition Set – Volume I & II, the single most comprehensive pair of Angus books ever written.
      </p>

      <p>
        Jeremy joined the American Angus Hall of Fame sale management firm and was with the firm for 15 years where he established his skills in marketing a variety of Angus genetics to breeders all over the world. He was in charge of a large portion of advertising and sale catalog preparation along with conducting sales from coast to coast. He has conducted auctions in 38 states and managed over 1,000 sales to date. He maintains a close working relationship with a wide range of Angus breeders assisting them in marketing and seedstock selection.
      </p>

      <p>
        Jeremy and his wife, Jenny Porter-Haag, have three children, Drew, Aleigh, and Matthew.
      </p>

      <div class="row mt-3">
        <div class="col d-flex flex-row flex-wrap">
          <div class="card-container">
              <img src="../../../assets/icons/photoBrian.png" />
          </div>
          <div class="card-container">
              <p>
                  <span class="title-text">Brian Snell</span><br>
                  {{theme.app_title}} General Manager<br>
                  <a class="link-text" href="mailto:brian@anguslive.com?subject={{theme.app_title}}" target="_blank">brian@anguslive.com</a>
              </p>
          </div>
        </div>
      </div>

      <p class="mt-3">
        Brian was born and raised in northern Indiana where he has lived his entire life. His love for sports led him to play football, basketball and baseball throughout high school. In addition to sports, Brian also had a passion for art. In 1998, that passion followed him to West Lafayette where he attended Purdue University. Brian graduated Purdue in 2002 with a Bachelor's Degree in Computer Graphics.
      </p>

      <p>
        In 2003, he began his career at Valley Screen in Mishawaka, IN as a Graphic Designer. Currently Brian continues to work at Valley Screen where he leads the art department as Art Director.
      </p>

      <p>
        Brian lives in Bremen, Indiana with his wife and two children.
      </p>

      <div class="row mt-3">
        <div class="col d-flex flex-row flex-wrap">
          <div class="card-container">
              <img src="../../../assets/icons/photoJenny.png" />
          </div>
          <div class="card-container">
              <p>
                  <span class="title-text">Jenny Porter-Haag</span><br>
                  {{theme.app_title}} Online Administrative Assistant and Account Manager<br>
                  <a class="link-text" href="mailto:jenny@anguslive.com?subject={{theme.app_title}}" target="_blank">jenny@anguslive.com</a>
              </p>
          </div>
        </div>
      </div>

      <p class="mt-3">
        Jenny grew up in a small town in northeast Florida surrounded by as many animals as she could press past her parents, although cattle were not a part of her life other than her weekend and summer stays with grandparents in southern Georgia until she began working at the American Angus Hall of Fame in the fall of 2006.
      </p>

      <p>
        From the very first day of working with Angus breeders, Jenny has been amazed by the passion and commitment exuded from this dedicated group of individuals. With her strong work ethic, business degree and customer service driven personality, Jenny was a natural to help with the collection of information for our {{theme.app_title}} sales and encouraged Jeremy to pursue his dreams of starting up an online source of providing superior Angus genetics.
      </p>

      <p class="mb-3">
        In their spare time, Jenny and Jeremy enjoy spending time with their three children, Drew, Aleigh and Matthew, along with their feisty dog, Willow, and enjoy time with family and friends across the states. You might also find Jenny trying out the latest coffee shop or with her nose in a book.
      </p>

    </div>
  </div>
</div>
