import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LotModel } from '../../model/auction.model';
import { AuctionLotCategory } from '../../API.service';
import { Timezones } from '../../services/timezones';

@Component({
  selector: 'app-lotdetailtext',
  templateUrl: './lotdetailtext.component.html',
  styleUrls: ['./lotdetailtext.component.scss']
})
export class LotdetailtextComponent implements OnInit {
  @Input() lot:LotModel;
  @Input() displaytype:string = "";

  birthDateStr:string = "";


  constructor(private router:Router) {
  }

  ngOnInit(): void {
    this.setBirthDate();
  }

  setBirthDate(){
    if(this.lot.dob != undefined && this.lot.dob.trim().length > 0){
      this.birthDateStr = Timezones.birthday_moment(this.lot.dob);
    }
  }

  getCategory(){
    var returnValue = "";
    if(this.lot.category != undefined && this.lot.category.trim().length > 0){
      let lotCategory = LotModel.LOT_CATEGORIES.find((el)=>{return el.key == this.lot.category});
      returnValue = lotCategory != undefined ? lotCategory["value"] : "";
    }
    return returnValue;
  }

  getSexedCategory(){
    var returnValue = "";
    if(this.lot.sexedCategory != undefined && this.lot.sexedCategory.trim().length > 0){
      let sexedCategory = LotModel.SEXED_CATEGORIES.find((el)=>{return el.key == this.lot.sexedCategory});
      returnValue = sexedCategory != undefined ? sexedCategory["value"] : "";
    }
    return returnValue;
  }

  isFlush(){
    var returnValue = false;
    return this.getCategory()==AuctionLotCategory.FLUSH;
  }

  isEmbryo(){
    var returnValue = false;
    return this.getCategory()==AuctionLotCategory.EMBRYO;
  }

  getRegUrl(){
    return this.lot.regUrl != undefined && this.lot.regUrl.length > 0 ? this.lot.regUrl : "";
  }

  getInterest(){
    var returnValue = "";
    if(this.lot.interest != undefined && this.lot.interest.length > 0){
      let interestCategory = LotModel.INTEREST_CATEGORIES.find((el)=>{return el.key == this.lot.interest});
      returnValue = interestCategory != undefined ? interestCategory["value"] : "";
    }
    return returnValue;
  }

  getExportableType(){
    var returnValue = "";
    if(this.lot.exportable != undefined && this.lot.exportable.length > 0){
      let exportableTypeCategory = LotModel.EXPORTABLE_TYPES.find((el)=>{return el.key == this.lot.exportable});
      returnValue = exportableTypeCategory != undefined ? exportableTypeCategory["value"] : "";
    }
    return returnValue;
  }

  isGeneralInfo(){
    return this.displaytype == "generalinfo"
  }

  isHeader(){
    return this.displaytype == "header"
  }

  isAll(){
    return this.displaytype == "all"
  }

  sireExists() {
    return this.lot.lineageHierarchy && this.lot.lineageHierarchy.sire;
  }

  damExists() {
    return this.lot.lineageHierarchy && this.lot.lineageHierarchy.dam;
  }

  getCustomFields(){
    return this.lot.customFields != undefined ? this.lot.customFields : [];
  }

}
