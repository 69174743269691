import { Component, OnInit, Input, AfterViewInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { AuctionModel } from '../../model/auction.model';
import { AuctionStatus} from '../../API.service';

@Component({
  selector: 'app-auctiondetail',
  templateUrl: './auctiondetail.component.html',
  styleUrls: ['./auctiondetail.component.scss']
})
export class AuctiondetailComponent implements OnInit {

  _TYPE_AUCTION = "auction";
  _TYPE_SELLER  = "seller";

  @Input() auction:AuctionModel=undefined;
  @Input() type:string="auction";
  @Output() messageEvent = new EventEmitter<any>();

  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  startDate;

  statusValues = {
    "SETUP": "Set Up",
    "CALENDAR_POSTED": "Calendar Posted",
    "SALE_INFO_POSTED":"Sale Info Posted",
    "LOTS_POSTED":"Lots Posted",
    "LIVE":"Live",
    "CLOSED":"Closed",
    "INVOICING":"Invoicing",
    "SETTLED":"Settled",
    "ARCHIVED":"Archived"
  }

  constructor() { }

  ngOnInit(): void {
    this.loadDate();
  }

  getStatusButtonStyle(){
    let statusName = this.auction.status != undefined ? this.auction.status : "";
    if(statusName == AuctionStatus.SETUP){
      return "statusSetupButton";
    }
    else if(statusName == AuctionStatus.CALENDAR_POSTED){
      return "statusCalendarPostedButton";
    }
    else if(statusName == AuctionStatus.SALE_INFO_POSTED){
      return "statusSaleInfoPostedButton";
    }
    else if(statusName == AuctionStatus.LOTS_POSTED){
      return "statusLotsPostedButton";
    }
    else if(statusName == AuctionStatus.LIVE){
      return "statusLiveButton";
    }
    else if(statusName == AuctionStatus.CLOSED){
      return "statusClosedButton";
    }
    else if(statusName == AuctionStatus.SETTLED){
      return "statusSettledButton";
    }
    else {
      return "";
    }
  }

  getAuctionStatus(){
    return this.auction.status != undefined && this.auction.status.length > 0 ? this.statusValues[this.auction.status] : "";
  }

  onViewDetails(){
    var eventMsg = {}
    eventMsg['message'] = 'viewauctiondetails';
    let parms = {
      "auctionId":this.auction.auctionId
    }
    eventMsg['parms'] = parms;
    this.messageEvent.emit(eventMsg);
  }

  onBidderReport(){
    var eventMsg = {}
    eventMsg['message'] = 'bidderreport';
    let parms = {
      "auctionId":this.auction.auctionId,
      "title":this.auction.title
    }
    eventMsg['parms'] = parms;
    this.messageEvent.emit(eventMsg);
  }

  onClone(){
    var eventMsg = {}
    eventMsg['message'] = 'cloneauction';
    let parms = {
      "auctionId":this.auction.auctionId
    }
    eventMsg['parms'] = parms;
    this.messageEvent.emit(eventMsg);
  }

  loadDate(){
    let start = new Date(this.auction.startTime);
    this.auction.timezone = this.auction.timezone != undefined ? this.auction.timezone : "US/Eastern";
    start = this.changeTimezone(start,this.auction.timezone);
    this.startDate = this.formatDay(start);
  }

  formatDay(date) {
      var y = date.getFullYear();
      var m = this.monthNames[date.getMonth()];
      var d = date.getDate();
      return '' + m + ' ' + d + ', ' + y;
  }

  changeTimezone(date, timezone) {

    // suppose the date is 12:00 UTC
    var invdate = new Date(date.toLocaleString('en-US', {
      timeZone: timezone
    }));

    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    var diff = date.getTime() - invdate.getTime();

    // so 12:00 in Toronto is 17:00 UTC
    return new Date(date.getTime() - diff); // needs to substract

  }

  isAuction(){
    return this.type==this._TYPE_AUCTION;
  }

  isSeller(){
    return this.type==this._TYPE_SELLER;
  }

  bidderReportAvailable(){
    return (this.auction.isClosed() || this.auction.isSettled() || this.auction.isArchived())
  }

}
