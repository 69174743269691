<div class="container-fluid mt-2 lot-detail-container" *ngIf="lot">
    <div class="auction-lot-detail-card">
      <div class="row">
        <div class="col auction-lot-header">
          <a (click)="onViewDetails()" class="auction-lot-link" *ngIf="!disableLotLink; else lot_num">Lot {{ lot.lotNum }}:</a>
          <ng-template #lot_num><span class="auction-lot-link">Lot {{ lot.lotNum }}:</span></ng-template>
          <span> {{ lot.title }}</span>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <!-- <ng-container *ngIf="videoSources.length > 0">
            <div [style]="showVideoPlayer() ? 'display:block' : 'display:none'">
              <div class="video-container d-inline-block mr-4">
                <div plyr class="player" style="width: 100%;" plyrTitle="Lot Video" [plyrPlaysInline]="true" [plyrSources]="videoSources" (plyrInit)="player = $event" (plyrPlay)="played($event)"></div>
              </div>
            </div>
          </ng-container> -->

          <div *ngIf="imageObjects.length > 0; else cover_image" class="d-inline-block mr-4 mt-1 mt-md-0 image-slider-container">
            <ng-image-slider
                class="d-sm-block d-none"
                #imageslider
                [images]="imageObjects"
                [infinite]="imageObjects.length > 1"
                [imageSize]="{width: '295px', height: '200px'}"
                [manageImageRatio]="true"
                [showArrow]="true"
                [slideImage]="1"
                [animationSpeed]="0.5"
                [lazyLoading]="true"
                (imageClick)="imageClicked($event)"
                (lightboxClose)="fullScreenImageClosed($event)">
            </ng-image-slider>

            <ng-image-slider
                class="d-sm-none"
                #imagesliderSmall
                [images]="imageObjects"
                [infinite]="imageObjects.length > 1"
                [imageSize]="{width: '100%', height: 'auto'}"
                [manageImageRatio]="true"
                [showArrow]="true"
                [slideImage]="1"
                [animationSpeed]="0.5"
                [lazyLoading]="true"
                (imageClick)="imageClicked($event)"
                (lightboxClose)="fullScreenImageClosed($event)">
            </ng-image-slider>
          </div>
          <ng-template #cover_image>
            <div>
              <img class="lotpic img-fluid" src="{{ lot.getLotCoverImage() }}">
            </div>
          </ng-template>
          <div class="lot-detail-text-container d-inline-block align-top r-top-margin">
            <div *ngIf="videoSources.length > 0">
              <a [routerLink]="" (click)="openVideoPlayer()" class="auction-lot-link flex-vert-center">
                <img class="mr-2" src="../../../assets/icons/video_play_icon.svg" />VIDEO
              </a>
            </div>
            <app-lotdetailtext [lot]="lot" displaytype="all"></app-lotdetailtext>
            <div class="mt-4"><a (click)="onViewDetails()" class="auction-lot-link" *ngIf="!detailedView">VIEW DETAILS</a></div>
          </div>
          <div class="lot-footnote-text-container align-top" *ngIf="showFootnote">
            <ng-container *ngFor="let note of lot.footnote">
              <p style="white-space: pre-wrap">{{ note }}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="auction-bidding-card">
      <app-bidding #bidding [lot]="lot" [authenticated]="authenticated" (messageEvent)="receiveMessage($event)" [previewMode]="previewMode"></app-bidding>
    </div>
</div> <!-- container-fluid -->