import { Component, OnInit, ApplicationRef } from '@angular/core';
import { AuctionModelService, AuctionModel } from '../../model/auction.model';

import * as moment from 'moment';
import { VisibilityService, VisibilityState } from 'src/app/services/visibility.service';
import { ConnectivityService, ConnectivityState } from 'src/app/services/connectivity.service';
import { EventService } from 'src/app/services/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auctions-list',
  templateUrl: './auctions-list.component.html',
  styleUrls: ['./auctions-list.component.scss']
})
export class AuctionsListComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  auctionList:AuctionModel[] = [];
  auctionListByMonths = {};
  sortedAuctionMonths = [];

  numberOfItemsOnPage: number = 24;
  featuredRowSize: number = 2;
  rowSize: number = 4;

  private visibilitySub: Subscription;
  private connectivitySub: Subscription;

  constructor(
    private auctionModel: AuctionModelService,
    private visibilityService: VisibilityService,
    private connectivityService: ConnectivityService,
    private eventService: EventService,
    private appRef: ApplicationRef
  ) { }

  ngOnInit(): void {
    this.loadAuctions(true);

    const localThis = this;
    this.visibilitySub = this.visibilityService.message$.subscribe(async event => {
      if (event === VisibilityState.PAGE_HIDDEN) {
        this.eventService.createEvent({ message: "hide-timers" });
      }
      if (event === VisibilityState.PAGE_VISIBLE) {
        await localThis.loadAuctions(true);
        this.appRef.tick();
        this.eventService.createEvent({ message: "show-timers" });
      }
    });

    this.connectivitySub = this.connectivityService.message$.subscribe(async event => {
      if (event === ConnectivityState.OFFLINE) {
        this.eventService.createEvent({ message: "hide-timers" });
      }
      if (event === ConnectivityState.ONLINE) {
        await localThis.loadAuctions(true);
        this.appRef.tick();
        this.eventService.createEvent({ message: "show-timers" });
      }
    });
  }

  ngOnDestroy(): void {
    this.visibilitySub.unsubscribe();
    this.connectivitySub.unsubscribe();
  }

  async loadAuctions(forcereload=false){
    this.processing = true;
    try {
      this.auctionList = await this.auctionModel.loadOpenAuctions(forcereload);
      this.auctionList = this.auctionList.sort(this.compareEndTimeAsc);
      this.separateByMonths();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving open auctions list');
    }
  }

  compareEndTimeAsc(a: AuctionModel, b: AuctionModel) {
    let firstDate = moment(a.endTime).toDate();
    let secondDate = moment(b.endTime).toDate();

    if ( firstDate > secondDate ){
      return 1;
    }
    else if ( firstDate < secondDate ){
      return -1;
    }
    else {
      return 0;
    }
  }

  separateByMonths(){
    let monthName = "";
    this.sortedAuctionMonths = [];
    this.auctionListByMonths = {};

    for(let item of this.auctionList){
      let endTimeMoment = moment(item.endTime);
      let currentMonth = endTimeMoment.format('MMMM YYYY');
      if(currentMonth != monthName){
        monthName = currentMonth;
        this.auctionListByMonths[currentMonth] = []
        this.sortedAuctionMonths.push(monthName);
      }
      this.auctionListByMonths[monthName].push(item);
    }
  }

}
