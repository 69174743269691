<div class="max-bid-container">
  <nb-icon icon="close-outline" class="float-right close-button" (click)="ref.close()"></nb-icon>

  <h6>Max Bid</h6>

  <p>If another bidder has a max bid higher than yours, this will cause the bidding to jump up to your max bid. If the bidding passes your maximum amount, you may miss out on winning the item.</p>

  <p><strong>Lot {{lot.lotNum}}: {{lot.title}}</strong></p>

  <p>Current: <strong>${{lot.currentBid || lot.startBid}}</strong></p>
  <p>Increment: <strong>${{lot.bidIncrement}}</strong></p>

  <form #f="ngForm" (ngSubmit)="submitMaxBid(f)">
    <div class="label">Your Max Bid Amount</div>

    <div class="row align-items-center">
      <div class="col-12 col-sm-4">
        <input nbInput
          type="number"
          [(ngModel)]="maxBidAmount"
          name="maxBidAmount"
          class="max-bid-amount-field"
          required>
      </div>
      <div class="col-12 col-sm-8" *ngIf="!maxBidValid()">
        <img src="../../../assets/icons/alert-triangle-fill.svg">
        <span class="error-message">Amount must be a multiple of the increment and more than the current bid + increment</span>
      </div>
    </div>

    <p>**Increments may change during the course of an auction. We will place bids on your behalf in the specified increments, up to, but not exceeding, your max bid.</p>

    <button type="submit" [nbSpinner]="processing" nbButton size="medium" shape="round" status="basic" [disabled]="!f.valid || !maxBidValid()">{{bidCaption}}</button>
    <a class="back-link-text ml-2" (click)="ref.close()">Cancel</a>
    <p class="error-message" *ngIf="errorMsg">{{errorMsg}}</p>
  </form>
</div>