import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { NbStepComponent } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { PasswordCheck } from '../passwordcheck';
import { isEmpty } from '../../utils/object.util';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  errorMsg = "";
  processing = false;
  linearMode:boolean = true;
  filteredOptions$: Observable<string[]>;

  showPassword:boolean = false;
  showConfirmPasswordText:boolean = false;

  firstname:string = "";
  lastname:string = "";
  email:string = "";
  password:string = "";
  confirmpassword:string = "";
  businessname:string = "";
  addressline1:string = "";
  addressline2:string = "";
  city:string = "";
  state:string = "";
  zip:string = "";
  phone:string = "";
  termsofservice:boolean = false;
  receiveinformation:boolean = true;
  verificationcode:string = "";
  signincompleted:boolean = false;
  states:string[] = [];

  currentStepIndex: number = 0;

  public theme = environment.theme_config;

  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('stepper') stepper;
  @ViewChildren('step') steps: QueryList<NbStepComponent>;
  // @ViewChild('laststep') laststep;
  @ViewChild('stateautoinput') stateInput;

  constructor(private authService: AuthService){}

  ngOnInit(): void {
      this.states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland", "Nova Scotia", "Northwest Territories", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"];
      this.filteredOptions$ = of(this.states);
  }

  passwordCharLength(){
    return PasswordCheck.passwordCharLength(this.password);
  }

  passwordUpperCase(){
    return PasswordCheck.passwordUpperCase(this.password);
  }

  passwordLowerCase(){
    return PasswordCheck.passwordLowerCase(this.password);
  }

  passwordSpecialChars(){
    return PasswordCheck.passwordSpecialChars(this.password);
  }

  passwordNumber(){
    return PasswordCheck.passwordNumber(this.password);
  }

  validPassword(){
    return PasswordCheck.validPassword(this.password);
  }

  validEmail() {
    var returnValue = false;
    if(this.email != undefined && this.email.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.email).toLowerCase());

    }
    return returnValue;
  }

  validZip(){
    return /\b\d{5}\b/g.test(this.zip) || /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/gi.test(this.zip);
  }

  validPhone(){
    return /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.phone);
  }

  validBusinessInfo(){
    return this.validPhone() && this.validZip() && this.validState() && !isEmpty(this.addressline1) && !isEmpty(this.city);
  }

  validState(){
    return this.states.includes(this.state);
  }

  confirmPasswordCheck(){
    return (this.password.length > 0) && (this.password == this.confirmpassword);
  }

  onToggleShowPassword(){
    this.showPassword = !this.showPassword;
  }

  onToggleShowConfirmPassword(){
    this.showConfirmPasswordText = !this.showConfirmPasswordText;
  }

  close(){
    var eventMsg = {}
    eventMsg['message'] = 'closelogin';
    this.messageEvent.emit(eventMsg);
  }

  onSignIn(){
    var eventMsg = {}
    eventMsg['message'] = 'signin';
    this.messageEvent.emit(eventMsg);
  }

  onDone(){
    var eventMsg = {}
    eventMsg['message'] = 'signupcomplete';
    this.messageEvent.emit(eventMsg);
  }

  onAppleSignin(){
    this.authService.loginApple();
  }

  onFacebookSignin(){
    this.authService.loginFacebook();
  }

  onGoogleSignin(){
    this.authService.loginGoogle();
  }

  onSignupOneNext(){
    this.errorMsg = "";
    if(!this.confirmPasswordCheck()){
      this.errorMsg = "Password and confirmation password do not match";
      return;
    }

    this.onNext();
  }

  onPrevious(){
    this.stepper.previous();
    this.currentStepIndex--;
    this.setStepCompletionStatesUpToStep(this.currentStepIndex);
    this.scrollToSectionHook();
  }

  onNext(){
    this.stepper.next();
    this.currentStepIndex++;
    this.setStepCompletionStatesUpToStep(this.currentStepIndex);
    this.scrollToSectionHook();
  }

  setStepCompletionStatesUpToStep(stepIndex: number) {
    this.steps.forEach((x: NbStepComponent, index: number) => {
      if (index < stepIndex) {
        x.completed = true;
      } else {
        x.completed = false;
        x.reset();
      }
    })
  }

  scrollToSectionHook() {
      setTimeout(() => {
        const element = document.getElementById('cardHeader');
        if(element){
          element.scrollIntoView(true);
        }
      },30);
  }

  onSignup(){
    this.processing = true;
    var optional = {};
    if(this.phone != null && this.phone.length > 0 ){
      optional['phone'] = this.phone;
    }

    if(this.businessname != null && this.businessname.length > 0 ){
      optional['businessname'] = this.businessname;
    }

    if(this.addressline1 != null && this.addressline1.length > 0 ){
      optional['addressline1'] = this.addressline1;
    }

    if(this.addressline2 != null && this.addressline2.length > 0 ){
      optional['addressline2'] = this.addressline2;
    }

    if(this.city != null && this.city.length > 0 ){
      optional['city'] = this.city;
    }

    if(this.state != null && this.state.length > 0 ){
      optional['state'] = this.state;
    }

    if(this.zip != null && this.zip.length > 0 ){
      optional['zip'] = this.zip;
    }

    optional['termsofservice'] = this.termsofservice ? 'TRUE' : 'FALSE';
    optional['receiveinformation'] = this.receiveinformation ? 'TRUE': 'FALSE';

    this.authService.signupUser(this.email,this.password, this.firstname, this.lastname, optional).then(response => {
        this.processing = false;
        this.errorMsg = "";
        this.onNext();
    }, error => {
        this.processing = false;
        this.errorMsg = error.message;
    });

  }

  onConfirm(){
    this.errorMsg = "";
    this.processing = true;
    this._login();
  }

  _login() {
    this.errorMsg = "";
    this.authService.signinUser(this.email, this.password).then(response => {
        this.processing = false;
        this.signincompleted = true;
        this.onDone();

    }, error => {
        this.errorMsg = "Your email address has not yet been confirmed.";
        this.processing = false;
    });
  }

  async _onUpdateProfile(){
    this.processing = true;
    this.authService.activeUser.businessName = this.businessname.length > 0 ? this.businessname : undefined;
    this.authService.activeUser.physicalAddress[0] = this.addressline1;
    this.authService.activeUser.physicalAddress[1] = this.addressline2;
    this.authService.activeUser.physicalCity = this.city.length > 0 ? this.city : undefined;
    this.authService.activeUser.physicalState = this.state.length > 0 ? this.state : undefined;
    this.authService.activeUser.physicalZip = this.zip.length > 0 ? this.zip : undefined;
    this.authService.activeUser.physicalCountry = "US";
    this.authService.activeUser.phone = this.phone.length > 0 ? this.phone : undefined;
    this.authService.activeUser.termsOfService = this.termsofservice;
    this.authService.activeUser.receiveInfo = this.receiveinformation;

    try {
      await this.authService.activeUser.update();
      this.processing = false;
      this.onNext();
      this.signincompleted = true;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = err;
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.stateInput.nativeElement.value);
  }

  onStateSelectedChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
  }

}
