import { Component, Input, OnInit } from '@angular/core';
import Plyr from 'plyr';
import { isIOS } from '../utils/browser-detection.util';


const plyrOptions = {
  controls: ['play-large', 'play', 'progress', 'current-time', 'fullscreen'],
  fullscreen: { iosNative: true }
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input() videoSources: Plyr.Source[] = []

  public player: Plyr;
  public plyrOptions: any;

  constructor() { }

  ngOnInit(): void {
    this.plyrOptions = this.determinePlyrOptions();
  }

  determinePlyrOptions() {
    let options = { ...plyrOptions };
    const videoProviders = this.videoSources.map(x => x.provider);
    if (isIOS && (videoProviders.includes("vimeo") || videoProviders.includes("youtube"))) {
      options.controls = ['play-large', 'play', 'progress', 'current-time'];
    }
    return options;
  }
}
