export const theme = {
  app_title: "Wagyu Live",
  app_breed: "Wagyu",
  favicon_url: "assets/brand/wagyu_favicon_light.svg",
  favicon_url_dark_mode: "assets/brand/wagyu_favicon_dark.svg",
  header_logo_large_url: "assets/brand/wagyu_large_icon.svg",
  header_logo_large_url_alt: "Wagyu Live Logo",
  header_logo_small_url: "assets/brand/wagyu_small_icon.svg",
  home_show_hero_section: true,
  main_banner_url_srcset: [
    "assets/images/header/wagyu_header_XL_2450.png 2450w",
    "assets/images/header/wagyu_header_L_1200.png 1199w",
    "assets/images/header/wagyu_header_M_992.png 992w",
    "assets/images/header/wagyu_header_S_768.png 771w",
    "assets/images/header/wagyu_header_XS_576.png 577w"
  ],
  main_banner_url: "assets/images/header/wagyu_header_S_768.png",
  hero_title: "Unmatched Expertise In Wagyu Marketing",
  hero_subtitle: "Other livestock auction sites exist, but Wagyu Live is the premier online platform for buying and selling Wagyu genetics.",
  why_subtitle: "Our focus is on Wagyu breeders having a breed-specific online marketing option for their programs. Our expertise, and passion run deep, just like yours.",
  learn_more_url: "/sellers#seller-info",
  auction_listing_banner_url_srcset: [
    "assets/images/header/wl_auction_listing_XL_2450.png 2450w",
    "assets/images/header/wl_auction_listing_L_1200.png 1199w",
    "assets/images/header/wl_auction_listing_M_992.png 992w",
    "assets/images/header/wl_auction_listing_S_768.png 771w",
    "assets/images/header/wl_auction_listing_XS_576.png 577w"
  ],
  auction_listing_banner_url: "assets/images/header/wl_auction_listing_S_576.png",
  header_menu: [
    {
      title: "Auctions",
      router_link: ["/auctions"],
      menu_items: []
    },
    {
      title: "Sellers",
      router_link: ["/sellers"],
      menu_items: [
        {
          title: "Seller Portal",
          router_link: ['/sellers', 'seller-portal'],
          required_roles: ['SELLER']
        },
        {
          title: "Marketing and Sales on Wagyu Live",
          router_link: ['/sellers'],
          fragment: "seller-info"
        },
        {
          title: "Services",
          router_link: ['/sellers'],
          fragment: "seller-services"
        },
        {
          title: "Terms and Conditions",
          router_link: ['/sellers'],
          fragment: "terms-and-conditions"
        }
      ]
    },
    {
      title: "Buyers",
      router_link: ["/buyers"],
      menu_items: [
        {
          title: "Bidding Portal",
          router_link: ['/buyers', 'bidding-portal'],
          required_roles: ['SELLER']
        },
        {
          title: "Buying on Wagyu Live",
          router_link: ['/buyers']
        },
        {
          title: "Delivery & Payment",
          router_link: ['/buyers'],
          fragment: "delivery-payment"
        },
        {
          title: "Service Providers",
          router_link: ['/buyers'],
          fragment: "service-providers"
        },
        {
          title: "Terms and Conditions",
          router_link: ['/buyers'],
          fragment: "terms-and-conditions"
        }
      ]
    }
  ],
  sales_office_phone_number: "(406) 571-3006",
  sales_office_email_address: "sales@wagyulive.com",
  marketing_phone_number: "(406) 571-3006",
  marketing_email_address: "marketing@wagyulive.com",
  support_email_address: "support@wagyulive.com",
  footer: {
    show_services: false,
    show_social: false,
    link_class: "footer-link-neutral"
  },
  facebook_url: "",
  instagram_url: "",
  twitter_url: "",
  youtube_url: "",
  auction_card: {
    auction_live_class: 'happening-now'
  },
  seller: {
    show_marketing_sales_highlights: false,
    advantages: {
      heading: "As an emerging or established Wagyu seller, we would like to invite you to realize the advantages of using Wagyu Live as an auction management platform: saving you sale day operation costs; increasing your brand exposure; and leveraging our nation-wide connections to thousands of buyers.",
      benefits: [
        {
          heading: "More sales, with less effort",
          points: [
            "Friendly, helpful and professional service in setting up and running your auction",
            "Deep sales knowledge",
            "A built-in invoicing system",
            "Our web-based system records all buyers, prices, and auction details. We can re-rack a sale in very little time for you."
          ]
        },
        {
          heading: "Reach more, and find new customers",
          points: [
            "Leveraging our contacts and database as well as our Facebook, Instagram, and Twitter feeds for extending awareness and to get your sale and contact links to the buying public.",
            "A better experience for bidders",
            "Fast and reliable bidding platform for desktop and mobile, and a bespoke app for Android or iPhone.A built-in invoicing system"
          ]
        },
      ]
    }
  },
  buyer: {
    show_buying_on: false,
    show_why_buy: false,
    show_payment_options: false,
    payment_footnote: "Payments must be made directly to the seller - see invoice for checks payable and address information."
  }
}