import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { AuctionModelService, LotModel } from 'src/app/model/auction.model';

@Component({
  selector: 'app-max-bid-dialog',
  templateUrl: './max-bid-dialog.component.html',
  styleUrls: ['./max-bid-dialog.component.scss']
})
export class MaxBidDialogComponent implements OnInit {

  @Input() lot: LotModel;
  @Input() accountId: string;

  public maxBidAmount: number;
  public processing: boolean = false;
  public bidCaption: string = "BID NOW";
  public errorMsg: string;

  constructor(private auctionModel: AuctionModelService,
    public ref: NbDialogRef<MaxBidDialogComponent>) { }

  ngOnInit(): void {
    this.maxBidAmount = (this.lot.currentBid || this.lot.startBid) + this.lot.bidIncrement;
  }

  public async submitMaxBid(f: NgForm) {
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.lot.submitMaxBid(this.maxBidAmount, this.accountId);
      this.processing = false;
      this.ref.close();
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "System error while setting max bid.";
      console.log('error while setting max bid', err);
    }
  }

  public maxBidValid() {
    const maxBid = this.maxBidAmount || 0;
    const existingBid = (this.lot.currentBid || this.lot.startBid);
    return (maxBid - existingBid) % this.lot.bidIncrement == 0 && maxBid >= (existingBid + this.lot.bidIncrement);
  }

}
