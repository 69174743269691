<div class="d-flex flex-column align-items-end">
  <button nbButton status="secondary" size="small" class="d-flex" (click)="downloadAllInvoices()" *ngIf="invoicesSent" [nbSpinner]="downloadingAllInvoices">Download All Invoices</button>
</div>

<div *ngFor="let sellerKey of sellerKeys()">
  <h6>{{ groupListing[sellerKey].name }}</h6>
  <div *ngFor="let buyerKey of buyerKeys(sellerKey)">

    <div class="d-flex flex-row justify-content-between align-items-center winning-bidder-container1">

      <div class="d-flex flex-row">
        <div class="winning-bidder-header">Winning Bidder</div>
        <div class="ml-3 winning-bidder-name">{{ groupListing[sellerKey].bidders[buyerKey].name }}</div>
      </div>

      <div class="d-flex flex-row align-items-center">
        <ng-container *ngIf="groupListing[sellerKey].bidders[buyerKey].paymentStatus == 'UNPAID'">
          <ng-container *ngIf="sendInvoiceEnabled(sellerKey, buyerKey); else invoice_actions"></ng-container>
          <ng-template #invoice_actions>
            <div class="invoice-actions-container d-flex justify-content-end" *ngIf="!isAuctionSettled()">
              <span class="mr-2">Invoiced {{ formatAsMonthDayYear(groupListing[sellerKey].bidders[buyerKey].invoiceDateSent) }}</span>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="invoiceNotAutomatedPaidYet(sellerKey, buyerKey); else automated_paid">
          <div class="d-flex flex-row align-items-center">
            <span class="mr-2">{{ getPaidStatusString(sellerKey,buyerKey) }}</span>
            <button nbButton size="tiny" status="basic" (click)="onViewInvoice(groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId)" *ngIf="groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId">INVOICE</button>
            <button nbButton status="basic" size="tiny" outline [nbSpinner]="downloadingInvoice[sellerKey + buyerKey]" (click)="downloadInvoice(groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId, sellerKey, buyerKey)" *ngIf="groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId" class="ml-2">Download<nb-icon icon="download-outline"></nb-icon></button>
          </div>
        </ng-container>

        <ng-template #automated_paid>
          <div class="d-flex flex-row align-items-center">
            <nb-icon status="basic" icon="done-all-outline"></nb-icon>
            <span class="mr-2">Paid {{ getInvoicePaidDateString(sellerKey,buyerKey) }}</span>
            <button nbButton size="tiny" status="basic" (click)="onViewInvoice(groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId)" *ngIf="groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId">INVOICE</button>
            <button nbButton status="basic" size="tiny" outline [nbSpinner]="downloadingInvoice[sellerKey + buyerKey]" (click)="downloadInvoice(groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId, sellerKey, buyerKey)" *ngIf="groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId" class="ml-2">Download<nb-icon icon="download-outline"></nb-icon></button>
          </div>
        </ng-template>
      </div>
    </div>

    <ng-container *ngIf="groupListing[sellerKey].bidders[buyerKey].lineItems.length > 0">
      <div class="d-flex flex-row justify-content-between align-items-center winning-bidder-container2">
        <div class="d-flex flex-row">
          <div class="mr-4">{{ groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderPhoneNumber }}</div>
          <div class="mr-4">{{ groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderEmail }}</div>
          <div>{{ getAddress(groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderAddress) }}</div>
          <div class="noaddress-text" *ngIf="!groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderAddress">No Address on File</div>
        </div>
      </div>
    </ng-container>

    <div class="table-responsive winning-bidder-table">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="table-header-text-link" style="min-width: 4rem;">LOT #</th>
            <th scope="col" class="table-header-text-link" style="min-width: 18.75rem;">NAME</th>
            <th scope="col" class="table-header-text-link" style="width: 10rem;">FINAL BID</th>
            <th scope="col" class="table-header-text-link">QTY</th>
            <th scope="col" class="table-header-text-link">FINAL PRICE</th>
          </tr>
        </thead>
        <tbody>
            <tr app-auction-report-lot-detail *ngFor="let item of groupListing[sellerKey].bidders[buyerKey].lineItems" [lot]="item" saleStatus="SOLD" [auctionStatus]="auctionModel.activeAuction.status"></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
