import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { VideoSource } from 'src/app/auctiondetails/lotdetail/lotdetail.component';

@Component({
  selector: 'app-video-player-dialog',
  templateUrl: './video-player-dialog.component.html',
  styleUrls: ['./video-player-dialog.component.scss']
})
export class VideoPlayerDialogComponent implements OnInit {
  public videoSources: VideoSource[] = []

  constructor(protected ref: NbDialogRef<VideoPlayerDialogComponent>) { }

  ngOnInit(): void {
  }

  public close(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

}
