<div class="container-fluid">

    <nb-card class="auction-card">

      <nb-card-body [ngStyle]="getCardStyle()">
        <div class="d-flex flex-column justify-content-between h-100 px-3 pb-3">
          <ng-container>
            <div class="row mb-5">
              <div class="col no-gutters">
                <div class="flex-center">
                    <div class="flex-center" [ngClass]="{ 'happening-now': auction.isLive(), 'closed': auction.isClosed() }">{{ getBannerText() }}</div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row mt-2">
            <div class="col-12 col-sm-6">
              <a class="logo flex-center" *ngIf="auctionActive()" [routerLink]="" (click)="onViewDetails()">
                  <img class="logo" src="{{ auction.getLogoUrl() }} ">
              </a>
              <img *ngIf="!auctionActive()" class="logo flex-center" src="{{ auction.getLogoUrl() }} ">
            </div>
            <div class="col-12 col-sm-6 p-3">
              <div class="auction-title">{{ auction.title }} </div>
              <div class="auction-sale-end-date">Sale Ends: <br/> {{ getFormattedEndDate() }} | <br/> {{ getFormattedEndTime() }}</div>
            </div>
          </div>

          <div class="row">
              <div class="col link-text">
                <div class="d-flex flex-row justify-content-between align-items-end">
                  <a *ngIf="auctionActive()" [routerLink]="" (click)="onViewDetails()" class="view-details">VIEW DETAILS</a>
                  <div *ngIf="!auctionActive()"></div>
                  <div class="auction-time-remaining-container">
                    <app-auction-time-remaining-counter #apptimecounter [auction]="auction"></app-auction-time-remaining-counter>
                  </div>
                </div>
              </div>
          </div>
        </div>

      </nb-card-body>
    </nb-card>

</div>
