<div class="container-fluid">

    <div class="row">
    	<div class="col">
          <ng-container *ngIf="lotsExist()">
            <div *ngFor="let item of auction.lots" class="mt-3 mb-2" [id]="item.lotId">
              <app-lotdetail #lotdetail [lot]="item" [authenticated]="authenticated" (messageEvent)="receiveMessage($event)" [previewMode]="previewMode"></app-lotdetail>
            </div>
          </ng-container>
		  </div>
	</div>

</div>