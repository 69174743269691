import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { PasswordCheck } from '../passwordcheck';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  public errorMsg = '';
  public processing = false;

  public showPassword = false;
  public showNewPasswordText = false;
  public showConfirmPasswordText = false;

  public theme = environment.theme_config;

  public changePassword = false;
  public confirmation = false;
  public login = true;

  /* Form entry */
  public email = '';
  public password = '';
  public newpassword = '';
  public confirmpassword = '';
  public portalUser = undefined;
  public verificationcode = '';

  @Output() public messageEvent = new EventEmitter<any>();

  constructor(private authService: AuthService) {}

  public ngOnInit(): void {
    this.showLogin();
  }

  public passwordCharLength() {
    return PasswordCheck.passwordCharLength(this.newpassword);
  }

  public passwordUpperCase() {
    return PasswordCheck.passwordUpperCase(this.newpassword);
  }

  public passwordLowerCase() {
    return PasswordCheck.passwordLowerCase(this.newpassword);
  }

  public passwordSpecialChars() {
    return PasswordCheck.passwordSpecialChars(this.newpassword);
  }

  public passwordNumber() {
    return PasswordCheck.passwordNumber(this.newpassword);
  }

  public validPassword() {
    return PasswordCheck.validPassword(this.newpassword);
  }

  public confirmPasswordCheck() {
    return (this.newpassword.length > 0) && (this.newpassword == this.confirmpassword);
  }

  public showLogin() {
    this.login = true;
    this.changePassword = false;
    this.confirmation = false;
  }

  public showNewPassword() {
    this.login = false;
    this.changePassword = true;
    this.confirmation = false;
  }

  public showConfirmation() {
    this.login = false;
    this.changePassword = false;
    this.confirmation = true;
  }

  public onToggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  public onToggleShowNewPassword() {
    this.showNewPasswordText = !this.showNewPasswordText;
  }

  public onToggleShowConfirmPassword() {
    this.showConfirmPasswordText = !this.showConfirmPasswordText;
  }

  public close() {
    this.messageEvent.emit({
      message: 'closelogin'
    });
  }

  public onForgotPassword() {
    this.messageEvent.emit({
      message: 'forgotpassword'
    });
  }

  public onSignUp() {
    this.messageEvent.emit({
      message: 'signup'
    });
  }

  public onSignIn() {
    this.processing = true;
    this.errorMsg = '';
    this.authService.signinUser(this.email, this.password).then((response) => {
        this.processing = false;
        this.processLoginResponse(response);
    }, (error) => {
        this.processing = false;
        this.processLoginException(error);
        return;
    });
  }

  public onSignInNewPassword() {
    this.errorMsg = '';
    if (this.newpassword != this.confirmpassword) {
      this.errorMsg = 'New password and confirmation password do not match';
      return;
    }

    this.processing = true;
    this.authService.completeNewPassword(this.email, this.password, this.newpassword).then((response) => {
        this.processing = false;
        this.signinSuccessful();
      }, (error) => {
        this.processing = false;
        this.processLoginException(error);
        return;
    });
  }

  public onConfirm() {
    this.errorMsg = '';
    this.processing = true;
    this.authService.signinUser(this.email, this.password).then((response) => {
        this.processing = false;
        this.processLoginResponse(response);
    }, (error) => {
        this.processing = false;
        if (error.code == 'UserNotConfirmedException') {
          this.errorMsg = 'Your email address has not yet been confirmed.';
        } else {
          this.processLoginException(error);
        }
    });
  }

  public async processLoginResponse(response) {
    if (response.challengeName == 'NEW_PASSWORD_REQUIRED') {
      this.showNewPassword();
    } else {
      this.signinSuccessful();
    }
  }

  public processLoginException(exception) {
    if (exception.code == 'PasswordResetRequiredException') {
      this.showNewPassword();
    } else if (exception.code == 'UserNotConfirmedException') {
      this._resendSignup();
    } else if (exception.code == 'NotAuthorizedException') {
      this.errorMsg = 'The email and password you entered did not match our records. Please double-check and try again.';
      this.showLogin();
    } else if (exception.code == 'UserNotFoundException') {
      this.errorMsg = 'We couldn’t find your account.';
      this.showLogin();
    } else {
      this.errorMsg = exception.message == undefined ? '' : exception.message;
    }
  }

  public resetPassword() {
    this.messageEvent.emit({
      message: 'forgotpassword',
      email: this.email
    });
  }

  public async signinSuccessful() {
    await this.authService.getAuthenticatedUser();
    this.messageEvent.emit({
      message: 'signinsuccessful',
      portaluser: this.authService.activeUser
    });
  }

  public onAppleSignin() {
    this.authService.loginApple();
  }

  public onFacebookSignin() {
    this.authService.loginFacebook();
  }

  public onGoogleSignin() {
    console.log('onGoogleSignin');
    this.authService.loginGoogle();
  }

  public _resendSignup() {
    this.errorMsg = '';
    this.processing = true;

    this.authService.resendSignup(this.email).then((response) => {
        this.processing = false;
        this.showConfirmation();
    }, (error) => {
        console.log('error confirming', error);
        this.errorMsg = error.message;
        this.processing = false;
        return;
    });

  }

}
