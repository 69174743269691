<div class="container-fluid footer">
  <div class="row mt-4">
    <div class="col-sm-4 mb-2">
        <div class="row footer-block">
            <div class="col">
                <app-section-header class="footer-header">Sales Office</app-section-header>
                <div class="footer-address-line">{{theme.sales_office_phone_number}}</div>
                <div class="footer-address-line mt-1"><a [href]="'mailto:' + theme.sales_office_email_address" [class]="theme.footer.link_class">{{theme.sales_office_email_address}}</a></div>
            </div>
        </div>

        <div class="row footer-block mt-3 mt-sm-5">
          <div class="col">
            <app-section-header class="footer-header">Auction Support</app-section-header>
            <div class="footer-address-line mt-1"><a [href]="'mailto:' + theme.support_email_address" [class]="theme.footer.link_class">{{theme.support_email_address}}</a></div>
          </div>
        </div>

        <div class="row footer-block mt-3 mt-sm-5">
            <div class="col">
              <app-section-header class="footer-header">Physical Address</app-section-header>
              <div class="footer-address-line">41338 Ridgeway Road</div>
              <div class="footer-address-line">Charlo, MT 59824</div>
            </div>
        </div>
    </div>

    <div class="col-sm-4 footer-side-border">
      <div class="row footer-block">
          <div class="col mt-n2">
            <div *ngFor="let menu of theme.header_menu" class="footer-nav-link mt-2"><a [routerLink]="menu.router_link">{{menu.title}}</a></div>
          </div>
      </div>
      <div class="row footer-block mt-3" *ngIf="theme.footer.show_services">
          <div class="col">
              <app-section-header class="footer-header">Services</app-section-header>
              <div class="footer-address-line">The {{theme.app_title}} Story</div>
              <div class="footer-address-line mt-1">Our Team</div>
          </div>
      </div>
    </div>

    <div class="col-sm-4 footer-side-border mt-3 mt-sm-0">
      <div class="row footer-block footer-block-side-border">
        <div class="col">
          <div *ngIf="theme.footer.show_social">
            <a target="_blank" [href]="theme.facebook_url"><img src="assets/images/facebook-fill.svg" class="social-button"></a>
            <a target="_blank" [href]="theme.instagram_url"><img src="assets/images/instagram-fill.png" class="social-button ml-4"></a>
            <a target="_blank" [href]="theme.twitter_url"><img src="assets/images/twitter-fill.svg" class="social-button ml-4"></a>
          </div>
          <div class="mt-4" *ngIf="theme.footer.show_social">
            <a target="_blank" [href]="theme.youtube_url"><img src="assets/images/youtube-fill.svg" class="youtube-button"></a>
          </div>
          <div [ngClass]="{ 'mt-4': theme.footer.show_social }">
            <p class="version-number">v1.6.2</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>