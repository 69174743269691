<div class="container-fluid">
  <div class="background">
    <img [srcset]="theme.main_banner_url_srcset.join(',')"
      sizes="
      (max-width: 1800px) 1500px,
      (max-width: 1200px) 1000px,
      (max-width: 992px) 900px,
      (max-width: 768px) 700px,
      (max-width: 576px) 480px,
        800px"
      [src]="theme.main_banner_url"
      alt="Logo background"
      class="img-background">

    <div class="hero-container">
      <p class="title">{{theme.hero_title}}</p>
      <p class="subtitle">{{theme.hero_subtitle}}</p>
      <button nbButton shape="round" size="large" status="primary" (click)="onViewSaleCalendar()" class="px-5 mt-4">VIEW SALE CALENDAR</button>
    </div>

    <div class="intro-container">
      <p class="title">Why {{theme.app_title}} is YOUR Marketing Partner</p>
      <p class="subtitle">{{theme.why_subtitle}}</p>

      <div class="d-flex flex-row align-items-center justify-content-center">
        <a class="learn-more-link" [href]="theme.learn_more_url">LEARN MORE ABOUT {{theme.app_title}}</a>
        <nb-icon icon="arrow-forward" class="forward-icon"></nb-icon>
      </div>
    </div>

    <div class="happening-now">
      <p class="title" *ngIf="auctionList.length > 0">Auctions Happening Now</p>
      <p class="title" *ngIf="auctionList.length === 0">Upcoming Auctions</p>

      <p class="no-auctions-message mt-3" *ngIf="auctionList.length === 0 && !processing">View the calendar for the auction we have scheduled in the current month.</p>

      <div class="auction-card-container">
        <app-auction-card class="auction-card" [auction]="item" [featured]="false" *ngFor="let item of auctionList"></app-auction-card>
      </div>

      <div class="d-flex flex-row justify-content-center">
        <button nbButton shape="round" size="large" status="basic" (click)="onViewSaleCalendar()" class="px-5 mt-4">VIEW SALE CALENDAR</button>
      </div>
    </div>

    <div class="buying-selling">
      <p class="title">Buying and Selling on the {{theme.app_title}} Platform</p>

      <div class="benefits-container row mx-sm-n2 my-lg-0 my-n2 justify-content-center">
        <div class="col-12 col-lg-3 mx-sm-2 my-lg-0 my-2" *ngFor="let item of benefitCards">
          <app-benefits-card [cardDetail]="item"></app-benefits-card>
        </div>
      </div>
  </div>

</div> <!-- END container-fluid -->