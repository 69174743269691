// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { theme } from '../assets/themes/wagyu/wagyu_theme';

export const environment = {
  production: true,
  awsconfig: {
    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint: "https://g5hjyvqnqzdrjdnbaofn35secy.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:e8e01246-10a2-4bde-a4bd-73ca1c78b1f6",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_qK0EixWqY",
    aws_user_pools_web_client_id: "6tt7iubp56c2a9plg2bq9h18a5",
    oauth: {
        domain: "g2-wagyu-dev.auth.us-east-1.amazoncognito.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://localhost:4200/",
        redirectSignOut: "https://localhost:4200/",
        responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS",
  },
  image_path_url: "assets-dev.wagyulive.com",
  asset_path_url: "assets-dev.wagyulive.com",
  buyer_portal_path_url: "app-dev.wagyulive.com",
  stripe_publishable_key: 'pk_test_51IGWb4Fnwgmp5C89ivT5pZw8usT6FVoSGtIyh0HVpcxo45Vs4xBfyJM4UJpHtS32Apl6ev0dPj2Mc5pF6FD8en1e00GwTnDmGH',
  theme_config: theme
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import "zone.js/dist/zone-error";  // Included with Angular CLI.
