<div class="container-fluid bid-area">

    <div *ngIf="lot.biddingDisabled" class="pt-3 bid-summary-container">Bidding has been disabled for this lot.</div>
    <div [hidden]="lot.biddingDisabled">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="bid-action-container" *ngIf="isLive()">
          <button class="bid-button" nbButton shape="round" size="medium" status="basic" (click)="onSignIn()" *ngIf="!authenticated">SIGN IN TO BID</button>
          <button class="bid-button" nbButton shape="round" size="medium" status="basic" [nbSpinner]="processing" nbSpinnerStatus="control" (click)="bidNow()" [disabled]="bidButtonDisabled()" *ngIf="authenticated">{{ getBidNowStr() }}</button>
          <span class="successful-bid-text ml-sm-4 d-none d-sm-flex" *ngIf="lot.winMsg.length > 0">{{ lot.winMsg }}</span>
          <span class="outbid-text ml-sm-4 d-none d-sm-flex" *ngIf="lot.outBidMsg.length > 0">{{ lot.outBidMsg }}</span>
          <span class="errorText ml-sm-4 d-none d-sm-flex" *ngIf="errorMsg.length > 0">{{ errorMsg }}</span>
        </div>
        <div *ngIf="!isLive() && !isClosed()"></div>
        <div *ngIf="isClosed()">
            <div>
              Winning Bid: <span class="bid-item">{{ getFinalBid() }}</span>
            </div>
        </div>

        <div class="auction-time-remaining-counter-container">
          <app-auction-time-remaining-counter #apptimecounter></app-auction-time-remaining-counter>
        </div>
      </div>

      <div class="d-flex d-sm-none mt-2" *ngIf="isLive()">
        <span class="successful-bid-text" *ngIf="lot.winMsg.length > 0">{{ lot.winMsg }}</span>
        <span class="outbid-text" *ngIf="lot.outBidMsg.length > 0">{{ lot.outBidMsg }}</span>
        <span class="errorText" *ngIf="errorMsg.length > 0">{{ errorMsg }}</span>
      </div>

      <div class="d-flex flex-row mt-1 bid-summary-container" *ngIf="isLive()">
        <div>
          Current: <span class="bid-item">${{ getCurrentBid() }}</span>
        </div>
        <div class="ml-2 ml-sm-4">
          Increment: <span class="bid-item">${{ lot.bidIncrement || auctionModel.activeAuction.bidIncrement || "" }}</span>
        </div>
        <div class="ml-2 ml-sm-4">
          # Bids: <span class="bid-item">{{ lot.bidCount || 0 }}</span>
        </div>
      </div>

      <div class="d-flex flex-row bid-calculation-container flex-vert-center mb-2 mr-3" *ngIf="hasMultipleQuantities() && isLive()">
        <div>
          Bid: <span class="bid-item">${{ getCurrentBid() }}</span>
        </div>
        <div class="ml-2 ml-sm-4 flex-vert-center">
          <nb-icon icon="close-outline"></nb-icon>
        </div>
        <div class="ml-2 ml-sm-4">
          Quantity: <span class="bid-item">{{ lot.quantity || 0 }}</span>
        </div>
        <div class="ml-2 ml-sm-4 flex-vert-center">
          <nb-icon icon="arrow-forward-outline"></nb-icon>
        </div>
        <div class="ml-2 ml-sm-4">
          Total: <span class="bid-item">${{ getSubtotal() }}</span>
        </div>
      </div>

      <div class="max-bid-container" *ngIf="isLive() && authenticated">
        <a class="view-details" (click)="onMaxBid()">MAX BID</a>
        <span class="ml-2" *ngIf="isMaxBidder()"><strong>MAX BID ENABLED: ${{ lot.currentMaxBid }}</strong></span>
      </div>
    </div>
</div> <!-- container-fluid -->
