import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuctionModelService, AuctionModel } from '../../model/auction.model';
import { FileTransferService } from '../../services/filetransfer.service';

@Component({
  selector: 'app-manageauctions',
  templateUrl: './manageauctions.component.html',
  styleUrls: ['./manageauctions.component.scss']
})
export class ManageauctionsComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  sub;
  current: boolean = true;
  searchauction:string = "";
  openAuctionList:AuctionModel[] = [];
  archiveAuctionList:AuctionModel[] = [];

  @ViewChild('currentlist') currentlist;  
  @ViewChild('archivelist') archivelist;  
  @ViewChild('downloadLink') downloadLink: ElementRef;  
  
  lastStatusFilter:number = 0;

  constructor(private auctionModel: AuctionModelService,
              private router: Router,
              private activatedRoute:ActivatedRoute,
              private fileTransferService : FileTransferService) { }

  ngOnInit(): void {
    this.current = true;
    this.sub=this.activatedRoute.paramMap.subscribe(params => { 
          let statusFilter = params.get('status') != undefined ? parseInt(params.get('status')) : 0;
          if(statusFilter >= 0){
            this.lastStatusFilter = params.get('status') != undefined ? parseInt(params.get('status')) : 0; 
          }
      });
    
    this.loadOpenAuctions();
  }
  
  selectTab($event) {
    if($event.tabTitle =='CURRENT AUCTIONS'){
      this.current = true;
    }
    else if($event.tabTitle =='ARCHIVED AUCTIONS'){
      this.current = false;
    }
  }
  
  async onUpdateFilter($event){
    if(this.current){
      this.currentlist.setPage(1);
      await this.currentlist.filter(this.searchauction);
    }
    else if(!this.current){
      this.archivelist.setPage(1);
      await this.archivelist.filter(this.searchauction);
    }
  }
  
  async onFilterByStatus(status){
    if(status == this.lastStatusFilter){
      this.lastStatusFilter = 0;
    }
    else{
      this.lastStatusFilter = status;
    }
    await this.applyFilterByStatus();
  }

  async applyFilterByStatus(){
    if(this.current){
      await this.currentlist.filterByStatus(this.lastStatusFilter);
    }
    else if(!this.current){
      await this.archivelist.filterByStatus(this.lastStatusFilter);
    }
  }
  
  getAppearance(status){
    return this.lastStatusFilter == status ? "filled" : "ghost";
  }

  getStatus(status){
    return this.lastStatusFilter == status ? "primary" : "basic";
  }
  
  async loadOpenAuctions(){
    this.processing = true;
    try {
      this.openAuctionList = await this.auctionModel.loadSellerOpenAuctions(true);
      this.currentlist.rows = this.openAuctionList;
      this.currentlist != undefined ? this.currentlist.loadPageData() : "";
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving open auctions list');
    }
  }
  
  async generateBidderReport(auctionId:string,auctionTitle:string){
    this.processing = true;
    this.errorMsg = "";
    let returnValue = undefined
    try {
      let myAuction = new AuctionModel();
      returnValue = await myAuction.generateBidderReport(auctionId);
      if(returnValue['status']=='SUCCESS'){
        let filename = auctionTitle.split(" ").join("_") + "_bidderReport.csv";
        await this.downloadFile(filename,returnValue['downloadUrl']);
      }
      else{
        this.errorMsg = returnValue['message']        
      }

      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error generating bidder report.";
    }
    return returnValue;
  }
  
  async cloneAuction(auctionId:string){
    this.processing = true;
    this.errorMsg = "";
    let returnValue = undefined
    try {
      let auctionToClone = new AuctionModel();
      let clonedAuctionId = await auctionToClone.cloneAuctionAndAssets(auctionId);
      this.processing = false;
      returnValue = clonedAuctionId;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error cloning auction: " + err;
    }
    return returnValue;
  }
  
  
  getRecordsLength(){
   return this.current ? this.openAuctionList.length : this.archiveAuctionList.length;
  }
  
  onNewAuction(){
    // 	this.router.navigate(['/editauction', "NEW","NEW",this.lastStatusFilter,0,1,0,0]);
  }
  
  async receiveMessage($event) {
    if($event['message']=='viewauctiondetails'){
    	this.router.navigate(['/sellers/seller-portal/auctiondetails', "EDIT",$event['parms']['auctionId'],this.lastStatusFilter,0,4,0,0]);
    }
    else if($event['message']=='bidderreport'){
      this.generateBidderReport($event['parms']['auctionId'],$event['parms']['title']);
    }
    else if($event['message']=='cloneauction'){
      // console.log($event);
      // let clonedAuctionId = await this.cloneAuction($event['parms']['auctionId']);
      // if(clonedAuctionId != undefined){
    	 // this.router.navigate(['/editauction', "EDIT",clonedAuctionId,0,0,1,0,0]);
      // }
    }
  }
  
  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }
  
  async downloadFile(filename,signedurl){
    this.fileTransferService.downloadSignedUrl(filename,signedurl)
      .subscribe(
        data => {
            this.saveFile(data['blob'],filename)
            return data;
      },
        (err) => {
          throw(err);
      }
    );
  }
  
  saveFile(blob:Blob,filename:string){
    const url = window.URL.createObjectURL(blob);
    const link = this.downloadLink.nativeElement;
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }  
  
  
}
