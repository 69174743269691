<div class="container-fluid container-horiz-padded">
  <div class="auction-list-card-container">
    <app-section-header underlineThickness="thick" type="primary">Auctions</app-section-header>

    <ng-container *ngFor="let month of sortedAuctionMonths">
        <div class="month-header">
            {{ month }}
        </div>

        <div class="row mt-3">
          <div class="col d-flex flex-row flex-wrap auction-group-container">
            <ng-container *ngFor="let auctionGroup of auctionListByMonths[month]">
              <div class="auction-card-container">
                <app-auction-card class="auction-card" [auction]="auctionGroup"></app-auction-card>
              </div>
            </ng-container>
          </div>
        </div>

    </ng-container>
  </div>
</div>
