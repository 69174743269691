import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SettingshomeComponent } from './accounts/settingshome/settingshome.component';
import { AuctiondetailshomeComponent } from './auctiondetails/auctiondetailshome/auctiondetailshome.component';
import { AuctionlotdetailsComponent } from './auctiondetails/auctionlotdetails/auctionlotdetails.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { SellerHomeComponent } from './sellers/seller-home/seller-home.component';
import { AuctionsHomeComponent } from './auctions/auctions-home/auctions-home.component';
import { AuthGuard, AuthSellerGuard } from './services/auth-guard.service';
import { BuyerHomeComponent } from './buyers/buyer-home/buyer-home.component';
import { BuyerBiddingPortalHomeComponent } from './buyers/buyer-bidding-portal-home/buyer-bidding-portal-home.component';
import { CommunityHomeComponent } from './community/community-home/community-home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ManageauctionsComponent } from './auctions/manageauctions/manageauctions.component';
import { AuctionedithomeComponent } from './auctions/auctionedithome/auctionedithome.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'auctions', component: AuctionsHomeComponent },
  { path: 'auctions/:id', component: AuctiondetailshomeComponent },
  { path: 'auctions/:auctionId/:lotId', component: AuctionlotdetailsComponent},
  { path: 'sellers', component: SellerHomeComponent },
  { path: 'sellers/seller-portal', component: ManageauctionsComponent, canActivate: [AuthSellerGuard]  },
  { path: 'sellers/seller-portal/auctiondetails/:editmode/:id/:filterStatus/:sellerId/:auctiontab/:lottab/:lotId', component: AuctionedithomeComponent, canActivate: [AuthSellerGuard]  },
  { path: 'buyers', component: BuyerHomeComponent },
  { path: 'buyers/bidding-portal', component: BuyerBiddingPortalHomeComponent },
  { path: 'community', component: CommunityHomeComponent },
  { path: 'accountsettings/:id', component: SettingshomeComponent, canActivate: [AuthGuard] },
  { path: 'previewauction/:id', component: AuctiondetailshomeComponent },
  { path: 'previewauction/:auctionId/:lotId', component: AuctionlotdetailsComponent},
  { path: 'invoice/:id', component: InvoiceComponent, data: { header: false, footer: false } },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];


const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

