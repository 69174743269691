<div class="container-fluid">

    <nb-card class="auction-card">

      <nb-card-body>

        <div class="row mb-4 logo-container" *ngIf="data.logourl != undefined">
            <div class="col-12">
                <div class="flex-center">
                    <img class="logo flex-center" src="{{ data.logourl }} ">
                </div>
            </div>
        </div>

        <div class="row mb-2" *ngIf="data.company != undefined">
            <div class="col-12 no-padding">
                <div class="title-text"> {{ data.company }} </div>
            </div>
        </div>

        <div class="row mb-2" *ngIf="data.name != undefined">
            <div class="col-12 no-padding">
                <div class="content-label-text"> {{ data.name }} </div>
            </div>
        </div>

        <div class="row" *ngIf="data.addressline1 != undefined">
            <div class="col-12 no-padding">
                <div class="content-label-text"> {{ data.addressline1 }} </div>
            </div>
        </div>

        <div class="row" *ngIf="data.addressline2 != undefined">
            <div class="col-12 no-padding">
                <div class="content-label-text"> {{ data.addressline2 }} </div>
            </div>
        </div>

        <div class="mb-2" *ngIf="data.addressline1 != undefined || data.addressline2 != undefined"></div>

        <div class="row" *ngIf="data.cell != undefined">
            <div class="col-12 no-padding">
                <div class="content-label-text"> {{ data.cell }} Cell </div>
            </div>
        </div>

        <div class="row" *ngIf="data.office != undefined">
            <div class="col-12 no-padding">
                <div class="content-label-text"> {{ data.office }} Office </div>
            </div>
        </div>

        <div class="row" *ngIf="data.fax != undefined">
            <div class="col-12 no-padding">
                <div class="content-label-text"> {{ data.fax }} Fax </div>
            </div>
        </div>

        <div class="mb-2" *ngIf="data.cell != undefined || data.office != undefined || data.fax != undefined"></div>

        <div class="row" *ngIf="data.email != undefined">
            <div class="col-12 no-padding">
                <a class="link-text" [href]="getMailToUrl()" target="_blank">{{ data.email }}</a><br>
            </div>
        </div>

        <div class="row" *ngIf="data.website != undefined">
            <div class="col-12 no-padding">
                <a class="link-text" [href]="getWebSiteUrl()" target="_blank">{{ data.website }}</a><br>
            </div>
        </div>

        <div class="mb-1" *ngIf="data.email != undefined || data.website != undefined"></div>

      </nb-card-body>
    </nb-card>

</div>
