import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Invoice, InvoiceModelService } from '../model/invoice.model';
import { environment } from '../../environments/environment';

const DATE_FORMAT = 'MM/DD/YYYY h:mm A';

@Component({
  selector: 'app-invoice',
  styleUrls: ['./invoice.component.scss'],
  templateUrl: './invoice.component.html',
})
export class InvoiceComponent implements OnInit {
  public invoiceId: string;
  public invoice: Invoice = {} as Invoice;
  public loading: boolean = false;
  public invoiceDateFormatted: string = '';
  public saleAuctionClosingDateFormatted: string = '';
  public datePaidFormatted: string = '';
  public paymentClientSecret: string;
  public printableMode: boolean = false;
  public logoSrc: string;
  public invoiceUrl: string;
  public downloading: boolean = false;
  public theme = environment.theme_config;

  constructor(private route: ActivatedRoute,
    private invoiceModel: InvoiceModelService,
    private router: Router
  ) {
    this.invoiceId = this.route.snapshot.params.id;

    this.logoSrc = `${window.location.protocol}//${window.location.host}/${this.theme.header_logo_large_url}`;
    this.invoiceUrl = window.location.href.split("?")[0];

    this.route.queryParams
      .subscribe(params => {
        if (params.print) {
          this.printableMode = true;
        }
    }
  );
  }

  public async ngOnInit(): Promise<any> {
    this.loading = true;
    try {
      this.invoice = await this.invoiceModel.loadInvoice(this.invoiceId);
      if (this.invoice) {
        this.invoiceDateFormatted = this.formatDate(this.invoice.invoiceDate);
        this.saleAuctionClosingDateFormatted = this.formatDate(this.invoice.saleAuctionClosingDate);
        this.datePaidFormatted = this.formatDate(this.invoice.datePaid);
        this.paymentClientSecret = this.invoice.paid ? null : await this.invoiceModel.getPaymentClientSecret(this.invoice.invoiceId);
      }
      this.loading = false;
    } catch (e) {
      this.router.navigate(['/404']);
    }
  }

  public printInvoice(): void {
    window.print();
  }

  private formatDate(dateString: string): string {
    return dateString ? moment(dateString).format(DATE_FORMAT) : '';
  }

  public async receiveMessage($event) {
    if ($event.message === 'paymentSuccessful') {
      this.invoice.paid = true;
      this.invoice.totalAmountPaid = $event.data.amount / 100.0;
      const datePaid = moment.unix($event.data.created);
      if (datePaid.isValid()) {
        this.datePaidFormatted = datePaid.format(DATE_FORMAT)
      }
      this.invoice.paymentMethod = ($event.data.payment_method_types || []).includes('card') ? 'CREDIT CARD' : 'OTHER';
    }
  }

  public async downloadInvoice() {
    try {
      this.downloading = true;
      const result = await this.invoiceModel.downloadInvoice(this.invoiceId);
      this.triggerDownload(result.downloadUrl);
    } catch (e) {
      console.error("Error downloading invoice", e);
    } finally {
      this.downloading = false;
    }
  }

  public triggerDownload(downloadUrl: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadUrl;
    const components = downloadUrl.split("/");
    a.download = components[components.length - 1];
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
  }

}
