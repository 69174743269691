import moment from 'moment-timezone'

export class Timezones {

  static timezones = [
    {"timezone":"US/Eastern","label":"US/Eastern","short":"ET","offset":"-05:00"},
    {"timezone":"US/Central","label":"US/Central","short":"CT","offset":"-06:00"},
    {"timezone":"US/Mountain","label":"US/Mountain","short":"MT","offset":"-07:00"},
    {"timezone":"US/Pacific","label":"US/Pacific","short":"PT","offset":"-08:00"},
    {"timezone":"Canada/Atlantic","label":"Canada/Atlantic","short":"AT","offset":"-04:00"},
    {"timezone":"Canada/Newfoundland","label":"Canada/Newfoundland","short":"NT","offset":"-03:30"}
  ];

  static getZoneInfo(timezone) {
    return Timezones.timezones.find((el)=>{return el.timezone == timezone});
  }

  static getDateWithTimezoneApplied_moment(isoDateTime,timezone) {
    return moment.utc(isoDateTime).tz(timezone);
  }

  static yyyymmdd(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      return '' + y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
  }

  static yyyymmdd_moment(moment) {
    return moment.format("YYYY-MM-DD");
  }

  static hhmm(date) {
      var h = date.getHours();
      var m = date.getMinutes();
      return '' + (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
  }

  static hhmm_moment(moment) {
    return moment.format("HH:mm");
  }

  static ampm_moment(moment,timezoneRec) {
    return moment.format("h:mm a") + ' ' + timezoneRec.short;
  }

  static birthday_moment(dateStr) {
    return moment(dateStr).format("MMM DD, YYYY");
  }

  static getTimeISOString(datetimestr:string,timezoneRec) {
    return moment.tz(datetimestr, timezoneRec['timezone']).utc().toISOString()
  }

  static dateDifferenceDays_moment(isoStartDate,isoEndDate) {
    var start = moment.utc(isoStartDate);
    var end = moment.utc(isoEndDate);
    return start.diff(end,'days');
  }

  static daysToDate_moment(isoEndDate) {
    console.log('daysToDate');
    var start = moment.utc();
    var end = moment.utc(isoEndDate);
    return end.diff(start,'days');
  }

  /* Monday, Jan 24 6AM  */
  static readableDateTimeMoment(moment) {
    return moment.format("ddd, MMM D, YYYY h:mm A");
  }

  static readableDateMoment(moment) {
    return moment.format("ddd, MMM D, YYYY");
  }

  static readableTimeMoment(moment) {
    return moment.format("h:mm A");
  }

}

