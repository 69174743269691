<div class="container-fluid" *ngIf="isAuction()">

    <div class="row no-gutters table-header-border">

	    <div class="col-4 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ auction.title }} </span></div>
	    </div>

	    <div class="col-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ startDate }} </span></div>
	    </div>

	    <div class="col-2 mt-3 mb-3 flex-center">
	      <button type="button" [ngClass]="getStatusButtonStyle()" *ngIf="getAuctionStatus() != undefined && getAuctionStatus().length > 0">{{ getAuctionStatus() }}</button>
	    </div>

	    <div class="col-1 mt-3 mb-3 justify-content-center align-self-center">
	      <div class="d-flex flex-row align-items-center">
            <nb-icon icon="edit-outline" class="details-icon" status="primary"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onViewDetails()">Details</a>
	      </div>
	    </div>

	    <div class="col-1 mt-3 mb-3 justify-content-center align-self-center">
	      <div class="d-flex flex-row align-items-center" *ngIf="bidderReportAvailable()">
            <nb-icon icon="cloud-download-outline" class="details-icon" status="primary"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onBidderReport()">Bidders</a>
	      </div>
	    </div>
	    
	    <div class="col"></div>
    </div> 
    
</div>
