import { ApplicationRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { BenefitCard } from '../benefits-card/benefits-card.component';
import { AuctionModel, AuctionModelService } from '../model/auction.model';
import { ConnectivityService, ConnectivityState } from '../services/connectivity.service';
import { EventService } from '../services/event.service';
import { VisibilityService, VisibilityState } from '../services/visibility.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // TODO: Pull this data and structure from a CMS.
  public benefitCards: BenefitCard[] = [
    {
      mainTitle: "Bidders & Buyers",
      items: [
        {
          subtitleImageSrc: "assets/icons/bidding.png",
          subtitle: "Bidding",
          description: "You'll need to sign up and create an account for our new platform (even if you had an account on the old platform). Once you've created your account, you'll be able to place bids.",
          actionLinkTitle: "SIGN UP",
          actionLinkSignUp: true
        },
        {
          subtitleImageSrc: "assets/icons/bidding-portal.png",
          subtitle: "Bidding Portal",
          description: "Our bidder portal is a quick-access hub to your auction wins and invoices. We'll store the record for you of lots you've won and invoices against the account you bid with.",
          actionLinkTitle: "BIDDING PORTAL",
          actionLinkSrc: "/buyers/bidding-portal",
        },
      ]
    },
    {
      mainTitle: "Sellers",
      items: [
        {
          subtitleImageSrc: "assets/icons/service-providers.png",
          subtitle: "Seller Portal",
          description: "Our seller portal provides you with a live look at your sale, and a bidder record after it's over. Track what's happening in real-time so you can effectively coordinate with our expert sales manager.",
        },
        {
          subtitleImageSrc: "assets/icons/live-app.png",
          subtitle: "Social Media",
          description: "We have a three-tiered marketing package offering and various a la carte service available to help you publicize your sale through our marketing channels and your own.",
          actionLinkTitle: "MORE SELLER INFO",
          actionLinkSrc: "/sellers#seller-info"
        },
      ]
    },
    {
      mainTitle: "Online Bidding",
      items: [
        {
          subtitleImageSrc: "assets/icons/bidder-numbers.png",
          subtitle: "Bidder Numbers",
          description: "You don't need to remember a Bidder Number anymore-you'll be assigned one for each lot you bid on. This means you can bid anonymously but still have access to your history on our system and in the bidder portal.",
          actionLinkTitle: "SIGN UP",
          actionLinkSignUp: true
        },
      ]
    }
  ]

  @Output() messageEvent = new EventEmitter<any>();

  public auctionList: AuctionModel[] = [];
  public processing: boolean = false;
  public theme = environment.theme_config;
  private visibilitySub: Subscription;
  private connectivitySub: Subscription;

  constructor(
    private router: Router,
    private auctionModel: AuctionModelService,
    private visibilityService: VisibilityService,
    private connectivityService: ConnectivityService,
    private eventService: EventService,
    private appRef: ApplicationRef
  ) { }

  ngOnInit(): void {
    this.loadOpenAuctions();

    const localThis = this;
    this.visibilitySub = this.visibilityService.message$.subscribe(async event => {
      if (event === VisibilityState.PAGE_HIDDEN) {
        this.eventService.createEvent({ message: "hide-timers" });
      }
      if (event === VisibilityState.PAGE_VISIBLE) {
        await localThis.loadOpenAuctions();
        this.appRef.tick();
        this.eventService.createEvent({ message: "show-timers" });
      }
    });

    this.connectivitySub = this.connectivityService.message$.subscribe(async event => {
      if (event === ConnectivityState.OFFLINE) {
        this.eventService.createEvent({ message: "hide-timers" });
      }
      if (event === ConnectivityState.ONLINE) {
        await localThis.loadOpenAuctions();
        this.appRef.tick();
        this.eventService.createEvent({ message: "show-timers" });
      }
    });
  }

  ngOnDestroy(): void {
    this.visibilitySub.unsubscribe();
    this.connectivitySub.unsubscribe();
  }

  public async loadOpenAuctions() {
    this.processing = true;
    this.auctionList = await this.auctionModel.loadOpenAuctions(true);
    this.auctionList = this.auctionList.filter(x => x.isLive()).sort(this.compareEndTimeAsc);
    this.processing = false;
  }

  public onViewSaleCalendar(): void {
    this.router.navigate(['/auctions']);
  }

  private compareEndTimeAsc(a: AuctionModel, b: AuctionModel) {
    let firstDate = moment(a.endTime).toDate();
    let secondDate = moment(b.endTime).toDate();

    if (firstDate > secondDate) {
      return 1;
    } else if (firstDate < secondDate) {
      return -1;
    } else {
      return 0;
    }
  }


}
