<div class="container-fluid container-no-padding">

  <div class="d-none d-sm-none d-md-block">
    <div class="row no-gutters table-header-border">
      <div class="col-md-1 table-header pl-2">
        LOT #
      </div>

      <div class="col-md-2 table-header">
        NAME
      </div>

      <div class="col-md-2 table-header">
        CATEGORY
      </div>

      <div class="col-md-4 table-header">
        ADDITIONAL INFORMATION
      </div>

      <div class="col-md-1 table-header text-right">
        QUANTITY
      </div>

      <div class="col-md-1 table-header text-right">
        PRICE
      </div>

      <div class="col-md-1 table-header text-right pr-2">
        TOTAL
      </div>
    </div> <!-- header -->
  </div>

  <div class="row no-gutters table-header-border pb-2">
    <div class="col">
      <div *ngFor="let item of lineItems">
        <hr class="d-sm-block d-md-none"/>
        <app-invoice-line-item [lineItem]="item"></app-invoice-line-item>
      </div>
    </div>
  </div>
</div> <!-- container-fluid -->
