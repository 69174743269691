<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid fixed">

    <div class="row mb-4 container-horiz-padded">
        <div class="col-8 col-md-9 pt-2 mb-2">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a [routerLink]="" (click)="onBackToAuctionList()" class="back-link-text ml-1">Sales</a>

            <div class="mt-5 flex-vert-center">
              <span class="title"> {{ auctionTitle }} </span>

              <ng-container *ngIf="auctionModel.activeAuction.isSetup()">
                <span class="ml-4"><button type="button" class="statusButton statusSetupButton" disabled>Setup</button></span>
              </ng-container>

              <ng-container *ngIf="auctionModel.activeAuction.isCalendarPosted()">
                <span class="ml-4"><button type="button" class="statusButton statusCalendarPostedButton" disabled>Calendar Posted</button></span>
              </ng-container>

              <ng-container *ngIf="auctionModel.activeAuction.isSaleInfoPosted()">
                <span class="ml-4"><button type="button" class="statusButton statusSalePostedInfoButton" disabled>Sale Posted Info</button></span>
              </ng-container>

              <ng-container *ngIf="auctionModel.activeAuction.isLotsPosted()">
                <span class="ml-4"><button type="button" class="statusButton statusSalePostedInfoButton" disabled>Lots Posted</button></span>
              </ng-container>

              <ng-container *ngIf="auctionModel.activeAuction.isLive() && !auctionModel.activeAuction.isClosed()">
                <span class="ml-4"><button type="button" class="statusButton statusLiveButton" disabled>Live</button></span>
              </ng-container>

              <ng-container *ngIf="auctionModel.activeAuction.isClosed() && !auctionModel.activeAuction.isSettled()">
                <span class="ml-4"><button type="button" class="statusButton statusClosedButton" disabled>Closed</button></span>
              </ng-container>

              <ng-container *ngIf="auctionModel.activeAuction.isSettled()">
                <span class="ml-4"><button type="button" class="statusButton statusSettledButton" disabled>Settled</button></span>
              </ng-container>
            </div>
        </div>
        <div class="col-4 col-md-3 mb-2">
          <div class="auction-time-remaining-counter-container">
            <app-auction-time-remaining-counter #apptimecounter></app-auction-time-remaining-counter>
          </div>
        </div>
    </div>

    <!--<div class="row mb-4 container-horiz-padded" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">-->
    <!--    <div class="col-md-12">-->
    <!--        <app-auctionstatusflow #auctionstatusflow (messageEvent)="receiveMessage($event)"></app-auctionstatusflow>-->
    <!--    </div>-->
    <!--</div>-->

    <div class="row mb-2 container-horiz-padded" *ngIf="errorMsg.length > 0">
        <div class="col-md-12">
            <div><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-2 container-horiz-padded" *ngIf="successMsg.length > 0">
        <div class="col-md-12">
            <div><span class="successText">{{successMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-3 container-horiz-padded" style="height:48px">
        <div class="col-md-12">
            <nb-tabset (changeTab)="onSelectTab($event.tabTitle)">
                <!--<nb-tab tabTitle="SALE INFORMATION" [active]="getPage()==1"></nb-tab>-->
                <!--<nb-tab tabTitle="CONFIGURE" [active]="getPage()==2"></nb-tab>-->
                <ng-container *ngIf="auctionExists()">
                    <!--<nb-tab tabTitle="LOTS" [active]="getPage()==3"></nb-tab>-->
                    <nb-tab tabTitle="BIDS" [active]="getPage()==4"></nb-tab>
                    <nb-tab tabTitle="AUCTION REPORT" [active]="getPage()==5"></nb-tab>
                </ng-container>
            </nb-tabset>
        </div>
    </div>

    <div class="row mt-4 container-horiz-padded">
        <div class="col-md-12">
            <!--<app-auctionsaleinfo #auctionsaleinfo [style]="getPage()==1 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)" [savedisabled]="savedisabled['auctionsaleinfo']"></app-auctionsaleinfo>-->
            <!--<app-auctionconfigure #auctionconfigure [style]="getPage()==2 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)" [savedisabled]="savedisabled['auctionconfigure']"></app-auctionconfigure>-->
            <!--<app-auction-lots-home #lots [style]="getPage()==3 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auction-lots-home>-->
            <app-auction-bids-home #bidhome [style]="getPage()==4 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auction-bids-home>
            <app-auctionreport #auctionreport [style]="getPage()==5 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auctionreport>
        </div>
    </div>

</div> <!-- container-fluid -->
