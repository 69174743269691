import { Injectable } from '@angular/core';
import {Observable,of, from, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FileTransferService {
  retryCount: number = 20;

  constructor(private http: HttpClient) {
  }

  uploadSignedUrl(file,signedURL) {
    let headers = new HttpHeaders();
    let response = undefined;
    headers  = headers.append('Content-Type', file.type);
    // headers  = headers.append('Content-Type', "binary/octet-stream");

    return this.http.put(signedURL, file, {headers} )
      .map(
        (response) => {
          return response;
        }
      );
  }

  downloadSignedUrl(filename,url) {
    let headers = new HttpHeaders();
    headers  = headers.append('Content-Type', 'application/json');
    return this.http.get(url, {headers,observe: 'response',responseType:'blob' as 'json'} )
      .map(
        (response) => {
          var returnValue = {};
          returnValue['blob'] = response['body'];
          returnValue['filename'] = filename; 
          return returnValue;
        }
      );
  }

}
