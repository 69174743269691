<td [ngClass]="{ 'sold-lot-line-item': saleStatus === 'SOLD' }">
  <div class="column-text">{{ lot.lotNum }}</div>
</td>

<td>
  <div class="d-flex justify-content-between pr-2">
    <span class="column-text">{{ lot.title }}</span>
  </div>
</td>

<td>
  <span class="column-text">${{ lot.finalBid }}</span>
</td>

<td>
  <span class="column-text">{{ lot.quantity }}</span>
</td>

<td>
  <span class="column-text" [ngClass]="{'field-changed': lot.finalPrice != (lot.finalBid || 0) * (lot.quantity || 0)}">${{ lot.finalPrice }}</span>
</td>
