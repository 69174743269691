import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../auth/authhome/authhome.component';
import { EventService } from '../services/event.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<any>();

  public stickyHeader: boolean = true;
  public theme = environment.theme_config;

  constructor(public router:Router,
              private dialogService: NbDialogService,
              public authService:AuthService,
              private eventService: EventService) { }

  ngOnInit(): void {
    this.eventService.message$.subscribe(event => {
      if (event.message === "unstick-header") {
        this.stickyHeader = false;
      }
      if (event.message === "stick-header") {
        this.stickyHeader = true;
      }
    })
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  onSignin(){
    this.openAuth("signin");
  }

  onChangePassword(){
    this.openAuth("changepassword");
  }

  processMsg(event){
    // console.log('processMsg:',event);
    if(event != undefined){
      var msg = event['message'];
      if(msg == "forgotpassword"){
        this.openAuth("forgotpassword");
      }
      else if(msg== "signup"){
        this.openAuth("signup");
      }
      else if(msg== "changepassword"){
        this.openAuth("changepassword");
      }
      else if(msg== "signin"){
        this.openAuth("signin");
      }
      else if(msg== "signupcomplete"){
      }
      else if(msg== "signinsuccessful"){
      }
    }
  }

  openAuth(pageName){
    console.log('pageName',pageName);
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName:pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  getFirstName():string{
    var returnValue = "";
    if(this.authService.activeUser != undefined && this.authService.activeUser.givenName != undefined){
      returnValue = this.authService.activeUser.givenName;
    }
    return returnValue;
  }

  isSeller(){
    var returnValue = false;
    if(this.authService.isAuthenticated() && this.authService.activeUser != undefined){
      returnValue = this.authService.activeUser.isSeller();
    }
    return returnValue;
  }

  isRole(requiredRoles) {
    if (!requiredRoles) return true;
    if (!this.authService.activeUser) return false;

    let accessAllowed = false;
    requiredRoles.forEach(role => {
      if (role === "SELLER") {
        if (!accessAllowed) {
          accessAllowed = this.authService.activeUser.isSeller();
        }
      } else if (role === "BUYER") {
        if (!accessAllowed) {
          accessAllowed = this.authService.activeUser.isBuyer();
        }
      } else {
        if (!accessAllowed) {
          accessAllowed = false;
        }
      }
    })
    return accessAllowed;
  }

}
