<!-- TODO: Move the items into its own component (remove duplication). -->
<div class="container-fluid lot-detail-text-container mt-2" *ngIf="isAll()">
  <ng-container *ngIf="getCategory().length > 0">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Category:</div>
      <div class="col-7 lot-field-value" *ngIf="getCategory().length > 0">{{ getCategory() }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="getSexedCategory().length > 0">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Sex:</div>
      <div class="col-7 lot-field-value">{{ getSexedCategory() }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="birthDateStr.length > 0">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Birth Date:</div>
      <div class="col-7 lot-field-value">{{ birthDateStr }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="lot.regNum && lot.regNum.trim().length > 0">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Reg#:</div>
      <div class="col-7 lot-field-value" *ngIf="getRegUrl().length > 0; else reg_num_text"><span class="link-text"><a target="_blank" [href]="getRegUrl()" class="link-text">{{ lot.regNum }}</a></span></div>
      <ng-template #reg_num_text><div class="col-7 lot-field-value"><span class="info-data-text">{{ lot.regNum }}</span></div></ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="lot.quantity">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Quantity:</div>
      <div class="col-7 lot-field-value">{{ lot.quantity }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="getInterest().length > 0">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Interest:</div>
      <div class="col-7 lot-field-value">{{ getInterest() }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="sireExists()">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Sire:</div>
      <div class="col-7 lot-field-value">{{ lot.lineageHierarchy.sire }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="damExists()">
    <div class="row mt-2">
      <div class="col-5 lot-field-label">Dam:</div>
      <div class="col-7 lot-field-value">{{ lot.lineageHierarchy.dam }}</div>
    </div>
  </ng-container>
</div>

<!-- <div class="container-fluid" *ngIf="isHeader()">

    <ng-container *ngIf="lot.lotNum != undefined && lot.lotNum.length > 0">
        <div class="row">
            <div class="col mb-1">
                <div class="info-text">Lot {{ lot.lotNum }}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="getCategory().length > 0">
        <div class="row">
            <div class="col mb-1">
                <span class="info-text">Category: </span> <span class="info-data-text">{{ getCategory() }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.title != undefined && lot.title.trim().length > 0">
        <div class="row">
            <div class="col">
                <span class="label-text" style="font-size:15px">{{ lot.title != undefined ? lot.title : "" }}</span>
            </div>
        </div>
    </ng-container>

</div> -->


<div class="container-fluid" *ngIf="isGeneralInfo()">
  <dl class="row mt-2">
    <ng-container *ngIf="lot.tattoo && lot.tattoo.trim().length > 0">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">Tattoo:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ lot.tattoo }}</dd>
    </ng-container>
    <ng-container *ngIf="lot.embryoType && lot.embryoType.trim().length > 0">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">Embryo Type:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ lot.embryoType }}</dd>
    </ng-container>
    <ng-container *ngIf="getExportableType().length > 0">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">Exportable:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ getExportableType() }}</dd>
    </ng-container>
    <ng-container *ngIf="lot.donorName && lot.donorName.trim().length > 0">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">Donor Name:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ lot.donorName }}</dd>
    </ng-container>
    <ng-container *ngIf="lot.donorSire && lot.donorSire.trim().length > 0">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">Donor Sire:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ lot.donorSire }}</dd>
    </ng-container>
    <ng-container *ngIf="lot.donorDam != undefined && lot.donorDam.trim().length > 0">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">Donor Dam:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ lot.donorDam }}</dd>
    </ng-container>
    <ng-container *ngFor="let item of getCustomFields()">
      <dt class="col-5 col-md-4 col-lg-3 col-xl-2 lot-field-label">{{ item.customLabel }}:</dt>
      <dd class="col-7 col-md-8 col-lg-9 col-xl-10 lot-field-value">{{ item.customValue }}</dd>
    </ng-container>
  </dl>
</div>

<!-- <div class="container-fluid" *ngIf="isGeneralInfo()">

    <ng-container *ngIf="getSexedCategory().length > 0">
        <div class="row">
            <div class="col mb-1">
                <span class="info-text">Sex: </span><span class="info-data-text">{{ getSexedCategory() }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="birthDateStr.length > 0">
        <div class="row">
            <div class="col mb-1">
                <span class="info-text">Birth Date:</span><span class="info-data-text"> {{ birthDateStr }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.regNum != undefined && lot.regNum.trim().length > 0">
        <div class="row">
            <div class="col mb-2" *ngIf="getRegUrl().length > 0">
                <div class="info-text">Reg#: <span class="link-text"><a target="_blank" [href]="getRegUrl()" class="link-text">{{ lot.regNum }}</a></span></div>
            </div>

            <div class="col mb-2" *ngIf="getRegUrl().length == 0">
                <span class="info-text">Reg#: </span><span class="info-data-text">{{ lot.regNum }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.tattoo != undefined && lot.tattoo.trim().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Tattoo:</span><span class="info-data-text"> {{ lot.tattoo }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.type != undefined && lot.type.trim().length > 0">
        <div class="row">
            <div class="col mb-1">
                <span class="info-text">Type: </span><span class="info-data-text"> {{ lot.type }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.quantity != undefined">
        <div class="row">
            <div class="col">
                <span class="info-text">Quantity: </span><span class="info-data-text">{{ lot.quantity }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="getInterest().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Interest: </span><span class="info-data-text">{{ getInterest() }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.embryoType != undefined && lot.embryoType.trim().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Embryo Type: </span><span class="info-data-text">{{ lot.embryoType }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="getExportableType().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Exportable: </span><span class="info-data-text">{{ getExportableType() }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.donorName != undefined && lot.donorName.trim().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Donor Name: </span><span class="info-data-text">{{ lot.donorName }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.donorSire != undefined && lot.donorSire.trim().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Donor Sire: </span><span class="info-data-text">{{ lot.donorSire }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="lot.donorDam != undefined && lot.donorDam.trim().length > 0">
        <div class="row">
            <div class="col">
                <span class="info-text">Donor Dam: </span><span class="info-data-text">{{ lot.donorDam }}</span>
            </div>
        </div>
    </ng-container>

</div> -->