<div class="modal-scroll auth-card">
    <nb-card>
            <nb-card-header style="padding:1rem 0px 1rem 0px">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col no-gutters">
                            <div class="login-center">
                                <div style="margin-right:auto;visibility:hidden">
                                    <nb-icon icon="close"></nb-icon>
                                </div>
                                <img class="login-header-img" [src]="theme.header_logo_small_url">
                                <div style="margin-left:auto;margin-top:-20px">
                                     <a [routerLink]="" (click)="close()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="login-center"><span class="login-header-text">Sign In</span></div>
                        </div>
                    </div>

                </div> <!--END: container-fluid -->
            </nb-card-header>

            <nb-card-body *ngIf="login" style="padding:1rem 0px 1rem 0px" class="maincontent">
              <form #f="ngForm" (ngSubmit)="onSignIn()">
                <div class="container-fluid">
                    <div class="row mb-3">
                        <div class="col">
                            <div class="msg-text" style="text-align:center">Sign in to your account to place bids, subscribe to alerts, and manage your settings.</div>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col">
                            <div class="msg-text" style="text-align:center">Need to create an account? <a [routerLink]="" (click)="onSignUp()" class="login-link">Sign Up</a></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-text">EMAIL ADDRESS</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <div class="form-control-group">
                                <input type="email"
                                       fullWidth
                                       nbInput
                                       id="email"
                                       name="email"
                                       placeholder="Type your email address"
                                       #emailentry="ngModel"
                                       [(ngModel)]="email"
                                       [ngClass]="{'inputError':!emailentry.valid && emailentry.touched}"
                                       required email>
                                <div class="errorText" *ngIf="!emailentry.valid && emailentry.touched">Please provide a valid email address.</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-text">PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="password"
                                           name="password"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showPassword ? 'text' : 'password'"
                                           [(ngModel)]="password"
                                           [ngClass]="{'inputError':!passwordentry.valid && passwordentry.touched}"
                                           #passwordentry="ngModel"
                                           required>
                                    <button type="button" nbSuffix nbButton ghost (click)="onToggleShowPassword()" class="mr-4">
                                       <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                                                pack="eva">
                                       </nb-icon>
                                    </button>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!passwordentry.valid && passwordentry.touched">Please provide a password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col -->
                    </div> <!-- row -->
                    <div class="row mb-2">
                        <div class="col">
                            <button type="submit" [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary"  [disabled]="!f.valid || processing">SIGN IN</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>

                </div> <!-- container-fluid -->
              </form>
            </nb-card-body>

            <nb-card-body *ngIf="confirmation" style="padding:1rem 0px 1rem 0px" class="maincontent">
              <form #f="ngForm" (ngSubmit)="onConfirm()">
                <div class="container-fluid">
                    <div class="row mb-1">
                        <div class="col">
                            <span class="msg-text">We just sent you an email at <strong>{{ email }}</strong> with instructions on how to activate your account.</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="msg-text" style="text-align:center"><span class="mr-2">1.</span>Look for an email with the subject "{{theme.app_title}} Account Sign Up"</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="msg-text"><span class="mr-2">2.</span>Click the provided link to confirm your account.</span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <span class="msg-text"><span class="mr-2">3.</span>Come back to this page and click Continue to sign in automatically.</span>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col flex-center">
                            <button type="submit" [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary">CONTINUE</button>
                        </div>
                    </div>

                    <div class="row mb-2" *ngIf="errorMsg.length > 0">
                        <div class="col">
                            <div><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>

                </div>
              </form>
            </nb-card-body>

            <nb-card-body *ngIf="changePassword" style="padding:1rem 0px 1rem 0px" class="maincontent">
              <form #f="ngForm" (ngSubmit)="onSignInNewPassword()">
                <div class="container-fluid">

                    <div class="row mb-4">
                        <div class="col">
                            <div class="msg-text" style="text-align:center">Your account requires a new password.</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-text">NEW PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="newpassword"
                                           name="newpassword"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showNewPasswordText ? 'text' : 'password'"
                                           [(ngModel)]="newpassword"
                                           [ngClass]="{'inputError':!newpasswordentry.valid && newpasswordentry.touched}"
                                           #newpasswordentry="ngModel"
                                           required>
                                    <button type="button" nbSuffix nbButton ghost (click)="onToggleShowNewPassword()">
                                       <nb-icon [icon]="showNewPasswordText ? 'eye-outline' : 'eye-off-2-outline'"
                                                pack="eva">
                                       </nb-icon>
                                    </button>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!newpasswordentry.valid && newpasswordentry.touched">Please provide a new password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col -->
                    </div> <!-- row -->

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordCharLength()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">8 character minimum</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordUpperCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 upper case letter</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordLowerCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 lower case letter</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordNumber()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 number</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordSpecialChars()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 special character: !@#$%^&*()</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-text">RE-ENTER NEW PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="confirmpassword"
                                           name="confirmpassword"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showConfirmPasswordText ? 'text' : 'password'"
                                           [(ngModel)]="confirmpassword"
                                           [ngClass]="{'inputError':!confirmpasswordentry.valid && confirmpasswordentry.touched}"
                                           #confirmpasswordentry="ngModel"
                                           required>
                                    <button *ngIf="!confirmPasswordCheck()" type="button" nbSuffix nbButton ghost (click)="onToggleShowConfirmPassword()">
                                       <nb-icon [icon]="showConfirmPasswordText ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"></nb-icon>
                                    </button>
                                    <nb-icon *ngIf="confirmPasswordCheck()" nbSuffix style="color: #00e096" icon="checkmark-outline" pack="eva"></nb-icon>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!confirmpasswordentry.valid && confirmpasswordentry.touched">Please confirm your new password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col -->
                    </div> <!-- row -->

                    <div class="row mb-2">
                        <div class="col">
                            <button type="submit" [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary"  [disabled]="!f.valid || processing">CHANGE PASSWORD</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>

                </div> <!-- container-fluid -->
              </form>
            </nb-card-body>

            <nb-card-footer style="padding:1rem 0px 1rem 0px" *ngIf="!confirmation">
                <div class="container-fluid">
                    <div class="row mb-4">
                        <div class="col">
                             <a [routerLink]="" (click)="onForgotPassword()" class="login-link">Forgot Password?</a>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="false">
                  <div class="row mb-2">
                      <div class="col login-center">
                          <button style="width:90%" type="button" (click)="onFacebookSignin()" class="facebook-button">
                              <span>LOGIN WITH FACEBOOK</span>
                              <img src="../../../assets/images/facebook.svg">
                          </button>

                      </div>
                  </div>
                  <div class="row mb-2">
                      <div class="col login-center">
                          <button style="width:90%" type="button" (click)="onGoogleSignin()" class="google-button">
                              <span>LOGIN WITH GOOGLE</span>
                              <img src="../../../assets/images/google.svg">
                          </button>

                      </div>
                  </div>
                  <div class="row mb-2">
                      <div class="col login-center">
                          <button style="width:90%" type="button" (click)="onAppleSignin()" class="apple-button">
                              <span>LOGIN WITH APPLE</span>
                              <img src="../../../assets/images/apple.png">
                          </button>

                      </div>
                  </div>
                </ng-container>
            </nb-card-footer>
    </nb-card>
</div>