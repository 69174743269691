<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid" *ngIf="auction && lot">
  <div class="container-horiz-padded py-2">
    <app-back-nav-button [backUrl]="getAuctionUrl()" [text]="auction.title" [fragment]="lot.lotId"></app-back-nav-button>
    <div class="lot-detail">
      <app-lotdetail #lotdetail [lot]="lot" [authenticated]="authService.isAuthenticated()" (messageEvent)="receiveMessage($event)" [previewMode]="previewMode" [disableLotLink]="true" [detailedView]="true" [showFootnote]="false"></app-lotdetail>
    </div>
  </div>
  <div class="lot-background"></div>

  <ng-container *ngIf="lot">
    <div class="row container-horiz-padded pt-4">
      <div class="col-sm-8">
        <app-section-header>Footnote</app-section-header>
        <div *ngFor="let item of lot.footnote">
          <p class="description-text" style="white-space: pre-wrap">{{ item }}</p>
        </div>
        <div class="mt-4" *ngIf="!isSupportedVideoLink">
          Video Link: <a [href]="getVideoLink()" target="_blank">View</a>
        </div>
      </div>
      <div class="col-sm-4">
        <app-section-header>Additional Details</app-section-header>
        <app-lotdetailtext [lot]="lot" displaytype="generalinfo"></app-lotdetailtext>
      </div>
    </div>
    <div class="row container-horiz-padded">
      <div class="col">
        <hr class="divider-rule">
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="lineageHierachyExists()">
    <div class="row container-horiz-padded mt-4">
        <div class="col-md-4">
          <app-section-header>Lineage</app-section-header>
        </div>
    </div>
    <div class="row container-horiz-padded-custom">
        <div class="col-md-12">
          <!-- try adding a char here to see the border-->
          <app-lot-lineage [lot]="lot" *ngIf="lot.lineageHierarchy"></app-lot-lineage>
        </div>
    </div>
    <div class="row container-horiz-padded mt-4">
      <div class="col">
        <hr class="divider-rule">
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="epdsExist()">
    <div class="row">
      <div class="col epd-container py-4">
        <app-section-header>{{ getEPDLabel() }}</app-section-header>
        <app-lot-epds [lot]="lot" *ngIf="lot.epdMeasures != undefined && lot.epdMeasures.length > 0"></app-lot-epds>
      </div>
    </div>
  </ng-container>

  <div class="row container-horiz-padded py-4">
    <div class="col-sm-4">
      <div class="link-text"><a routerLink="/buyers" fragment="delivery-payment">Delivery and Payment</a></div>
      <div class="link-text mt-2"><a routerLink="/buyers" fragment="terms-and-conditions">Terms and Conditions</a></div>
    </div>
    <div class="col-sm-4 mt-sm-0 mt-3">
      <app-section-header>Seller</app-section-header>
      <div class="description-text" *ngIf="getSellerBusinessName().length > 0"><strong>{{ getSellerBusinessName() }}</strong></div>
      <div class="description-text" *ngIf="getSellerAddressOne().length > 0">{{ getSellerAddressOne() }}</div>
      <div class="description-text" *ngIf="getSellerAddressTwo().length > 0">{{ getSellerAddressTwo() }}</div>
      <div class="description-text" *ngIf="getPhysicalLocation().length > 0">{{ getPhysicalLocation() }}</div>
      <div class="description-text" *ngIf="getSellerPhoneNumber().length > 0">{{ getSellerPhoneNumber() }}</div>
      <div class="link-text" *ngIf="getSellerEmail().length > 0">{{ getSellerEmail() }}</div>
      <div class="link-text" *ngIf="getSellerWebSite().length > 0"><a [href]="getSellerWebSiteUrl()" target="_blank">{{ getSellerWebSite() }}</a></div>
    </div>
    <div class="col-sm-4 mt-sm-0 my-3">
      <app-section-header>Sale Contact Info</app-section-header>
      <div class="description-text">{{ auction.salesRepName }}</div>
      <div class="description-text">{{ auction.salesRepContact }}</div>
      <div class="link-text"><a [href]="'mailto:' + auction.salesRepEmail" target="_blank">{{ auction.salesRepEmail }}</a></div>
    </div>
  </div>
</div>