import { Component, OnInit, Input } from '@angular/core';
import { LotModel } from '../../model/auction.model';

@Component({
  selector: 'app-lot-epds',
  templateUrl: './lot-epds.component.html',
  styleUrls: ['./lot-epds.component.scss']
})
export class LotEpdsComponent implements OnInit {

  @Input() lot:LotModel = undefined;

  EPDS_PRODUCTION_LEN:number;
  EPDS_PRODUCTION_START:number;
  EPDS_PRODUCTION = [];

  EPDS_MATERNAL_LEN:number;
  EPDS_MATERNAL_START:number;
  EPDS_MATERNAL = [];

  EPDS_MANAGEMENT_LEN:number;
  EPDS_MANAGEMENT_START:number;
  EPDS_MANAGEMENT = [];

  EPDS_WEIGHT_LEN:number;
  EPDS_WEIGHT_START:number;
  EPDS_WEIGHT = [];

  EPDS_FERTILITY_LEN:number;
  EPDS_FERTILITY_START:number;
  EPDS_FERTILITY = [];

  EPDS_CARCASS_LEN:number;
  EPDS_CARCASS_START:number;
  EPDS_CARCASS = [];

  EPDS_$VALUES_LEN:number;
  EPDS_$VALUES_START:number;
  EPDS_$VALUES = [];

  constructor() { }

  ngOnInit(): void {
    this.EPDS_PRODUCTION_LEN = LotModel.EPDS_PRODUCTION.length;
    this.EPDS_PRODUCTION_START = 0;
    this.EPDS_PRODUCTION = this.lot.epdMeasures.slice(this.EPDS_PRODUCTION_START,this.EPDS_PRODUCTION_LEN);

    this.EPDS_MATERNAL_LEN = LotModel.EPDS_MATERNAL.length;
    this.EPDS_MATERNAL_START = this.EPDS_PRODUCTION_LEN;
    this.EPDS_MATERNAL = this.lot.epdMeasures.slice(this.EPDS_MATERNAL_START,this.EPDS_MATERNAL_START+this.EPDS_MATERNAL_LEN);

    this.EPDS_MANAGEMENT_LEN = LotModel.EPDS_MANAGEMENT.length;
    this.EPDS_MANAGEMENT_START = this.EPDS_MATERNAL_START + this.EPDS_MATERNAL_LEN;
    this.EPDS_MANAGEMENT = this.lot.epdMeasures.slice(this.EPDS_MANAGEMENT_START,this.EPDS_MANAGEMENT_START+this.EPDS_MANAGEMENT_LEN);

    this.EPDS_WEIGHT_LEN = LotModel.EPDS_WEIGHT.length;
    this.EPDS_WEIGHT_START = this.EPDS_MANAGEMENT_START + this.EPDS_MANAGEMENT_LEN;
    this.EPDS_WEIGHT = this.lot.epdMeasures.slice(this.EPDS_WEIGHT_START,this.EPDS_WEIGHT_START+this.EPDS_WEIGHT_LEN);

    this.EPDS_FERTILITY_LEN = LotModel.EPDS_FERTILITY.length;
    this.EPDS_FERTILITY_START = this.EPDS_WEIGHT_START + this.EPDS_WEIGHT_LEN;
    this.EPDS_FERTILITY = this.lot.epdMeasures.slice(this.EPDS_FERTILITY_START,this.EPDS_FERTILITY_START+this.EPDS_FERTILITY_LEN);

    this.EPDS_CARCASS_LEN = LotModel.EPDS_CARCASS.length;
    this.EPDS_CARCASS_START = this.EPDS_FERTILITY_START + this.EPDS_FERTILITY_LEN;
    this.EPDS_CARCASS = this.lot.epdMeasures.slice(this.EPDS_CARCASS_START,this.EPDS_CARCASS_START+this.EPDS_CARCASS_LEN);

    this.EPDS_$VALUES_LEN = LotModel.EPDS_$VALUES.length;
    this.EPDS_$VALUES_START = this.EPDS_CARCASS_START + this.EPDS_CARCASS_LEN;
    this.EPDS_$VALUES = this.lot.epdMeasures.slice(this.EPDS_$VALUES_START,this.EPDS_$VALUES_START+this.EPDS_$VALUES_LEN);
  }

  hasProductionValues(){
    return this.lot.epdsExistForCategory(this.EPDS_PRODUCTION);
  }

  hasMaternalValues(){
    return this.lot.epdsExistForCategory(this.EPDS_MATERNAL);
  }

  hasManagementValues(){
    return this.lot.epdsExistForCategory(this.EPDS_MANAGEMENT);
  }

  hasWeightValues() {
    return this.lot.epdsExistForCategory(this.EPDS_WEIGHT);
  }

  hasFertilityValues() {
    return this.lot.epdsExistForCategory(this.EPDS_FERTILITY);
  }

  hasCarcassValues(){
    return this.lot.epdsExistForCategory(this.EPDS_CARCASS);
  }

  has$Values(){
    return this.lot.epdsExistForCategory(this.EPDS_$VALUES);
  }

  isEmpty(text: any) {
    return (typeof text === 'undefined') || text === '' || text === null
  }

}
