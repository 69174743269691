/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const auction = /* GraphQL */ `
  query Auction($auctionId: ID!) {
    auction(auctionId: $auctionId) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      closingExtensionTriggered
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      delisted
      createdAt
      updatedAt
      version
      lots {
        nextToken
      }
      lot {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
      }
    }
  }
`;
export const auctions = /* GraphQL */ `
  query Auctions($limit: Int, $nextToken: ID, $sortDirection: SortDirection) {
    auctions(
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const openAuctions = /* GraphQL */ `
  query OpenAuctions(
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    openAuctions(
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const auctionsBySeller = /* GraphQL */ `
  query AuctionsBySeller(
    $sellerId: ID!
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctionsBySeller(
      sellerId: $sellerId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const bidderReport = /* GraphQL */ `
  query BidderReport($auctionId: ID!) {
    bidderReport(auctionId: $auctionId) {
      status
      message
      fileKey
      downloadUrl
    }
  }
`;
export const winsByEmail = /* GraphQL */ `
  query WinsByEmail($email: AWSEmail!, $limit: Int, $nextToken: ID) {
    winsByEmail(email: $email, limit: $limit, nextToken: $nextToken) {
      items {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const auctionLot = /* GraphQL */ `
  query AuctionLot($auctionId: ID!, $lotId: ID!) {
    auctionLot(auctionId: $auctionId, lotId: $lotId) {
      auctionId
      lotId
      lotNum
      title
      lotOrder
      biddingDisabled
      customFields {
        customLabel
        customValue
      }
      embryoType
      category
      donorName
      donorSire
      donorDam
      breed
      consigner
      specialTerms
      deliveryMethods
      startBid
      floorPrice
      interest
      quantity
      taxable
      tag
      regNum
      regUrl
      lineageHierarchy {
        sire
        sireRegNum
        sireUrl
        dam
        damRegNum
        damUrl
      }
      epdMeasures {
        category
        measure
        value
        accuracy
        progeny
        percentage
        order
      }
      tattoo
      dob
      sexedCategory
      recipientId
      exportable
      footnote
      contactInfo
      pictures
      photos {
        storageName
        fileName
        caption
        cover
      }
      videos {
        storageName
        fileName
        caption
        cover
      }
      externalVideoUrl
      bidIncrement
      currentBid
      nextBid
      bannerImageKey
      businessAccountId
      lastBidTime
      version
      bidCount
      bidderAccountId
      bidderId
      bidderCount
      bids {
        nextToken
      }
      actualEPD
      importError
      importErrors
      currentMaxBid
      currentMaxBidAccountId
    }
  }
`;
export const auctionLots = /* GraphQL */ `
  query AuctionLots(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctionLots(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      nextToken
    }
  }
`;
export const auctionLotsBidPrice = /* GraphQL */ `
  query AuctionLotsBidPrice(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctionLotsBidPrice(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      nextToken
    }
  }
`;
export const auctionLotsBidTime = /* GraphQL */ `
  query AuctionLotsBidTime(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctionLotsBidTime(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        lotId
        lotNum
        title
        lotOrder
        biddingDisabled
        embryoType
        category
        donorName
        donorSire
        donorDam
        breed
        consigner
        specialTerms
        deliveryMethods
        startBid
        floorPrice
        interest
        quantity
        taxable
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        recipientId
        exportable
        footnote
        contactInfo
        pictures
        externalVideoUrl
        bidIncrement
        currentBid
        nextBid
        bannerImageKey
        businessAccountId
        lastBidTime
        version
        bidCount
        bidderAccountId
        bidderId
        bidderCount
        actualEPD
        importError
        importErrors
        currentMaxBid
        currentMaxBidAccountId
      }
      nextToken
    }
  }
`;
export const auctionReport = /* GraphQL */ `
  query AuctionReport(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortField: AuctionReportSortField
    $sortDirection: SortDirection
  ) {
    auctionReport(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      status
      message
      auctionId
      auctionName
      auctionStartDate
      auctionClosingDate
      totalGrossSales
      totalBidderCount
      totalLotCount
      invoiceSentDate
      payments {
        paymentDate
        amount
        fees
      }
      lineItems {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      nextToken
      version
    }
  }
`;
export const accountByEmail = /* GraphQL */ `
  query AccountByEmail($email: AWSEmail!) {
    accountByEmail(email: $email) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const user = /* GraphQL */ `
  query User($accountId: ID!) {
    user(accountId: $accountId) {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
export const accountByFirstLast = /* GraphQL */ `
  query AccountByFirstLast(
    $givenName: String
    $familyName: String!
    $limit: Int
    $nextToken: ID
  ) {
    accountByFirstLast(
      givenName: $givenName
      familyName: $familyName
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const business = /* GraphQL */ `
  query Business($accountId: ID!) {
    business(accountId: $accountId) {
      accountId
      businessName
      email
      familyName
      givenName
      nameTitle
      providerName
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      businessWebSite
      businessNotes
      businessPhoneNumbers {
        phone
        preferred
        mobile
      }
      proxyUsers {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      businessTimezone
      version
    }
  }
`;
export const businesses = /* GraphQL */ `
  query Businesses($limit: Int, $nextToken: ID) {
    businesses(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const users = /* GraphQL */ `
  query Users($limit: Int, $nextToken: ID) {
    users(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const sellers = /* GraphQL */ `
  query Sellers($limit: Int, $nextToken: ID) {
    sellers(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const buyers = /* GraphQL */ `
  query Buyers($limit: Int, $nextToken: ID) {
    buyers(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const userAccounts = /* GraphQL */ `
  query UserAccounts($limit: Int, $nextToken: ID) {
    userAccounts(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        email
        familyName
        givenName
        nameTitle
        businessName
        lastLoginDt
        loginCtr
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        userRoles
        businesses
        notifications
        cognitoUserStatus
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const invoice = /* GraphQL */ `
  query Invoice($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      auctionId
      auctionName
      auctionStartDate
      invoiceId
      invoiceNumber
      invoiceDate
      invoiceSentDate
      paymentDueDate
      bannerImageKey
      buyerName
      buyerAddress
      buyerPhoneNumber
      buyerEmail
      saleAuctionName
      saleAuctionClosingDate
      sellerName
      sellerPhoneNumber
      sellerEmail
      sellerWebsite
      sellerAddress
      sellerContactName
      sellerCity
      sellerState
      sellerZip
      sellerNotes
      totalAmountPaid
      totalAmountDue
      datePaid
      paid
      paymentMethod
      paymentStatus
      paymentInvoiceId
      paymentCustomerId
      paymentInvoicePdf
      paymentUrl
      lineItems {
        auctionId
        invoiceLineItemId
        lotNum
        lotName
        lotCategory
        quantity
        unitPrice
        totalPrice
        additionalInfo
      }
    }
  }
`;
export const downloadInvoice = /* GraphQL */ `
  query DownloadInvoice($invoiceId: ID!) {
    downloadInvoice(invoiceId: $invoiceId) {
      status
      downloadUrl
    }
  }
`;
export const downloadInvoices = /* GraphQL */ `
  query DownloadInvoices($auctionId: ID!) {
    downloadInvoices(auctionId: $auctionId) {
      status
      downloadUrl
    }
  }
`;
export const me = /* GraphQL */ `
  query Me {
    me {
      accountId
      email
      familyName
      givenName
      nameTitle
      businessName
      lastLoginDt
      loginCtr
      phone
      physicalAddress
      physicalCity
      physicalState
      physicalZip
      physicalCountry
      physicalCountryStateCity
      billingAddress
      billingCity
      billingState
      billingZip
      billingCountry
      termsOfService
      receiveInfo
      userRoles
      businesses
      notifications
      cognitoUserStatus
      version
      activeBidderIds {
        auctionId
        lotId
        bidderId
      }
      associatedBusinesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      outstandingInvoices {
        auctionId
        auctionName
        auctionStartDate
        invoiceId
        invoiceNumber
        invoiceDate
        invoiceSentDate
        paymentDueDate
        bannerImageKey
        buyerName
        buyerAddress
        buyerPhoneNumber
        buyerEmail
        saleAuctionName
        saleAuctionClosingDate
        sellerName
        sellerPhoneNumber
        sellerEmail
        sellerWebsite
        sellerAddress
        sellerContactName
        sellerCity
        sellerState
        sellerZip
        sellerNotes
        totalAmountPaid
        totalAmountDue
        datePaid
        paid
        paymentMethod
        paymentStatus
        paymentInvoiceId
        paymentCustomerId
        paymentInvoicePdf
        paymentUrl
      }
      activeAuctions {
        auctionId
        title
        startTime
        endTime
        type
        status
        bidIncrement
        startBid
        floorPrice
        bannerImageKey
      }
      wins {
        auctionId
        auctionName
        auctionStartDate
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        sellerName
        sellerPhoneNumber
        sellerEmail
        version
      }
      auctionsAsSeller {
        nextToken
      }
      auctionAsSellerWithLots {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        closingExtensionEnabled
        closingExtensionStyle
        closingExtensionInterval
        closingExtensionTriggered
        status
        salesInfoBannerKeys
        salesInfoNotes
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        startBid
        floorPrice
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        hasCustomInvoiceDetails
        hasStandardTermsAndConditions
        hasCustomTermsAndConditions
        customTermsAndConditions
        hasStandardDeliveryAndPaymentTerms
        hasCustomDeliveryAndPaymentTerms
        customDeliveryAndPaymentTerms
        timezone
        useSellerContactInfo
        delisted
        createdAt
        updatedAt
        version
      }
      auctionAsSellerLotBidPrices {
        nextToken
      }
      auctionAsSellerLotBidTimes {
        nextToken
      }
    }
  }
`;
