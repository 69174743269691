
<nav class="navbar navbar-expand-sm navbar-light" [ngClass]="{ 'navbar-sticky': stickyHeader }">
  <a class="navbar-brand logo-link d-flex flex-row justify-content-center" routerLink="/">
    <img [src]="theme.header_logo_large_url" [alt]="theme.header_logo_large_url_alt" width="128">
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="ml-auto">
      <ul class="navbar-nav navbar-right">
          <ng-container *ngFor="let menu of theme.header_menu; let i = index">
            <li *ngIf="menu.menu_items.length > 0" class="dropdown header-nav ml-sm-2 ml-md-4 nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="header-nav-link d-sm-flex d-none" [id]="'menu-' + i" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [routerLink]="menu.router_link" [class.header-nav-link-active]="router.isActive(menu.router_link.join(','), false)">{{menu.title}}</a>
              <div class="dropdown-menu dropdown-link" [attr.aria-labelledby]="'menu-' + i">
                <ng-container *ngFor="let subMenu of menu.menu_items">
                  <a class="dropdown-item dropdown-link" [routerLink]="subMenu.router_link" *ngIf="isRole(subMenu.required_roles)" [fragment]="subMenu.fragment">{{subMenu.title}}</a>
                </ng-container>
              </div>
            </li>
            <ng-container class="d-sm-none" *ngIf="menu.menu_items.length > 0">
              <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a class="header-nav-link" href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [class.header-nav-link-active]="router.isActive('/sellers',false)">{{menu.title}}</a>
              </li>
              <ng-container *ngFor="let subMenu of menu.menu_items">
                <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="subMenu.router_link" *ngIf="isRole(subMenu.required_roles)" [fragment]="subMenu.fragment">{{subMenu.title}}</a>
                </li>
              </ng-container>
            </ng-container>

            <li *ngIf="menu.menu_items.length === 0" class="header-nav nav-item ml-sm-2 ml-md-4" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="header-nav-link" [routerLink]="menu.router_link" [id]="'menu-' + i" [class.header-nav-link-active]="router.isActive(menu.router_link.join(','), false)">{{menu.title}}</a>
            </li>
          </ng-container>

          <li class="header-nav nav-item ml-md-5" *ngIf="!authService.isAuthenticated()" data-toggle="collapse" data-target=".navbar-collapse.show">
              <button nbButton shape="round" size="large" status="basic" class="ml-sm-2 ml-md-4 my-2 my-sm-0 sign-in-button" (click)="onSignin()">SIGN IN</button>
          </li>

          <!-- wide -->
          <li class="dropdown d-sm-block d-none" *ngIf="authService.isAuthenticated()">
              <a class="dropdown-toggle header-nav-link ml-5 my-2 my-sm-0" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="header-nav-link">Hi, {{ getFirstName() }}</span>
              </a>
              <div class="dropdown-menu dropdown-link" style="right: 0; left: auto;" aria-labelledby="navbarDropdown">
                <a class="dropdown-item dropdown-link" [routerLink]="['/accountsettings', '1']"><span> Account Settings</span></a>
                <a class="dropdown-item dropdown-link" (click)="onLogout()"><span> Sign Out</span></a>
              </div>
          </li>

          <!-- narrow -->
          <ng-container class="d-sm-none" *ngIf="authService.isAuthenticated()">
            <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="header-nav-link" [routerLink]="['/accountsettings', '1']"> Account Settings</a>
            </li>
            <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="header-nav-link" (click)="onLogout()"> Sign Out</a>
            </li>
          </ng-container>

      </ul>
    </span>
  </div>

</nav>